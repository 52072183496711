/**
 * @file
 * A single "opération travaux" on a VAQ.
 */
import * as moment from 'moment';
import * as _ from 'lodash';

import { DayOfWeek, WEEK_DAYS, DATE_FORMATTERS, REPEAT_UNIT, getUserFriendlyFromDateToDate } from '@app/shared';
import { CardinalDirection } from './misc-models';
import { VoieAQuai } from './station';
import { StationOrientationMapping, STATION_ORIENTATION_MAPPING_DEFAULT } from './station-preferences';
import { ConflictDto } from './conflict';
import { TypeIndispo } from './type-indispo';
import { TravauxOpSelection } from './travaux-op-selection';
import { IndispoPart } from './indispo-part';
import { capitalizeFirstCharOnly } from '@app/utils';

/**
 * ============================
 * ========== MODELS ==========
 * ============================
 */

/**
 * A raw travaux op, as returned by the backend.
 */
export interface TravauxOpDto {
  id: number;
  rid: number; // technical id
  typeTravaux: string; // enum
  typeIndispo: string; // enum
  nom: string;
  commentaire?: string; // not in use as of Sep 2020
  nomsVEG: string[];
  subtypeOperationVEG: string; // enum
  dateHeureDebut: string; // ISO 8601 date
  dateHeureFin: string; // ISO 8601 date
  display: boolean; // used to be `showOnGov`
  direction1: string;
  longueur1: string;
  direction2: string;
  longueur2: string;
  // Répétition
  repetition?: {
    repeatDays: string; // "1;2;7"
    repeatEvery: number;
    repeatUnit: string; // 'WEEK' | 'MONTH'
  };
  children?: TravauxOpDto[]; // Set only if the travaux op is a parent with children
  groupId?: number; // Set * BY THE FRONTEND * when saving a child travaux op

  // The prop below is a special, optional prop added * BY THE FRONTEND *
  // when flattening a child op for which we need to retain the parent op's info
  $parentOp?: TravauxOpDto;
  indispoPart?: string;
}

/**
 * A frontend-friendly travaux op.
 */
export interface TravauxOp {
  id: number;
  rid: number;
  typeTravaux: TravauxOpType;
  typeIndispo: TypeIndispo;
  nom: string;
  nomsVEG: string[];
  sousTypeOperationVEG: TravauxSousTypeOperationVEG;
  dateHeureDebut: moment.Moment;
  dateHeureFin: moment.Moment;
  dateHeureProjDebut?: moment.Moment; // Hour projection on GOV conception
  dateHeureProjFin?: moment.Moment; // Hour projection on GOV conception
  afficherSurGOV: boolean;
  direction1?: CardinalDirection;
  longueur1?: string;
  direction2?: CardinalDirection;
  longueur2?: string;
  // Répétition
  repetition?: {
    repeatDays: DayOfWeek[];
    repeatEvery: number;
    repeatUnit: REPEAT_UNIT;
  };
  children?: TravauxOp[]; // Set only if the travaux op is a parent with children
  groupId?: number; // Set only if the travaux op is a child
  indispoPart: string;
  childrenSelections?: TravauxOp[];
  // Frontend-specific props
  $sys: {
    conflictIds: number[];
    voiesEnGare: { name: string; index: number; longueurDirection1: number }[];
    icon: string;
    description: string;
    fromDateToDateText: string;
    frequencyText: string;
    horairesModified: boolean; // Only for child ops - True if the child op's horaires differ from the parent op's horaires
    isJTApplied: boolean;
    indispoPartSelected: string;
    repetitionIcon?: boolean; // Only for repetition on conception
  };
}

export enum TravauxOpType {
  VEG = 'VEG',
  ZEP = 'ZEP',
  SEL = 'SEL',
  INDISPO_ITI = 'INDISPO_ITI',
}

export enum TravauxSousTypeOperationVEG {
  TOTAL_INDISPO = 'TOTAL_INDISPO',
  MISE_BUTOIR = 'MISE_BUTOIR',
  REDUC_LONG_UTILE = 'REDUC_LONG_UTILE',
}

/**
 * Search criteria to find specific travaux ops.
 */
export interface TravauxSearchParams {
  name: string;
  timeIntervals?: { startDatetime: string; endDatetime: string }[];
  nomsVEG?: string[];
}

// Search params in a format compatible with the backend
export interface TravauxSearchParamsBACK {
  typeTravaux: TravauxOpType;
  id?: number;
  nom?: string;
  timeIntervals?: { dateHeureDebut: string; dateHeureFin: string }[];
  nomsVEG?: string[];
}

/**
 * ============================
 * ======== FUNCTIONS =========
 * ============================
 */

/**
 * Create a frontend-friendly travaux op from a travaux op DTO.
 *
 * @param travauxOpDto  The travaux op from the backend.
 *                      When the op is a child op rehydrated independently of its parent op,
 *                      the `travauxOpDto.$parentOp` property will be set.
 * @param sortedVaqList All VAQs for the current station, sorted by `positionGOV`.
 * @param parentOp      The parent travaux op. This param will be set when rehydrating
 *                      a child op as a part of its parent op.
 */
export function createTravauxOpFromDto(
  travauxOpDto: TravauxOpSelection,
  opts: {
    sortedVaqList: VoieAQuai[];
    conflictDtos?: ConflictDto[];
    parentOp?: TravauxOpSelection;
    jtTravauxOpsSelection?: TravauxOpSelection[];
    isSubpart?: boolean;
  },
): TravauxOp {
  const parentOp = opts.parentOp || travauxOpDto.$parentOp;
  const isChildOp = !!parentOp;
  const voiesEnGare = opts.isSubpart ? undefined : travauxOpDtoProcessVegs(travauxOpDto, opts.sortedVaqList);

  const travauxOp: TravauxOp = {
    // Preserve backend properties
    id: travauxOpDto.id,
    rid: travauxOpDto.rid,
    typeTravaux: TravauxOpType[travauxOpDto.typeTravaux],
    typeIndispo: opts.isSubpart ? undefined : TypeIndispo[travauxOpDto.typeIndispo],
    nom: travauxOpDto.nom,
    nomsVEG: travauxOpDto.nomsVEG,
    sousTypeOperationVEG: TravauxSousTypeOperationVEG[travauxOpDto.subtypeOperationVEG],
    direction1: CardinalDirection[travauxOpDto.direction1],
    longueur1: travauxOpDto.longueur1,
    direction2: CardinalDirection[travauxOpDto.direction2],
    longueur2: travauxOpDto.longueur2,
    dateHeureDebut: moment(travauxOpDto.dateHeureDebut),
    dateHeureFin: moment(travauxOpDto.dateHeureFin),
    dateHeureProjDebut: moment(travauxOpDto.dateHeureProjDebut),
    dateHeureProjFin: moment(travauxOpDto.dateHeureProjFin),
    afficherSurGOV: travauxOpDto.display,
    // Répétition
    repetition: travauxOpDtoGetRepetition(travauxOpDto),
    children: travauxOpDto.children
      ? _.sortBy(travauxOpDto.children, ['dateHeureDebut']).map(childOp =>
          createTravauxOpFromDto(childOp, {
            sortedVaqList: opts.sortedVaqList,
            conflictDtos: opts.conflictDtos,
            parentOp: travauxOpDto,
            jtTravauxOpsSelection: opts.jtTravauxOpsSelection,
          }),
        )
      : null,
    childrenSelections: travauxOpDto.childrenSelections
      ? _.sortBy(travauxOpDto.childrenSelections, ['dateHeureDebut']).map(childOp =>
          createTravauxOpFromDto(childOp, {
            sortedVaqList: opts.sortedVaqList,
            conflictDtos: opts.conflictDtos,
            parentOp: travauxOpDto,
            jtTravauxOpsSelection: opts.jtTravauxOpsSelection,
            isSubpart: true,
          }),
        )
      : null,
    groupId: isChildOp ? parentOp.id : null,
    indispoPart: travauxOpDto.indispoPart,
    // Frontend-specific props
    $sys: {
      conflictIds: opts.conflictDtos ? travauxOpGetConflictIds(travauxOpDto, opts.conflictDtos) : [],
      voiesEnGare,
      icon: travauxOpDtoGetIcon(travauxOpDto),
      description: travauxOpGetDescription(travauxOpDto, voiesEnGare),
      fromDateToDateText: getUserFriendlyFromDateToDate(travauxOpDto.dateHeureDebut, travauxOpDto.dateHeureFin),
      frequencyText: travauxOpGetUserFriendlyFrequency(isChildOp ? parentOp : travauxOpDto),
      horairesModified: isChildOp && isChildTravauxOpModified(travauxOpDto, parentOp),
      isJTApplied: isJTApplied(isChildOp ? parentOp.id : travauxOpDto.id, travauxOpDto.indispoPart, opts.jtTravauxOpsSelection),
      indispoPartSelected: getIndispoPart(travauxOpDto.id, opts.jtTravauxOpsSelection),
      repetitionIcon: travauxOpDto.repetition ? true : false,
    },
  };

  return travauxOp;
}

function isJTApplied(travauxOpId: number, indispoPart: string, travauxOpSelectionList: TravauxOpSelection[]) {
  const foundSelection = travauxOpSelectionList?.find(travauxOpSelection => travauxOpSelection.id === travauxOpId);
  if (foundSelection) {
    if (!indispoPart) {
      // single or parent
      return true;
    } else {
      // Child
      if (foundSelection.indispoPart === indispoPart) {
        // H24/BEFORE/AFTER
        return true;
      } else if (
        foundSelection.indispoPart === IndispoPart.BEFORE_AFTER_MIDNIGHT &&
        (indispoPart === IndispoPart.BEFORE_MIDNIGHT || indispoPart === IndispoPart.AFTER_MIDNIGHT)
      ) {
        return true;
      }
    }
  }

  return false;
}

function getIndispoPart(travauxOpId: number, travauxOpSelectionList: TravauxOpSelection[]) {
  return travauxOpSelectionList?.find(travauxOpSelection => travauxOpSelection.id === travauxOpId)?.indispoPart;
}

/**
 * Convert a frontend-friendly travaux op into a backend-friendly DTO.
 *
 * @param travauxOp The travaux op to convert.
 */
export function createDtoFromTravauxOp(travauxOp: TravauxOp): TravauxOpDto {
  const repInfo = travauxOp.repetition;
  const travauxOpDto: TravauxOpDto = {
    id: travauxOp.id,
    rid: travauxOp.rid,
    typeTravaux: travauxOp.typeTravaux,
    nom: travauxOp.nom,
    dateHeureDebut: travauxOp.dateHeureDebut.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    dateHeureFin: travauxOp.dateHeureFin.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    typeIndispo: travauxOp.typeIndispo,
    nomsVEG: travauxOp.nomsVEG,
    subtypeOperationVEG: travauxOp.sousTypeOperationVEG,
    direction1: travauxOp.direction1,
    longueur1: travauxOp.longueur1,
    direction2: travauxOp.direction2,
    longueur2: travauxOp.longueur2,
    display: travauxOp.afficherSurGOV,
    repetition: repInfo ? { ...repInfo, repeatDays: repInfo.repeatDays.map(day => WEEK_DAYS.indexOf(day) + 1).join(';') } : null,
    // children: UNDEFINED ------ REMEMBER: Children COME FROM the backend, but can't be SENT TO the backend
    groupId: travauxOp.groupId, // must be set for child ops, so the backend can connect them to their parent op
  };

  return travauxOpDto;
}

/**
 * Convert a frontend-friendly travaux op into a backend-friendly DTO.
 *
 * @param travauxOp The travaux op to convert.
 */
export function createSelectionFromTravauxOp(travauxOp: TravauxOp): TravauxOpSelection {
  const repInfo = travauxOp.repetition;
  const travauxOpSelection: TravauxOpSelection = {
    id: travauxOp.id,
    rid: travauxOp.rid,
    typeTravaux: travauxOp.typeTravaux,
    nom: travauxOp.nom,
    dateHeureDebut: travauxOp.dateHeureDebut.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    dateHeureFin: travauxOp.dateHeureFin.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    dateHeureProjDebut: travauxOp.dateHeureProjDebut.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    dateHeureProjFin: travauxOp.dateHeureProjFin.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    typeIndispo: travauxOp.typeIndispo,
    nomsVEG: travauxOp.nomsVEG,
    subtypeOperationVEG: travauxOp.sousTypeOperationVEG,
    direction1: travauxOp.direction1,
    longueur1: travauxOp.longueur1,
    direction2: travauxOp.direction2,
    longueur2: travauxOp.longueur2,
    display: travauxOp.afficherSurGOV,
    indispoPart: travauxOp.indispoPart,
    repetition: repInfo ? { ...repInfo, repeatDays: repInfo.repeatDays.map(day => WEEK_DAYS.indexOf(day) + 1).join(';') } : null,
    // children: UNDEFINED ------ REMEMBER: Children COME FROM the backend, but can't be SENT TO the backend
    groupId: travauxOp.groupId, // must be set for child ops, so the backend can connect them to their parent op
    childrenSelections: travauxOp.childrenSelections
      ? travauxOp.childrenSelections.map(childOp => createSelectionFromTravauxOp(childOp))
      : null,
  };

  return travauxOpSelection;
}

/**
 * Process the "voies en gare" property of a travaux op
 * to store additional properties for each voie,
 * i.e. its index and its original length.
 */
function travauxOpDtoProcessVegs(
  travauxOpDto: TravauxOpDto,
  vaqList: VoieAQuai[],
): { name: string; index: number; longueurDirection1: number; longueurDirection2: number }[] {
  return travauxOpDto.nomsVEG.map(voieEnGare => {
    const vaq = vaqList.find(v => v.nom === voieEnGare);
    return {
      name: voieEnGare,
      index: vaqList.findIndex(v => v.nom === voieEnGare),
      longueurDirection1: (vaq || {}).longueurDirection1,
      longueurDirection2: (vaq || {}).longueurDirection2,
    };
  });
}

/**
 * Convert the `repetition` property of a travaux op.
 */
function travauxOpDtoGetRepetition(travauxOpDto: TravauxOpDto) {
  if (!_.isEmpty(travauxOpDto.repetition)) {
    return {
      // Turn a single string of day indexes into an array of day names.
      repeatDays: travauxOpDto.repetition?.repeatDays?.split(';').map(dayIndex => WEEK_DAYS[Number(dayIndex) - 1]),
      repeatEvery: Number(travauxOpDto.repetition?.repeatEvery),
      repeatUnit: travauxOpDto.repetition?.repeatUnit as REPEAT_UNIT,
    };
  }
}

/**
 * Return the name of the icon file to use to represent the given travaux op.
 *
 * There are 3 parameters determining which icon is used:
 *   - The given travaux op's type
 *   - The given travaux op's subtype
 *   - The current station's "orientation mapping".
 *
 * @TODO: Pass the station preferences to this function.
 *
 * Examples of values are:
 *   ic-works-voie-reduc-long-utile-droite.svg
 *   ic-works-catenaire-mise-butoir-gauche.svg
 *
 * @param travauxOp               The travaux op for which we want the icon.
 * @param stationOrientationMapping Mapping of a station's orientation to either "gauche" or "droite".
 */
export function travauxOpDtoGetIcon(
  travauxOp: { typeIndispo: string; subtypeOperationVEG: string; direction1?: string; direction2?: string },
  stationOrientationMapping?: StationOrientationMapping,
) {
  if (travauxOp.typeIndispo && travauxOp.subtypeOperationVEG) {
    const orientation1 = travauxOp.direction1 as CardinalDirection;
    const orientation2 = travauxOp.direction2 as CardinalDirection;
    const direction = mapStationOrientationToDirection(orientation1, orientation2, stationOrientationMapping);
    const type = travauxOp.typeIndispo.replace(/_/g, '-').toLowerCase();
    const sousType = travauxOp.subtypeOperationVEG.replace(/_/g, '-').toLowerCase();

    return `ic-works-${type}-${sousType}` + (direction ? `-${direction}` : '') + `.svg`;
  }

  return undefined;
}

/**
 * Return the direction ("gauche", "droite", "gauche-droite") for the given orientation (NORD/SUD or EST/OUEST).
 *
 * Each station can map its orientations (NORD/SUD or EST/OUEST)
 * to either the "gauche" or "droite" direction.
 */
export function mapStationOrientationToDirection(
  orientation1: CardinalDirection,
  orientation2: CardinalDirection,
  stationOrientationMapping?: StationOrientationMapping,
) {
  // @TODO: Use the station preferences to map directions to icons
  if (!stationOrientationMapping) {
    stationOrientationMapping = STATION_ORIENTATION_MAPPING_DEFAULT;
  }

  let stationOrientation = '';
  if (orientation1 && orientation2) {
    stationOrientation = stationOrientationMapping[orientation1] + '-' + stationOrientationMapping[orientation2];
  } else if (orientation1) {
    stationOrientation = stationOrientationMapping[orientation1];
  } else if (orientation2) {
    stationOrientation = stationOrientationMapping[orientation2];
  }

  return stationOrientation;
}

/**
 * Return a user-friendly description for the given travaux op.
 * This is used in the travaux list in the backoffice.
 */
function travauxOpGetDescription(
  travauxOpDto: TravauxOpDto,
  voiesEnGare: { name: string; index: number; longueurDirection1: number; longueurDirection2: number }[],
) {
  const vegInfo = voiesEnGare?.find(veg => veg.name === travauxOpDto.nomsVEG[0]);
  const longueurInitiale1 = vegInfo ? vegInfo.longueurDirection1 : 0;
  const longueurInitiale2 = vegInfo ? vegInfo.longueurDirection2 : 0;

  let description = '';
  let description1 = '';
  let description2 = '';

  switch (travauxOpDto.subtypeOperationVEG) {
    case TravauxSousTypeOperationVEG.TOTAL_INDISPO:
      description = 'Entièrement indisponible';
      break;
    case TravauxSousTypeOperationVEG.MISE_BUTOIR:
      description = 'Mise en butoir';
      break;
    case TravauxSousTypeOperationVEG.REDUC_LONG_UTILE:
      if (travauxOpDto.direction1) {
        const direction = capitalizeFirstCharOnly(travauxOpDto.direction1);
        description1 = `Longueur utile ${direction} réduite : ${longueurInitiale1} m -> ${travauxOpDto.longueur1} m`;
      }

      if (travauxOpDto.direction2) {
        const direction = capitalizeFirstCharOnly(travauxOpDto.direction2);
        description2 = `Longueur utile ${direction} réduite : ${longueurInitiale2} m -> ${travauxOpDto.longueur2} m`;
      }

      if (description1 !== '' && description2 !== '') {
        description = description1 + '\n' + description2;
      } else {
        description = description1 !== '' ? description1 : description2;
      }

      break;
    default:
      description = '*** DESCRIPTION NON TROUVÉE ***';
      break;
  }

  return description;
}

/**
 * Return a user-friendly frequency text for the given travaux op,
 * for instance "Toutes les 2 semaines : mardi, jeudi".
 *
 * This is used in the travaux list in the GOV sidebar.
 */
function travauxOpGetUserFriendlyFrequency(travauxOpDto: TravauxOpDto) {
  if (!_.isEmpty(travauxOpDto.repetition)) {
    const rep = travauxOpDto.repetition;
    const repFrontendFriendly = travauxOpDtoGetRepetition(travauxOpDto);
    let freq = '';
    if (rep.repeatUnit === REPEAT_UNIT.WEEK) {
      freq = rep.repeatEvery === 1 ? 'Répétée toutes les semaines' : `Répétée toutes les ${rep.repeatEvery} semaines`;
    } else if (rep.repeatUnit === REPEAT_UNIT.MONTH) {
      freq = rep.repeatEvery === 1 ? 'Répétée tous les mois' : `Répétée tous les ${rep.repeatEvery} mois`;
    }
    const fromDatetoDate = getUserFriendlyFromDateToDate(travauxOpDto.dateHeureDebut, travauxOpDto.dateHeureFin);
    return `${freq} ${fromDatetoDate.toLowerCase()} : ` + repFrontendFriendly.repeatDays.map(day => day.toLowerCase()).join(', ');
  }
}

/**
 * Return the conflict ids associated to the given travaux op.
 */
export function travauxOpGetConflictIds(travauxOpDto: { rid: number }, conflictDtos: ConflictDto[]) {
  return conflictDtos
    .filter(conflictDto => conflictDto.opeRid && conflictDto.opeRid === travauxOpDto.rid)
    .map(conflictDto => conflictDto.identifier);
}

/**
 * Return true if the given travaux op affects the given date.
 *
 * @param travauxOpDto MUST be a regular travauxOp (no repetition) or a child travauxOp.
 *                     PARENT TRAVAUX OPS (with repetitions) ARE NOT SUPPORTED.
 * @param dateYMD      Date to match in the YYYY-MM-DD format.
 */
export function travauxOpMatchesDate(travauxOpDto: TravauxOpDto, dateYMD: string) {
  const dateDebutYMD = travauxOpDto.dateHeureDebut.substring(0, 10);
  const dateFinYMD = travauxOpDto.dateHeureFin.substring(0, 10);
  return dateDebutYMD <= dateYMD && dateYMD <= dateFinYMD;
}

/**
 * Return all the travaux ops (DTOs) affecting the given date.
 *
 * This function supports travauxOps with repetitions
 * (it will also search in the repeated, child ops).
 */
export function findTravauxOpDtosForDate(travauxOpDtos: TravauxOpDto[], dateYMD: string) {
  return (
    travauxOpDtos
      // Flatten children ops
      .reduce((acc, tOpDto) => (tOpDto.children ? acc.concat(tOpDto.children) : acc.concat(tOpDto)), [] as TravauxOpDto[])
      // Filter out ops that don't match the given date
      .filter(tOpDto => travauxOpMatchesDate(tOpDto, dateYMD))
  );
}

/**
 * Return true if the given child travaux op's HORAIRES
 * differ from the horaires of its parent op.
 */
export function isChildTravauxOpModified(childOp: TravauxOpDto, parentOp: TravauxOpDto): boolean {
  const childHeureDebut = moment(childOp.dateHeureDebut).format(DATE_FORMATTERS.HH_MM);
  const childHeureFin = moment(childOp.dateHeureFin).format(DATE_FORMATTERS.HH_MM);
  const heureDebut = moment(parentOp.dateHeureDebut).format(DATE_FORMATTERS.HH_MM);
  const heureFin = moment(parentOp.dateHeureFin).format(DATE_FORMATTERS.HH_MM);

  return childHeureDebut !== heureDebut || childHeureFin !== heureFin;
}

/**
 * Return a blueprint for a new travauxOp with sensible defaults.
 * This is used to populate a new travauxOp form.
 */
export function getNewTravauxOp(opts: { startDateYMD?: string; endDateYMD?: string } = {}): TravauxOp {
  return {
    typeTravaux: TravauxOpType.VEG,
    typeIndispo: TypeIndispo.VOIE,
    afficherSurGOV: true,
    dateHeureDebut: opts.startDateYMD ? moment(opts.startDateYMD) : null,
    dateHeureFin: opts.endDateYMD ? moment(opts.endDateYMD) : null,
  } as TravauxOp;
}
