/**
 * @file
 * Interface describing an eqGroup that was dragged.
 */

export interface EqGroupDragged {
  // eqGroup: Equilibre[];  // DEPREC 26-JUN-2020
  eqGroupId: string;
  newVaqName: string; // Name of the "voie à quai the eqGroup was dragged to
  newMiQuaiDisplay?: string; // Name of the mi-quai if the vaq has mi-quais, otherwise it's undefined
}
