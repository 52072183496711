<div class="header flex row" [class.calendar]="isCalendarPage" [style.width]="headerWidth + 'px'">
  <!-- Logo and breadcrumb -->
  <div class="flex col1">
    <a routerLink="/" class="logo" [title]="version">
      <img src="/assets/img/logo-header.svg" [alt]="version" />
    </a>

    <app-breadcrumb
      *ngIf="station || isAdminMode"
      [station]="station"
      [workMode]="workMode"
      [workModeLabel]="workModeLabel"
      [bcFragments]="bcFragments"
      [bcCalendarWhen]="bcCalendarWhen"
    >
    </app-breadcrumb>
  </div>
  <!-- End of Logo and breadcrumb -->

  <!-- Specific to calendar Adaptation -->
  <div class="flex col2" *ngIf="isCalendarPage">
    <ng-content></ng-content>
  </div>
  <!-- End of Specific to calendar Adaptation -->

  <!-- GOV Actions -->
  <div class="flex col3">
    <div *ngIf="isGOVPage && (canApplyJT || canOptimize)" class="flex col3-subpart start">
      <span class="point-separation"></span>
      <div class="flex gov-actions">
        <app-apply-journee-type-button
          *ngIf="canApplyJT"
          [lastAppliedJtDescription]="govData.lastAppliedJtDescription"
          (applyJourneeType)="onApplyJourneeType()"
        >
        </app-apply-journee-type-button>

        <app-synchronize-pdt-menu
          *ngIf="canSynchronize"
          [userPermissions]="userPermissions"
          [isGovSynchronizedHouat]="govData.isGovSynchronizedHouat"
          [isGovSynchronizedPasseMinuit]="govData.isGovSynchronizedPasseMinuit"
          [isGovNotEditable]="govData.isGovNotEditable"
          [modifCount]="govData.modifCount"
          (synchronizeTp)="onSynchronizeTp($event)"
        >
        </app-synchronize-pdt-menu>

        <app-optimize-button *ngIf="canOptimize" [isGovNotEditable]="govData.isGovNotEditable" (optimizeTp)="onOptimizeTp()">
        </app-optimize-button>
      </div>
    </div>
    <div *ngIf="canCertify || canValidateJT || canExport || canViewTravaux" class="flex col3-subpart end">
      <div class="flex gov-actions">
        <!-- Certify -->
        <app-certify-button
          *ngIf="canCertify"
          [isGovCertified]="govData.isGovCertified"
          [stationCode]="govData.stationCode"
          [tpRid]="govData.tpRid"
          [tpDate]="govData.tpDate"
          [modifCount]="govData.modifCount"
          (updateTp)="onUpdateTp($event)"
        >
        </app-certify-button>

        <!-- Validate JT -->
        <app-validate-journee-type-button
          *ngIf="canValidateJT"
          [isGOVJTValidated]="govData.isGovJTValidated"
          [modifCount]="govData.modifCount"
          (updateTp)="onUpdateTp($event)"
        >
        </app-validate-journee-type-button>

        <app-export-menu *ngIf="canExport" [workMode]="workMode" [userPermissions]="userPermissions" (export)="onExport($event)">
        </app-export-menu>
      </div>
      <app-travaux-menu *ngIf="canViewTravaux" [station]="station"> </app-travaux-menu>
      <span class="point-separation"></span>
    </div>
  </div>
  <!-- End of GOV Actions -->

  <div class="flex col4">
    <app-gov-settings-menu
      *ngIf="canApplyGovSettings"
      (showGovDataFilters)="onShowGovDataFilters()"
      (showUserPreferences)="onShowUserPreferences()"
    >
    </app-gov-settings-menu>

    <app-info-menu *ngIf="isAdminMode"></app-info-menu>

    <app-user-menu [isAdmin]="isAdmin"> </app-user-menu>
  </div>
  <!-- .col4 -->
</div>
