/**
 * @file
 * Application User.
 */
import { Station } from '@app/models';
import { USER_PERMISSION } from './user-permission';

export interface User {
  username: string;
  roles: string[];
  garePermissions: GarePermissions[]; // All permissions for all stations that the user has access to
}

export interface GarePermissions {
  codeGare: string;
  garePermissions?: USER_PERMISSION[];
}

export interface UserPermissionCategory {
  [key: string]: PermissionInformations;
}

export interface PermissionInformations {
  category: string;
  label: string;
  value: string;
}

export interface UserDetailsData {
  allStations: Station[];
  allPermissions: USER_PERMISSION[];
  selectedUser: User;
}

export interface UserPermissionsFormData {
  allPermissions: USER_PERMISSION[];
  currentStation: Station;
  currentGarePermissions: GarePermissions;
}

export type USER_PERMISSIONS_IS_CHECKED = [USER_PERMISSION, boolean][];

export type USER_WITH_STATIONS_LIST = {
  stations: Station['nom'];
  username: User['username'];
  roles: User['roles'];
  garePermissions: User['garePermissions'];
}[];
