/**
 * @file
 * Service handling the communication between
 * an opened "dynamic" modal and the main page.
 *
 * We need this service because PrimeNG's dynamic dialog
 * doesn't let us exchange data between the dialog and the main page
 * other than when opening or closing the modal.
 * And the component displayed within the dynamic dialog
 * can't use inputs or outputs.
 * (And we need to handle things like form submission
 * and error display without closing the dialog.)
 */
import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalMessageService {
  private modalDataSubject = new Subject<{ modalId: string; data: any }>();
  private respSubject = new Subject<{ modalId: string; success?: any; error?: any }>();

  /**
   * Emit data from a dynamic modal to the main page.
   */
  emitDataFromModal(modalId: string, data: any) {
    this.modalDataSubject.next({ modalId, data });
  }

  getModalDataObs(modalId: string): Observable<any> {
    return this.modalDataSubject.asObservable().pipe(
      filter(info => info.modalId === modalId),
      map(info => info.data), // remove `modalId` prop
    );
  }

  /**
   * Emit a successful response from the main page to the dynamic modal.
   */
  emitSuccessToModal(modalId: string, data?: any) {
    this.respSubject.next({
      modalId,
      success: data !== undefined ? data : true,
    });
  }

  /**
   * Emit an error response from the main page to the dynamic modal.
   */
  emitErrorToModal(modalId: string, error: any) {
    this.respSubject.next({
      modalId,
      error,
    });
  }

  getRespFromPageObs(modalId: string): Observable<{ success?: any; error?: string }> {
    return this.respSubject.asObservable().pipe(
      filter(info => info.modalId === modalId),
      map(info => ({ success: info.success, error: info.error })), // remove `modalId` prop
    );
  }
}
