import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';

import { BaseService } from '@app/services';
import { Token } from '../models/token';
import { User, GarePermissions } from '../models/user';
import { environment } from 'src/environments/environment';
import { USER_PERMISSION } from '../models/user-permission';

@Injectable()
export class AuthService extends BaseService {
  private readonly authorization: string;

  constructor() {
    super();
    this.authorization = `${btoa(environment.basic_key + ':' + environment.basic_pass)}`;
  }

  login(username: string, password: string): Observable<User> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${this.authorization}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.backend.post('oauth/token', `username=${username}&password=${password}&grant_type=password`, { headers }).pipe(
      tap(token => this.cache.setToken(token)),
      mergeMap(() => this.loadUser(username)),
    );
  }

  /**
   * Log user out ---- @TODO: Call the backend!!
   */
  logout(): Observable<boolean> {
    this.cache.resetCacheAtLogout();
    return of(true);
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return token && token.access_token ? true : false;
  }

  /**
   * Return the current authentication token.
   */
  getToken(): Token | undefined {
    return this.cache.getToken();
  }

  /**
   * Update the password for the given user.
   */
  updatePassword(pwdInfo: { oldPassword: string; newPassword: string }, username: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${this.authorization}`,
      'Content-Type': 'application/json',
    });
    return this.backend.patch(`api/users/${username}/password`, pwdInfo, { headers });
  }

  /**
   * Refresh the current access token.
   */
  refreshToken(): Observable<Token | undefined> {
    return of(this.getToken()).pipe(
      tap(token => {
        if (token) {
          token.access_token = null;
        }
      }),
      map(token => this.cache.setToken(token)),
      mergeMap(token => this.getRefreshToken(token && token.refresh_token)),
      map(token => this.cache.setToken(token)),
    );
  }

  private getRefreshToken(refreshToken: string | undefined): Observable<Token> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${this.authorization}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.backend.post('oauth/token', `refresh_token=${refreshToken}&grant_type=refresh_token`, { headers });
  }

  /**
   * Return the current user.
   */
  getCurrentUser(): User {
    return this.cache.getUser();
  }

  /**
   * Return the current user's permissions for the given station.
   */
  getUserPermissionsForStation(stationCode: string): USER_PERMISSION[] {
    const user = this.getCurrentUser();
    const garePermissions = user.garePermissions.find(gp => stationCode === gp.codeGare) as GarePermissions;
    return garePermissions && garePermissions.garePermissions ? garePermissions.garePermissions : [];
  }

  /**
   * Return true or false whether the current user
   * has the given permission for the given station.
   */
  hasUserPermissionForStation(permission: USER_PERMISSION, stationCode: string): boolean {
    const garePermissions = this.getUserPermissionsForStation(stationCode);
    return garePermissions.includes(permission);
  }

  /**
   * Return true if the current user has the "admin" role.
   *
   * @TODO: IMPROVE
   * As of May 2020, the "admin" role is not exposed to the frontend,
   * so we extrapolate the role from the username...
   */
  isAdminTEMP() {
    const user = this.cache.getUser();
    return user ? user.username === 'qa-admin' || user.username === 'crmadmin' : false;
  }

  //
  // Private
  //

  /**
   * Load the user DTO identified by `username`
   * and store it in the cache.
   */
  private loadUser(username: string): Observable<User> {
    return this.backend.get(`api/users/${username}`).pipe(map((user: User) => this.cache.setUser(user)));
  }
}
