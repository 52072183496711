import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import * as moment from 'moment';

import { BaseComponent } from '@app/base.component';
import { TP_ACTION } from '@app/models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-certify-button',
  templateUrl: './certify-button.component.html',
  styleUrls: ['./certify-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertifyButtonComponent extends BaseComponent implements OnInit {
  @Input() stationCode: string;
  @Input() tpRid: string; // Date YYYY-MM-DD
  @Input() tpDate: moment.Moment;
  @Input() isGovCertified: boolean;
  @Input() modifCount: number;

  @Output() updateTp = new EventEmitter<{ action: TP_ACTION }>();

  private isTooLate: boolean;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    // For E2E tests
    const currentDateQuery = this.route.snapshot.queryParamMap.get('currentDate');
    // Is it too late to certify/uncertify the GOV?
    const currentDate = !currentDateQuery ? moment() : moment(currentDateQuery);
    const diff = currentDate.endOf('day').diff(this.tpDate); // positive int == today or before == too late
    this.isTooLate = diff >= 0;
  }

  //

  certify() {
    if (this.isTooLate) {
      this.dialog.show('CERTIF__CANT_CERTIFY_DATE_CURRENT_OR_PAST');
    } else if (this.modifCount > 0) {
      this.dialog.show('CERTIF__CANT_CERTIFY_BECAUSE_GOV_IS_DIRTY');
    } else {
      this.updateTp.emit({ action: TP_ACTION.CERTIFY_GOV });
    }
  }

  unCertify() {
    if (this.isTooLate) {
      this.dialog.show('CERTIF__CANT_UNCERTIFY_DATE_CURRENT_OR_PAST');
    } else {
      this.updateTp.emit({ action: TP_ACTION.UNCERTIFY_GOV });
    }
  }
}
