import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFilterComponent {
  @Input() listToFilter: any[] = [];
  @Input() propertyToFilter: string;
  @Input() placeholder: string;

  @Output() filteredList = new EventEmitter<any[]>();

  query: string;

  filterList() {
    if (this.query.trim() === '') {
      // Reset list when query is considered as empty
      this.filteredList.emit(this.listToFilter);
    } else {
      const filteredData = this.listToFilter.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, this.propertyToFilter)) {
          return item[this.propertyToFilter].toLowerCase().includes(this.query.toLowerCase());
        } else {
          return true;
        }
      });
      this.filteredList.emit(filteredData);
    }
  }
}
