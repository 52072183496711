import { Component, Input } from '@angular/core';
import { DataTableCol } from './data-table-model';

@Component({
  selector: 'app-custom-data-table',
  templateUrl: './custom-data-table.component.html',
  styleUrls: ['./custom-data-table.component.scss'],
})
export class CustomDataTableComponent<T> {
  @Input() columns: DataTableCol[];

  @Input() data: T[];
}
