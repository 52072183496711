import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { rotatedAnimation, smoothCollapseAnimation } from '@app/animations/animation';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [smoothCollapseAnimation, rotatedAnimation],
})
export class AccordionItemComponent implements OnChanges {
  /** Shows the body of the accordion item */
  @Input() showBody = false;
  /** Indicates if the accordion has a bottom part */
  @Input() hasBottom = false;
  /** Allows to know if the accordion item should be opened */
  @Input() isOpen = false;

  // Templates
  /** To display a template on header */
  @Input() headerTemplate: TemplateRef<any>;
  /** To display a template on body */
  @Input() bodyTemplate: TemplateRef<any>;

  @Input() header: string;

  constructor() {}

  ngOnChanges(): void {
    if (this.isOpen) {
      this.showBody = true;
    }
  }

  /**
   * Action when we click on the toggle icon,
   * allows to open/close the accordion item
   */
  onToggleAccordion() {
    this.showBody = !this.showBody;
  }
}
