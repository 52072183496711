import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Token } from '../models/token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private static readonly BEARER_PREFIX: string = 'bearer';

  /**
   * Add the "Authorization: Bearer <token>" header to the given request
   * if a token if provided. If not, simply clone the request.
   */
  private static addToken(req: HttpRequest<any>, token?: Token) {
    const setHeaders =
      token && token.access_token ? { Authorization: `${AuthInterceptor.BEARER_PREFIX} ${token.access_token}` } : undefined;
    return req.clone({ setHeaders });
  }

  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('assets') > -1) {
      return next.handle(req);
    }

    const token: Token | undefined = this.auth.getToken();
    const authReq = AuthInterceptor.addToken(req, token);

    return of(authReq).pipe(
      mergeMap(authReq => next.handle(authReq)),
      catchError(err => {
        if (401 === err.status) {
          return this.auth.refreshToken().pipe(
            mergeMap(tok => {
              if (tok && tok.access_token) {
                req = AuthInterceptor.addToken(req, tok);
                return next.handle(req);
              }
              return throwError(() => err);
            }),
          );
        } else if (400 === err.status) {
          if (!token || !token.access_token) {
            this.auth.logout().subscribe();
            return throwError(() => err);
          }
          return throwError(() => err);
        }
        return throwError(() => err);
      }),
    );
  }
}
