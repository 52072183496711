import { DATE_FORMATTERS, getUserFriendlyFromDateToDate } from '@app/shared';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ConflictDto } from '.';
import { IndispoItineraireSelection } from './indispo-itineraire-selection';
import { IndispoPart } from './indispo-part';
import { TravauxOpType } from './travaux-op';
import { TypeIndispo } from './type-indispo';

/**
 * Indispo iti front BO
 */
export interface IndispoItineraire {
  id: number;
  rid: number;
  nom: string;
  veg?: string;
  vag1?: string;
  vag2?: string;
  vag3?: string;
  vel?: string;
  dateHeureDebut: moment.Moment;
  dateHeureFin: moment.Moment;
  dateHeureProjDebut?: moment.Moment; // Hour projection on GOV conception
  dateHeureProjFin?: moment.Moment; // Hour projection on GOV conception
  typeIndispo: string;
  indispoPart?: string;
  sousIndispoSelections?: IndispoItineraire[];

  typeTravaux: TravauxOpType;
  // Frontend-specific props
  $sys: {
    conflictIds: number[];
    fromDateToDateText: string;
    voies: string;
    isJTApplied: boolean;
    indispoPartSelected: string;
  };
}

/**
 * Indispo iti DTO
 */
export interface IndispoItineraireDTO {
  id: number;
  rid: number;
  nom: string;
  veg?: string;
  vag1?: string;
  vag2?: string;
  vag3?: string;
  vel?: string;
  dateHeureDebut: string;
  dateHeureFin: string;
  dateHeureProjDebut?: string; // Hour projection on GOV conception
  dateHeureProjFin?: string; // Hour projection on GOV conception
  typeIndispo: string;
}

/** Enum for modes traction/types d'alimentation */
export enum ModeTraction {
  Z = 'Z',
  X = 'X',
  BB = 'BB',
}

/**
 * Search criterias to find specific indispos itis.
 */
export interface IndispoItineraireFilter {
  id?: number;
  timeIntervals?: { dateHeureDebut: string; dateHeureFin: string }[];
}

/**
 * Return a blueprint for a new indispoIti with sensible defaults.
 * This is used to populate a new indispoIti form.
 */
export function getNewIndispoIti(opts: { startDateYMD?: string; endDateYMD?: string } = {}): IndispoItineraire {
  return {
    dateHeureDebut: opts.startDateYMD ? moment(opts.startDateYMD) : null,
    dateHeureFin: opts.endDateYMD ? moment(opts.endDateYMD) : null,
    typeIndispo: TypeIndispo.VOIE,
    typeTravaux: TravauxOpType.INDISPO_ITI,
  } as IndispoItineraire;
}

/**
 * Converts IndispoIti => IndispoItiDTO
 * @param indispoIti indispo iti
 * @returns indispo iti DTO
 */
export function createIndispoItiDTOFromIndispoIti(indispoIti: IndispoItineraire): IndispoItineraireDTO {
  const indispoItiDTO: IndispoItineraireDTO = {
    id: indispoIti.id,
    rid: indispoIti.rid,
    nom: indispoIti.nom,
    veg: indispoIti.veg,
    vag1: indispoIti.vag1,
    vag2: indispoIti.vag2,
    vag3: indispoIti.vag3,
    vel: indispoIti.vel,
    dateHeureDebut: indispoIti.dateHeureDebut.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    dateHeureFin: indispoIti.dateHeureFin.seconds(0).format(DATE_FORMATTERS.DATE_TIME),
    typeIndispo: indispoIti.typeIndispo,
  };

  return indispoItiDTO;
}

/**
 * Converts IndispoItiDTO => IndispoIti
 * @param indispoItiDTO indispo iti DTO
 * @returns indispo iti
 */
export function createIndispoItiFromDto(
  indispoItiDTO: IndispoItineraireSelection,
  opts?: {
    conflictDtos?: ConflictDto[];
    jtIndispoItiSelections?: IndispoItineraireSelection[];
    parentIndispo?: IndispoItineraireSelection;
  },
): IndispoItineraire {
  const parentIndispo = opts?.parentIndispo;
  const indispoIti: IndispoItineraire = {
    id: indispoItiDTO.id,
    rid: indispoItiDTO.rid,
    nom: indispoItiDTO.nom,
    veg: indispoItiDTO.veg,
    vag1: indispoItiDTO.vag1,
    vag2: indispoItiDTO.vag2,
    vag3: indispoItiDTO.vag3,
    vel: indispoItiDTO.vel,
    dateHeureDebut: moment(indispoItiDTO.dateHeureDebut),
    dateHeureFin: moment(indispoItiDTO.dateHeureFin),
    dateHeureProjDebut: moment(indispoItiDTO.dateHeureProjDebut),
    dateHeureProjFin: moment(indispoItiDTO.dateHeureProjFin),
    typeIndispo: indispoItiDTO.typeIndispo,
    typeTravaux: TravauxOpType.INDISPO_ITI,
    indispoPart: indispoItiDTO.indispoPart,
    sousIndispoSelections: indispoItiDTO.sousIndispoSelections
      ? _.sortBy(indispoItiDTO.sousIndispoSelections, ['dateHeureDebut']).map(sousIndispo =>
          createIndispoItiFromDto(sousIndispo, {
            conflictDtos: opts.conflictDtos,
            jtIndispoItiSelections: opts.jtIndispoItiSelections,
            parentIndispo: indispoItiDTO,
          }),
        )
      : null,

    $sys: {
      conflictIds: opts?.conflictDtos ? indispoItiGetConflictIds(indispoItiDTO, opts.conflictDtos) : [],
      fromDateToDateText: getUserFriendlyFromDateToDate(indispoItiDTO.dateHeureDebut, indispoItiDTO.dateHeureFin),
      voies: getVoies(indispoItiDTO),
      isJTApplied: opts?.jtIndispoItiSelections
        ? isJTApplied(parentIndispo ? parentIndispo.id : indispoItiDTO.id, indispoItiDTO.indispoPart, opts.jtIndispoItiSelections)
        : undefined,
      indispoPartSelected: opts?.jtIndispoItiSelections
        ? getIndispoPart(parentIndispo ? parentIndispo.id : indispoItiDTO.id, opts.jtIndispoItiSelections)
        : undefined,
    },
  };

  return indispoIti;
}

function isJTApplied(travauxOpId: number, indispoPart: string, indispoItiSelectionList: IndispoItineraireSelection[]) {
  const foundSelection = indispoItiSelectionList?.find(indispoItiSelection => indispoItiSelection.id === travauxOpId);
  if (foundSelection) {
    if (!indispoPart) {
      // single or parent
      return true;
    } else {
      // Child
      if (foundSelection.indispoPart === indispoPart) {
        // H24/BEFORE/AFTER
        return true;
      } else if (
        foundSelection.indispoPart === IndispoPart.BEFORE_AFTER_MIDNIGHT &&
        (indispoPart === IndispoPart.BEFORE_MIDNIGHT || indispoPart === IndispoPart.AFTER_MIDNIGHT)
      ) {
        return true;
      }
    }
  }

  return false;
}

function getIndispoPart(indispoItiId: number, indispoItiSelectionList: IndispoItineraireSelection[]) {
  return indispoItiSelectionList?.find(indispoItiSelection => indispoItiSelection.id === indispoItiId)?.indispoPart;
}

/**
 * @param indispoItiDTO The indispo iti DTO
 * @returns all voies (VEG, VAGs, VEL) in string format
 */
function getVoies(indispoItiDTO: IndispoItineraireDTO): string {
  return [indispoItiDTO.veg, indispoItiDTO.vag1, indispoItiDTO.vag2, indispoItiDTO.vag3, indispoItiDTO.vel].filter(Boolean).join(', ');
}

/**
 * Return the conflict ids associated to the given indispo iti.
 */
export function indispoItiGetConflictIds(indispoItiDTO: { rid: number }, conflictDtos: ConflictDto[]) {
  return conflictDtos
    .filter(conflictDto => conflictDto.opeRid && conflictDto.opeRid === indispoItiDTO.rid)
    .map(conflictDto => conflictDto.identifier);
}
