import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ColorDef } from '@app/settings';

/**
 * Component representing a panel with a group of colors
 */
@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
})
export class ColorPaletteComponent implements OnChanges {
  // The color selected
  @Input() colorSelected: string;
  // Used to position the component
  @Input() xPosition: number;
  // Used to position the component
  @Input() yPosition: number;
  // List of colors of the palette
  @Input() availableColors: ColorDef[];

  /** Sent when color is changed */
  @Output() selectColor = new EventEmitter<ColorDef>();

  paletteCssStyles: any = {};

  constructor() {}

  ngOnChanges(): void {
    this.paletteCssStyles = { top: `${this.yPosition}px`, left: `${this.xPosition}px` };
  }

  /**
   * Select color
   * @param colorDef color def
   */
  onSelectColor(colorDef: ColorDef) {
    this.selectColor.emit(colorDef);
  }
}
