import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExportService extends BaseService {
  constructor() {
    super();
  }

  exportConflicts(stationCode: string, planRid: string): Observable<any> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/rid/${planRid}/conflits?output=xlsx`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  exportGroix(stationCode: string, rid: string): Observable<any> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/export-groix?rid=${rid}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  exportTST(stationCode: string, rid?: string): Observable<any> {
    let url = `api/gares/${stationCode}/plandetransports/export-tst`;
    if (rid) {
      url += `?rid=${rid}`;
    }
    return this.backend.get(url, { observe: 'response', responseType: 'blob' });
  }

  exportCorrespondances(codeGare: string, rid: string, min: number, max: number) {
    return this.backend.get(`api/gares/${codeGare}/plandetransports/export/correspondances?rid=${rid}&min=${min}&max=${max}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  exportRoulements(rid: string, codeGare: string) {
    return this.backend.get(`api/gares/${codeGare}/plandetransports/compare/${rid}/houat?format=xlsx`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  exportOpengov(stationCode: string, rid: string): Observable<any> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/rid/${rid}/valid/v2?full=true`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  exportBaseGare(stationCode: string, rid: string): Observable<any> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/rid/${rid}/export-base-gare`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
