<section class="page-login container">
  <div class="col col-left">
    <div class="train-img">
      <div>
        <img src="/assets/img/illu-train.jpg" />
      </div>
    </div>
  </div>

  <div class="col col-right">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="item logo">
        <img src="/assets/img/logo-header.svg" />
      </div>
      <!-- Error Message -->
      <div class="item error" *ngIf="errorMsg">{{ errorMsg }}</div>
      <div class="item">
        <input type="text" placeholder="Identifiant" formControlName="login" data-cy="username" />
      </div>
      <div class="item">
        <input type="password" placeholder="Mot de passe" formControlName="password" data-cy="password" />
      </div>
      <div class="item">
        <button type="submit" class="og-btn og-btn--primary og-btn--large" [disabled]="form.invalid">
          <span>Connexion</span>
        </button>
      </div>
      <div class="item-dgex">
        <img src="/assets/img/logo-dgex.png" alt="Logo DGEX" class="pl-logo-dgex" />
      </div>
    </form>
  </div>
</section>
