import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styles: [
    `
      ngx-spinner {
        .black-overlay {
          position: fixed !important;
        }
      }
    `,
  ],
})
export class SpinnerComponent {}
