/**
 * @file
 * The Core module contains all "singleton" features,
 * i.e. features that should be imported/activated only once.
 */
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerComponent } from './spinner/spinner.component';

import { LOCAL_STORAGE } from 'ngx-webstorage-service';

import { AuthModule } from '@app/auth';
import { CustomDialogModule } from '@app/shared/custom-dialog';

import { LOCAL_STORAGE_TOKEN } from './local-storage.token';

@NgModule({
  imports: [
    // One-time imports
    HttpClientModule,
    // Contrib modules
    NgxSpinnerModule,
    // Feature modules
    AuthModule.forRoot(),
    CustomDialogModule,
  ],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent, CustomDialogModule, ToastModule],
  providers: [
    { provide: LOCAL_STORAGE_TOKEN, useExisting: LOCAL_STORAGE },
    // WEIRD: I need to "provide" this PrimeNG service manually
    // Without this, error "No provider for MessageService"
    MessageService,
  ],
})
export class CoreModule {}
