import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { DialogInfo } from './dialog-info';
import { DIALOG_PRESETS } from './dialog-presets';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogInstanceSubj; // Obs for the current dialog instance

  private dialogShowSubj = new Subject<DialogInfo>();
  dialogShow$ = this.dialogShowSubj.asObservable();
  private dialogHideSubj = new Subject<void>();
  dialogHide$ = this.dialogHideSubj.asObservable();

  /**
   * Open the dialog window and return an observable
   * that will emit a value based on the user action:
   *   - true for "OK"
   *   - false for "Cancel"
   */
  show<T>(dialogInfo: DialogInfo | string): Observable<T> {
    const dInfo = typeof dialogInfo === 'string' ? DIALOG_PRESETS.get(dialogInfo) : dialogInfo;
    if (!dInfo) {
      throw new Error('Unrecognized dialog info.');
    }
    // Always show Cancel button when it has a label
    if (dInfo.cancelButtonLabel) {
      dInfo.showCancel = true;
    }
    // Initialize the dialog properties and show it.
    this.dialogShowSubj.next(dInfo);
    this.dialogInstanceSubj = new Subject<T>();
    return this.dialogInstanceSubj.asObservable();
  }

  hide() {
    this.dialogHideSubj.next();
  }

  /**
   * Used by the Dialog component to send its response to the caller.
   *
   * Emit a "true" value if OK was clicked, or "false" if CANCEL was clicked.
   * Then, always complete.
   */
  sendResponseFromDialog(response: any) {
    this.dialogInstanceSubj.next(response);
    this.dialogInstanceSubj.complete();
  }
}
