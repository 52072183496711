/**
 * @file
 * An equilibre in a transportation plan,
 * enriched with frontend-specific properties.
 */
import * as moment from 'moment';
import * as _ from 'lodash';

import { Conflict, ConflictCategory, ConflictDto, isConflictDto } from './conflict';
import { Itineraire, MiQuai, Sens, VaqInfo, VoieSubType } from './station';
import { ColorByType, EquilibreDto, GovColorMode, Train, TypeMateriel, TypeVoie } from './transportation-plan';
import { eqGroupGetId, eqFindColorByType, eqGetTrainProp, eqFindHighlightColor, eqFindColorByComparison } from './eq-utils';
import { EQ_ACTION_ID } from './eq-action';
import { EqGroupHighlight } from './eqgroup-highlight';
import { EqCreationType } from './eq-creation-type';
import { DATE_FORMATTERS } from '@app/shared';
import { PasseMinuitComparisonDTO } from './passe-minuit-comparison';
import { TransportationPlanComparison } from './transportation-plan-comparison';

/**
 * ============================
 * ========== MODELS ==========
 * ============================
 */

export interface Equilibre {
  // Properties inherited from EquilibreDto.
  id: number;
  arrive: Train;
  depart: Train;
  typeMateriel: string;
  materielCount: number;
  position: number;
  miQuai?: string;
  miQuaiDisplay?: string;

  // Frontend-specific props.
  $sys: EquilibreSys;
}

export interface EquilibreSys {
  label: string; // "Equilibre 48673-5968" or "Train 3985"
  labelNumero?: string;

  color: string; // Color for eq lines on the GOV
  colorTrainArrive: string; // Color for the "arrive" train number
  colorTrainDepart: string; // Color for the "arrive" train number
  highlightColor: string; // Color of the highlight for train numbers

  conflictIds: number[]; // Conflicts (Général, Train non placé) associated to this equilibre
  incoherenceDonneesIds: number[]; // Conflicts (Incoherence de données) associated to this equilibre

  hasArrive: boolean; // True if the eq has an "arrive" train
  hasDepart: boolean; // True if the eq has a "depart" train
  isTrainIsole: boolean;
  isDePassage: boolean; // True if the equilibre represents a "train de passage SANS arrêt"
  isDePassageAvecArret: boolean; // True if the equilibre represents a "train de passage AVEC arrêt"
  isChangementDeParite: boolean; // True if the equilibre has a train number with changement de parite
  vaqName: string; // Name of the voie à quai where the eq is
  vaqIndex: number; // Index position of the voie à quai where the eq is
  positionInVaq: number; // Vaq position (if it's 2TMV it's the mi-quai position)
  isOnVoie2TMV: boolean; // Allows to know if we are in a mi-quai
  miQuaiName: string; // Name of the mi-quai
  miQuaiPositionRelative: string; // Position relative of the mi-quai
  miQuaiDirection: string; // Direction of the mi-quai
  isOccupationTotale: boolean; // True if the equilibre is on a voie à quai without mi-quai or is on all mi-quais
  arriveTimeMs: number; // dateHeure for arrive train in milliseconds
  departTimeMs: number; // dateHeure for arrive train in milliseconds
  arriveIti: Itineraire;
  departIti: Itineraire;
  isCoupe: boolean; // True if eq belongs to a group where both eqs have the same arrive time
  isAccroche: boolean; // True if eq belongs to a group where both eqs have the same depart time
  isUniteMultiple: boolean; // True if eq belongs to a group where another eq contains the same trains but a different `typeMateriel`
  typeMatName: string; // Type matériel
  eqGroup: {
    // Parent eqGroup of the current eq.
    id: string; // eqGroupId, e.g. "eqGroup_1573292-1573277"
    totalEqs: number; // Number of eqs in the group
    isOccupationTotale: boolean; // True if the group is on a voie à quai without mi-quai or is on all mi-quais
    isGroupBetweenMiQuais: boolean; // True if the group should be draw between the mi-quais
  };
  isShort: boolean; // True if arrival and departure time are close -- Still in use??
  actions: EQ_ACTION_ID[]; // List of actions (ids) that can be triggered from the eq
  /** Positions occupied by the group on the VAQ */
  groupPositions: number[];
  /** Nb of group in the overlap group */
  overlapGroupSize: number;
}

/**
 * Enum used to associate coupe or accroche value to an eq
 */
export enum CoupeAccrocheValue {
  COUPE,
  ACCROCHE,
  NONE,
}

/**
 * ============================
 * ======== FUNCTIONS =========
 * ============================
 */

/**
 * Create a frontend-friendly equilibre from an equilibre DTO.
 *
 * NB. Returns null if the given eq can't be linked to a vaq in `vaqList`.
 */
export function createEquilibreFromDto(
  eqDto: EquilibreDto,
  vaqList: VaqInfo[],
  eqGroupDto: EquilibreDto[] = [],
  conflicts: (ConflictDto | Conflict)[] = [],
  opts: Partial<{
    colorsLineByType: ColorByType[];
    colorModeForLines: GovColorMode;
    colorsTrainNumberByType: ColorByType[];
    colorModeForTrainNumbers: GovColorMode;
    eqGroupHighlights: EqGroupHighlight[];
    listItineraires: Itineraire[];
    listTypesMateriel: TypeMateriel[];
    stationCode: string;
    tpComparison: TransportationPlanComparison;
    passeMinuitComparison: PasseMinuitComparisonDTO;
  }> = {},
): Equilibre {
  const eqGroupId = eqGroupGetId(eqGroupDto);

  // If no parent eqGroup given, create an eqGroup containing only the current eqDto.
  eqGroupDto = eqGroupDto.length === 0 ? [eqDto] : eqGroupDto;

  // Color Options -- Merge defaults with user-supplied values
  const colorOpts = {
    colorsLineByType: [],
    colorModeForLines: GovColorMode.NO_COLOR,
    colorsTrainNumberByType: [],
    colorModeForTrainNumbers: GovColorMode.NO_COLOR,
    eqGroupHighlights: [],
    ...opts, // <-- MERGE
  };
  const { colorsLineByType, colorModeForLines, colorsTrainNumberByType, colorModeForTrainNumbers, eqGroupHighlights } = colorOpts;

  const hasArrive = eqDto.arrive !== undefined;
  const hasDepart = eqDto.depart !== undefined;
  const vaqName = hasArrive ? eqDto.arrive?.voieFin : eqDto.depart?.voieDebut;

  const vaq = vaqList.find(v => v.nom === vaqName);

  const isOnVoie2TMV = eqDto.miQuaiDisplay ? true : false;

  // Occupation totale (it means that the eq mi-quai is undefined and concerns 2TMV)
  const isOccupationTotale = !eqDto.miQuai && isOnVoie2TMV ? true : false;
  let isGroupOccupationTotale: boolean;

  // The group is in occupation totale if :
  // 1) The group contains one eq and it is in occupation totale
  // 2) The group contains eqs that are in a different mi-quai
  // 3) The group contains an eq that is in occupation totale
  // Moreover, if the group is in the second case, it should be drawn between the mi-quais
  if (eqGroupDto.length === 1) {
    isGroupOccupationTotale = isOccupationTotale;
  } else {
    const miQuais = new Set(eqGroupDto.map(e => e.miQuai));
    isGroupOccupationTotale = miQuais.size > 1 || miQuais.has(undefined);
  }

  // The group is between mi-quais if we are in 2TMV with eqs, on different miQuaiDisplay
  const miQuaiDiplaySet = new Set(eqGroupDto.map(e => e.miQuaiDisplay));
  const isGroupBetweenMiQuais = isOnVoie2TMV && isGroupOccupationTotale && miQuaiDiplaySet.size > 1;

  // Mi-quai information
  const miQuai = vaq?.miQuais?.find(miQuai => miQuai.nom === eqDto.miQuaiDisplay);

  let positionInVaq: number;
  let miQuaiName: string;
  let miQuaiPositionRelative: string;
  let miQuaiDirection: string;
  if (miQuai) {
    miQuaiName = miQuai.nom;
    // if the vaq visibility is false we assign a negative value (-10) to hide it.
    positionInVaq = vaq.visible ? miQuai.position : -10;
    miQuaiPositionRelative = miQuai.positionRelativeGOV;
    miQuaiDirection = miQuai.direction;
  } else {
    // if the vaq visibility is false we assign a negative value (-10) to hide it.
    positionInVaq = vaq?.visible ? vaq?.positions[0] : -10;
  }

  const vaqIndex = vaqList.findIndex(vaq => vaq.nom === vaqName);
  const coupeOrAccroche = eqIsCoupeOrAccroche(eqDto, eqGroupDto, isOnVoie2TMV);

  const equilibre: Equilibre = {
    // Preserve backend properties
    id: eqDto.id,
    arrive: eqDto.arrive,
    depart: eqDto.depart,
    typeMateriel: eqDto.typeMateriel,
    materielCount: eqDto.materielCount,
    position: eqDto.position,
    miQuai: eqDto.miQuai,
    miQuaiDisplay: eqDto.miQuaiDisplay,
    // Create frontend-specific props
    $sys: {
      label: getEqLabel(eqDto),
      labelNumero: eqGetOnlyNumeroLabel(eqDto),

      color:
        !opts.tpComparison && !opts.passeMinuitComparison
          ? eqFindColorByType(eqDto, colorsLineByType, colorModeForLines)
          : eqFindColorByComparison(eqDto, opts.tpComparison, opts.passeMinuitComparison),
      colorTrainArrive: eqFindColorByType(eqDto, colorsTrainNumberByType, colorModeForTrainNumbers, true),
      colorTrainDepart: eqFindColorByType(eqDto, colorsTrainNumberByType, colorModeForTrainNumbers, false),
      highlightColor: eqFindHighlightColor(eqGroupId, eqGroupHighlights),

      conflictIds: eqGetConflictIds(eqDto, conflicts),
      incoherenceDonneesIds: eqGetConflictIncoherenceDonneesIds(eqDto, conflicts),

      hasArrive,
      hasDepart,
      isTrainIsole: eqIsTrainIsole(eqDto),
      isDePassage: eqIsDePassageSansArret(eqDto),
      isDePassageAvecArret: eqIsDePassageAvecArret(eqDto),
      isChangementDeParite: eqDoesTrainPariteChange(eqDto),
      vaqName,
      vaqIndex,
      positionInVaq,
      isOnVoie2TMV,
      miQuaiName,
      miQuaiPositionRelative,
      miQuaiDirection,
      isOccupationTotale,
      arriveTimeMs: hasArrive ? getTime(eqDto.arrive.dateHeure) : 0,
      departTimeMs: hasDepart ? getTime(eqDto.depart.dateHeure) : 0,
      arriveIti:
        hasArrive && opts.listItineraires ? opts.listItineraires.find((i: Itineraire) => i.rid === eqDto.arrive.itineraireRid) : null,
      departIti:
        hasDepart && opts.listItineraires ? opts.listItineraires.find((i: Itineraire) => i.rid === eqDto.depart.itineraireRid) : null,
      isCoupe: coupeOrAccroche === CoupeAccrocheValue.COUPE,
      isAccroche: coupeOrAccroche === CoupeAccrocheValue.ACCROCHE,
      isUniteMultiple: eqIsUniteMultiple(eqDto, eqGroupDto),
      typeMatName: ((opts.listTypesMateriel && opts.listTypesMateriel.find(type => type.type === `${eqDto.typeMateriel}`)) || { nom: '' })
        .nom,
      eqGroup: { id: eqGroupId, totalEqs: eqGroupDto.length, isOccupationTotale: isGroupOccupationTotale, isGroupBetweenMiQuais },
      isShort: isTimeDiffLessThan(eqDto.arrive && eqDto.arrive.dateHeure, eqDto.depart && eqDto.depart.dateHeure, 90),
      actions: eqGetActions(eqDto, eqGroupDto, opts.stationCode, vaqName, vaqList),
      groupPositions: [],
      overlapGroupSize: 0,
    },
  };

  if ((equilibre.$sys.isDePassage || equilibre.$sys.isDePassageAvecArret) && equilibre.arrive.changeParite === undefined) {
    equilibre.arrive.changeParite = equilibre.$sys.isChangementDeParite;
  }

  return equilibre;
}

/**
 * Return a blueprint for a new equilibre with sensible defaults.
 * This is used to populate a new train/equilibre form.
 */
export function getNewEquilibre(
  eqCreationType: string,
  tpDate: moment.Moment,
  eqCreateData?: { vaqName?: string; eq?: Equilibre },
): Equilibre {
  // TODO 11/03/2024 : Improve worflow when create/edit/duplicate
  let isEquilibre = eqCreationType === EqCreationType.EQUILIBRE ? true : false;

  // In case of duplication of an existing equilibre
  if (eqCreateData && eqCreateData.eq) {
    const newEq = _.cloneDeep(eqCreateData.eq);
    newEq.id = null;
    newEq.$sys = {
      isTrainIsole: !isEquilibre,
      isDePassage: (newEq.arrive && newEq.arrive.dePassage) || (newEq.depart && newEq.depart.dePassage),
    };

    if (newEq.arrive) {
      newEq.arrive.numero = null;
      newEq.arrive.commentaire = null;
      newEq.arrive.createdByOG = true;
      newEq.$sys.hasArrive = true;
    }
    if (newEq.depart) {
      newEq.depart.numero = null;
      newEq.depart.commentaire = null;
      newEq.depart.createdByOG = true;
      newEq.$sys.hasDepart = true;
    }

    return newEq;
  }

  // in case of creation from scratch
  const dateMoment = moment();
  const dateArrivee = moment(tpDate);
  dateArrivee.set('hour', dateMoment.hours());
  dateArrivee.set('minutes', dateMoment.minutes());
  let dateDepart = moment(tpDate);
  dateDepart.set('hour', dateMoment.hours());
  dateDepart.set('minutes', dateMoment.minutes());

  // In case of equilibre, we increment the number of minutes of depart train
  // It allows to display error (it is forbidden to have same hour on arrivee and depart)
  if (eqCreationType === EqCreationType.EQUILIBRE) {
    isEquilibre = true;
    dateDepart = moment(dateDepart).add(1, 'minutes');
  }

  return {
    materielCount: 1,
    arrive: {
      dateHeure: dateArrivee.format(DATE_FORMATTERS.DATE_TIME),
      voieFin: eqCreateData?.vaqName,
      amorce: 0,
    },
    depart: {
      dateHeure: dateDepart.format(DATE_FORMATTERS.DATE_TIME),
      voieDebut: eqCreateData?.vaqName,
      amorce: 0,
    },
    $sys: {
      hasArrive: isEquilibre,
      hasDepart: isEquilibre,
      isTrainIsole: !isEquilibre,
    },
  } as Equilibre;
}

/**
 * Create an equilibre with an exiting equilibre containing one train (arrivée or départ)
 * @param existingEquilibre The existing equilibre containing one train
 * @returns The new equilibre
 */
export function createEquilibreFromOneTrain(existingEquilibre: Equilibre): Equilibre {
  const newEquilibre: Equilibre = _.cloneDeep(existingEquilibre);

  if (newEquilibre.$sys.hasArrive) {
    const depart = {
      dateHeure: moment(newEquilibre.arrive.dateHeure).add(1, 'minutes').format(DATE_FORMATTERS.DATE_TIME),
      voieDebut: newEquilibre.arrive.voieFin,
      typeVoieDebut: TypeVoie.VOIE_A_QUAI,
      typeVoieFin: TypeVoie.VOIE_EN_LIGNE,
      amorce: 0,
    } as Train;
    newEquilibre.depart = depart;
    newEquilibre.$sys.hasDepart = true;
    newEquilibre.$sys.label = 'Équilibre ' + newEquilibre.arrive.numero + ' -  ?';
  } else {
    const arrivee = {
      dateHeure: moment(newEquilibre.depart.dateHeure).subtract(1, 'minutes').format(DATE_FORMATTERS.DATE_TIME),
      voieFin: newEquilibre.depart.voieDebut,
      typeVoieFin: TypeVoie.VOIE_EN_LIGNE,
      typeVoieDebut: TypeVoie.VOIE_A_QUAI,
      amorce: 0,
    } as Train;
    newEquilibre.arrive = arrivee;
    newEquilibre.$sys.hasArrive = true;
    newEquilibre.$sys.label = 'Équilibre ' + '? - ' + newEquilibre.depart.numero;
  }

  newEquilibre.$sys.isTrainIsole = false;

  return newEquilibre;
}

/**
 * Create an equilibre DTO from a frontend-friendly equilibre.
 */
export function createDtoFromEquilibre(eq: Equilibre) {
  const eqClone: Equilibre = { ...eq };
  delete eqClone.$sys;
  return eqClone as EquilibreDto;
}

//
// Local helper functions
//

/**
 * Return the ids of the conflicts related to the given equilibre.
 */
export function eqGetConflictIds(eqDto: EquilibreDto, conflicts: (ConflictDto | Conflict)[]) {
  const categoryList = [ConflictCategory.GENERAL, ConflictCategory.TRAIN_NON_PLACE];
  return getConfictIdsForEq(eqDto, conflicts, categoryList);
}

/**
 * Return the ids of the conflicts related to the given equilibre.
 */
export function eqGetConflictIncoherenceDonneesIds(eqDto: EquilibreDto, conflicts: (ConflictDto | Conflict)[]) {
  const categoryList = [ConflictCategory.INCOHERENCE_DONNEES];
  return getConfictIdsForEq(eqDto, conflicts, categoryList);
}

/**
 * Return the ids of the conflicts related to the given equilibre.
 */
function getConfictIdsForEq(eqDto: EquilibreDto, conflicts: (ConflictDto | Conflict)[], categoryList: ConflictCategory[]) {
  const conflictIds = [
    ...getConflictIdsForTrainId(eqDto.arrive && eqDto.arrive.id, conflicts, categoryList),
    ...getConflictIdsForTrainId(eqDto.depart && eqDto.depart.id, conflicts, categoryList),
  ];
  return Array.from(new Set(conflictIds)); // make sure conflictIds are unique
}

/**
 * Return the ids of the conflicts implicating the given train.
 */
function getConflictIdsForTrainId(trainId: number, conflicts: (ConflictDto | Conflict)[], categoryList: ConflictCategory[]): number[] {
  if (!trainId) {
    return [];
  }
  return conflicts
    .filter(c => c.trainIds.some(tId => tId === trainId))
    .filter(c => categoryList.find(category => category === c.category))
    .map(c => (isConflictDto(c) ? c.identifier : c.id));
}

// Convert the given dateString (ISO 8601) in milliseconds.
function getTime(dateString: string): number {
  return moment(dateString).toDate().getTime();
}

/**
 * Return true if the difference between 2 datetimes
 * is less than the given number of seconds.
 */
export function isTimeDiffLessThan(dateStart: string, dateEnd: string, maxSeconds: number) {
  return !dateStart || !dateEnd ? false : Math.floor((getTime(dateEnd) - getTime(dateStart)) / 1000) <= maxSeconds;
}

/**
 * Return the equilibre label.
 * Examples: "Equilibre 48673-5968" or "Train 3985"
 *
 * This is used in EquilibreForm and when forming eqJoins.
 *
 * @param eq the equilibre
 * @param stationCode the station code
 */
function getEqLabel(eq: { arrive: Train; depart: Train }): string {
  // Train de passage
  const trainNumbers = [eq.arrive && eq.arrive.numero, eq.depart && eq.depart.numero].filter(Boolean);
  if (eqIsDePassageSansArret(eq) || eqIsDePassageAvecArret(eq)) {
    // Train de passage
    return (
      'Train de passage ' +
      (eqDoesTrainPariteChange(eq) ? trainNumbers[0] + '/' + trainNumbers[1][trainNumbers[1].length - 1] : trainNumbers[0])
    );
  } else {
    // Equilibre OR Train isole
    return (eq.arrive && eq.depart ? 'Équilibre ' : 'Train ') + trainNumbers.join(' - ');
  }
}

/**
 * Get the equilibre label with only numbers
 * @param eq the equilibre
 * @param stationCode the station code
 * @returns The label
 */
function eqGetOnlyNumeroLabel(eq: { arrive: Train; depart: Train }): string {
  // Train de passage
  const trainNumbers = [eq.arrive && eq.arrive.numero, eq.depart && eq.depart.numero].filter(Boolean);
  if (eqIsDePassageSansArret(eq) || eqIsDePassageAvecArret(eq)) {
    // Train de passage
    return eqDoesTrainPariteChange(eq) ? trainNumbers[0] + '/' + trainNumbers[1][trainNumbers[1].length - 1] : trainNumbers[0];
  } else {
    // Equilibre OR Train isole
    return trainNumbers.join(' - ');
  }
}

/**
 * Return true if the given eq represents a "train isolé",
 * i.e. only has an "arrive" or a "depart" train instead of 2 trains.
 */
function eqIsTrainIsole(eq: { arrive: Train; depart: Train }) {
  return eq.arrive === undefined || eq.depart === undefined;
}

/**
 * Return true if the given eq is "de passage sans arrêt",
 * meaning both its arrive and depart trains have the same horaire
 * (i.e. the train does not stop in the station).
 *
 * Trains de passage are represented by small squares on the GOV.
 *
 * FYI, this is how `dePassage` is computed on the backend:
 *   arrivee != null && depart != null && arrivee.getDateHeure().equals(depart.getDateHeure());
 */
function eqIsDePassageSansArret(eq: { arrive: Train; depart: Train }) {
  return (
    !eqIsTrainIsole(eq) &&
    Boolean(eq.arrive.dePassage) === true &&
    Boolean(eq.depart.dePassage) === true &&
    eq.arrive.dateHeure === eq.depart.dateHeure
  );
}

/**
 * Return true if the given eq "de passage avec arrêt".
 *
 * An eq is considered "de passage avec arrêt" if:
 *   - It's not a train isolé, AND...
 *     - ... arrive.numero === depart.numero
 *     - ... OR, the train number has a "changement de parité"
 *               AND the eq doesn't have the given station as its origin or destination.
 */
function eqIsDePassageAvecArret(eq: { arrive: Train; depart: Train }) {
  return (
    !eqIsTrainIsole(eq) &&
    Boolean(eq.arrive.dePassage) === true &&
    Boolean(eq.depart.dePassage) === true &&
    eq.arrive.dateHeure !== eq.depart.dateHeure
  );
}

/**
 * Allows to know if the equilibre is sans arrêt
 * @param eq The equilibre
 * @returns True if the equilibre is sans arrêt
 */
export function eqIsArrDepSameHour(eq: { arrive: Train; depart: Train }) {
  return !eqIsTrainIsole(eq) && eq.arrive.dateHeure === eq.depart.dateHeure;
}

/**
 * Return true if the numbers of the arrive and depart train
 * in the given eq represent a "changement de parité".
 *
 * Example: "W100" (arrive) --> "W101" (depart)
 */
export function eqDoesTrainPariteChange(eq: { arrive: Train; depart: Train }) {
  if (eq.arrive && eq.depart && eq.arrive.numero && eq.depart.numero) {
    const numTrain1NoLetters = eq.arrive.numero.replace(/[^0-9]/g, '');
    const numTrain2NoLetters = eq.depart.numero.replace(/[^0-9]/g, '');
    const numTrain1LastDigit = parseInt(numTrain1NoLetters.charAt(numTrain1NoLetters.length - 1), 10);
    const numTrain2LastDigit = parseInt(numTrain2NoLetters.charAt(numTrain2NoLetters.length - 1), 10);
    const smallestNumberIsEven = Math.min(numTrain1LastDigit, numTrain2LastDigit) % 2 === 0;
    return (
      numTrain1NoLetters !== '' &&
      numTrain2NoLetters !== '' &&
      Math.abs(parseInt(numTrain1NoLetters, 10) - parseInt(numTrain2NoLetters, 10)) === 1 &&
      smallestNumberIsEven
    );
  }
  return false;
}

/**
 * Return the coupe/accroche status of the given equilibre in its parent eqGroup.
 *
 * This function determines coupe/accroche status based on the train Ids.
 *
 * The given equilibre is compared to the one before it in its eqGroup:
 *   - If "arrive" trainIds are IDENTICAL and "depart" trainIds DIFFER, the eq is considered a "coupe".
 *   - If "arrive" trainIds DIFFER and "depart" trainIds are IDENTICAL, the eq is considered an "accroche".
 */
function eqIsCoupeOrAccroche(eqDto: EquilibreDto, eqGroup: EquilibreDto[], isOnVoie2TMV?: boolean): CoupeAccrocheValue {
  if (eqGroup.length === 1 || (eqDto.position === 0 && !isOnVoie2TMV)) {
    return CoupeAccrocheValue.NONE;
  }

  if (!isOnVoie2TMV) {
    return eqIsCoupeOrAccrocheNot2TMV(eqDto, eqGroup);
  } else {
    return eqIsCoupeOrAccroche2TMV(eqDto, eqGroup);
  }
}

/**
 * Return the coupe/accroche status of the given equilibre in its parent eqGroup.
 * @param eqDto The eqDto
 * @param eqGroup The eqGroup
 */
function eqIsCoupeOrAccrocheNot2TMV(eqDto: EquilibreDto, eqGroup: EquilibreDto[]): CoupeAccrocheValue {
  const previousEq = eqGroup.find(eq => eq.position === eqDto.position - 1);
  if (!previousEq) {
    return CoupeAccrocheValue.NONE;
  }

  const arriveTrainId = eqGetTrainProp(eqDto, 'id', true);
  const arriveTrainIdPrevious = eqGetTrainProp(previousEq, 'id', true);
  const departTrainId = eqGetTrainProp(eqDto, 'id', false);
  const departTrainIdPrevious = eqGetTrainProp(previousEq, 'id', false);
  const hasSameArrive = arriveTrainId === arriveTrainIdPrevious;
  const hasSameDepart = departTrainId === departTrainIdPrevious;

  if (hasSameArrive && !hasSameDepart) {
    return CoupeAccrocheValue.COUPE;
  } else if (!hasSameArrive && hasSameDepart) {
    return CoupeAccrocheValue.ACCROCHE;
  } else {
    return CoupeAccrocheValue.NONE;
  }
}

/**
 * Return the coupe/accroche status of the given equilibre in its parent eqGroup for 2TMV
 * @param eqDto The eqDto
 * @param eqGroup The eqGroup
 */
function eqIsCoupeOrAccroche2TMV(eqDto: EquilibreDto, eqGroup: EquilibreDto[]): CoupeAccrocheValue {
  for (const eq of eqGroup) {
    const arriveTrainId = eqGetTrainProp(eqDto, 'id', true);
    const arriveTrainIdPrevious = eqGetTrainProp(eq, 'id', true);
    const departTrainId = eqGetTrainProp(eqDto, 'id', false);
    const departTrainIdPrevious = eqGetTrainProp(eq, 'id', false);
    const hasSameArrive = arriveTrainId === arriveTrainIdPrevious;
    const hasSameDepart = departTrainId === departTrainIdPrevious;

    if (hasSameArrive && !hasSameDepart && moment(eqDto.depart?.dateHeure).isBefore(moment(eq.depart?.dateHeure))) {
      return CoupeAccrocheValue.COUPE;
    } else if (!hasSameArrive && hasSameDepart && moment(eqDto.arrive?.dateHeure).isAfter(moment(eq.arrive?.dateHeure))) {
      return CoupeAccrocheValue.ACCROCHE;
    }
  }

  return CoupeAccrocheValue.NONE;
}

/**
 * Return true if the given eq belongs to a group where another eq
 * contains the same trains (i.e. ids for "arrive" trains match and
 * ids for "depart" trains match) but a different `typeMateriel`.
 */
function eqIsUniteMultiple(eqDto: EquilibreDto, eqGroup: EquilibreDto[]) {
  return eqGroup.some(
    eq =>
      eqDto.id !== eq.id &&
      (eq.arrive !== undefined && eqDto.arrive !== undefined ? eqDto.arrive.id === eq.arrive.id : true) &&
      (eq.depart !== undefined && eqDto.depart !== undefined ? eqDto.depart.id === eq.depart.id : true),
  );
}

/**
 * Return the list of actions (ids) allowed for the given eq.
 */
function eqGetActions(
  eqDto: EquilibreDto,
  eqGroupDto: EquilibreDto[],
  stationCode: string,
  vaqName: string,
  vaqList: VaqInfo[],
): EQ_ACTION_ID[] {
  const actions: EQ_ACTION_ID[] = [];
  const isTrainIsole = eqIsTrainIsole(eqDto);
  const isDePassageSansArret = eqIsDePassageSansArret(eqDto);
  const isDePassageAvecArret = eqIsDePassageAvecArret(eqDto);

  // Conditions
  const canStartEqJoin = isTrainIsole; // can start a "standard" eqJoin
  const canBeSplit = !(isTrainIsole || isDePassageSansArret);
  const canStartCoupe = (isTrainIsole && eqDto.depart !== undefined) || (!isTrainIsole && !isDePassageSansArret);
  const canStartAccroche = (isTrainIsole && eqDto.arrive !== undefined) || (!isTrainIsole && !isDePassageSansArret);

  const isTrain = isTrainIsole || isDePassageSansArret || isDePassageAvecArret;

  const { canDeleteOrIgnoreTrain, canDeleteOrIgnoreEquilibre, canDeleteOrIgnoreGroup } = eqGetDeleteActions(
    eqDto,
    eqGroupDto,
    vaqName,
    vaqList,
    isTrain,
  );

  if (isTrain) {
    actions.push(EQ_ACTION_ID.DUPLICATE_TRAIN);
  }
  if (!isTrain) {
    actions.push(EQ_ACTION_ID.DUPLICATE_EQ);
  }
  if (canBeSplit) {
    actions.push(EQ_ACTION_ID.SPLIT_EQ);
  }
  if (canStartEqJoin) {
    actions.push(EQ_ACTION_ID.START_EQJOIN_STANDARD);
  }
  if (canStartCoupe) {
    actions.push(EQ_ACTION_ID.START_COUPE);
  }
  if (canStartAccroche) {
    actions.push(EQ_ACTION_ID.START_ACCROCHE);
  }
  if (canDeleteOrIgnoreTrain) {
    actions.push(EQ_ACTION_ID.DELETE_TRAIN);
  }
  if (canDeleteOrIgnoreEquilibre) {
    actions.push(EQ_ACTION_ID.DELETE_EQ);
  }
  if (canDeleteOrIgnoreGroup) {
    actions.push(EQ_ACTION_ID.DELETE_EQ_GROUP);
  }

  return actions;
}

/**
 * @param eqDto The equilibre
 * @param eqGroupDto The equilibre group
 * @param vaqName The vaqName of the equilibre
 * @param vaqList The vaq list
 * @param isTrain boolean to know if it's a train not an equilibre
 * @returns possible delete actions
 */
function eqGetDeleteActions(eqDto: EquilibreDto, eqGroupDto: EquilibreDto[], vaqName: string, vaqList: VaqInfo[], isTrain: boolean) {
  const vaq = vaqList.find(v => v.nom === vaqName);
  const isOnIgnoreVaq = vaq && vaq.subType === VoieSubType.IGNORE;

  const eqNotDeletable = (eqDto.arrive && !eqDto.arrive.createdByOG) || (eqDto.depart && !eqDto.depart.createdByOG);

  const canDeleteTrain = isTrain && eqGroupDto.length === 1 && !eqNotDeletable;
  const canIgnoreTrain = isTrain && eqGroupDto.length === 1 && eqNotDeletable && !isOnIgnoreVaq;

  const canDeleteEquilibre = !isTrain && eqGroupDto.length === 1 && !eqNotDeletable;
  const canIgnoreEquilibre = !isTrain && eqGroupDto.length === 1 && eqNotDeletable && !isOnIgnoreVaq;

  const eqGroupNotDeletable = eqGroupDto.find(eq => (eq.arrive && !eq.arrive.createdByOG) || (eq.depart && !eq.depart.createdByOG));
  const canDeleteEqGroup = eqGroupDto.length > 1 && !eqGroupNotDeletable;
  const canIgnoreEqGroup = eqGroupDto.length > 1 && eqGroupNotDeletable && !isOnIgnoreVaq;

  const canDeleteOrIgnoreTrain = canDeleteTrain || canIgnoreTrain;
  const canDeleteOrIgnoreEquilibre = canDeleteEquilibre || canIgnoreEquilibre;
  const canDeleteOrIgnoreGroup = canDeleteEqGroup || canIgnoreEqGroup;

  return { canDeleteOrIgnoreTrain, canDeleteOrIgnoreEquilibre, canDeleteOrIgnoreGroup };
}

/**
 * Get the vaq info of an equilibre
 * @param eq The equilibre
 * @param vaqList The vaq list
 * @returns The corresponding vaq
 */
export function getEquilibreVaqInfo(eq: Equilibre, vaqList: VaqInfo[]): VaqInfo {
  return vaqList.find(vaq => vaq.vaqIndex === eq.$sys.vaqIndex);
}

/**
 * Links eq groups that overlap and creates a map that regroup the overlap group
 * @param eqGroups The eq groups list
 * @param mapGroupOverlap The map containg the overlap groups
 * @param groupsAlreadyChecked The groups that have been already checked on order to not check them again
 */
export function linkOverlapGroups(
  eqGroups: Equilibre[][],
  eqGroupsMap: Map<string, Equilibre[]>,
  mapGroupOverlap: Map<string, Equilibre[][]>,
  groupsAlreadyChecked: Set<string>,
) {
  eqGroups.forEach(eqGroup => {
    if (!groupsAlreadyChecked.has(eqGroup[0].$sys.eqGroup.id)) {
      const vaq = eqGroup[0].$sys.vaqIndex;
      const eqGroupsInVaq = getEqGroupsByVaq(eqGroups, eqGroup[0].$sys.eqGroup.id, vaq);
      const eqGroupsOverlap = new Set<string>();
      hasOverlap(eqGroup, eqGroupsInVaq, eqGroupsOverlap);
      eqGroupsOverlap.forEach(eqGroupOverlap => groupsAlreadyChecked.add(eqGroupOverlap));
      if (!_.isEmpty(eqGroupsOverlap)) {
        eqGroupsOverlap.add(eqGroup[0].$sys.eqGroup.id);
        const eqGroupsOverlapSorted = Array.from(eqGroupsOverlap.values()).sort();
        const idGroupOverlap = `overlapGroup-(${Array.from(eqGroupsOverlapSorted.values()).join('|')})`;
        mapGroupOverlap.set(
          idGroupOverlap,
          eqGroupsOverlapSorted.map(eqGroupOverlap => eqGroupsMap.get(eqGroupOverlap) as Equilibre[]),
        );
      } else {
        resetEqGroupPositions(eqGroup);
      }
    }
  });
}

/**
 *
 * @param eqGroups The eq groups
 * @param eqGroupId The eq group id
 * @param vaqIndex The vaq index
 * @returns Eq groups that are in the vaq index
 */
function getEqGroupsByVaq(eqGroups: Equilibre[][], eqGroupId: string, vaqIndex: number): Equilibre[][] {
  return eqGroups.filter(eqGroup => {
    return eqGroup[0].$sys.vaqIndex === vaqIndex && eqGroup[0].$sys.eqGroup.id !== eqGroupId;
  });
}

/**
 * Check if the eq group overlaps others eq groups
 * @param eqGroup The eq group
 * @param eqGroupsInVaq The eq groups in vaq
 * @param eqGroupsOverlap The eq groups which overlap themselves
 */
function hasOverlap(eqGroup: Equilibre[], eqGroupsInVaq: Equilibre[][], eqGroupsOverlap: Set<string>) {
  if (!_.isEmpty(eqGroupsInVaq)) {
    const min = Math.min(...eqGroup.map(eq => eq.$sys.arriveTimeMs || eq.$sys.departTimeMs));
    const max = Math.max(...eqGroup.map(eq => eq.$sys.departTimeMs || eq.$sys.arriveTimeMs));

    eqGroupsInVaq
      .filter(
        eg =>
          eg[0].$sys.eqGroup.id !== eqGroup[0].$sys.eqGroup.id &&
          !eqGroupsOverlap.has(eg[0].$sys.eqGroup.id) &&
          doesOverlapBetweenEq(min, max, eg),
      )
      .forEach(eg => {
        eqGroupsOverlap.add(eg[0].$sys.eqGroup.id);
        const groups = eqGroupsInVaq.filter(eqG => eqG[0].$sys.eqGroup.id !== eqGroup[0].$sys.eqGroup.id);
        hasOverlap(eg, groups, eqGroupsOverlap);
      });
  }
}

/**
 * Reset the eq group positions
 * @param eqGroup The eq group
 */
function resetEqGroupPositions(eqGroup: Equilibre[]) {
  eqGroup.forEach(eq => {
    eq.$sys.groupPositions = [];
    eq.$sys.overlapGroupSize = 0;
  });
}

/**
 * Check if there is overlap between equilibres
 * @param minEg1 The min time of first eq group
 * @param maxEg1 The max time of first eq group
 * @param eg2 The second eq group
 * @returns true if there is overlap, false oterwise
 */
export function doesOverlapBetweenEq(minEg1: number, maxEg1: number, eg2: Equilibre[]) {
  const minEg2 = Math.min(...eg2.map(eq => eq.$sys.arriveTimeMs || eq.$sys.departTimeMs));
  const maxEg2 = Math.max(...eg2.map(eq => eq.$sys.departTimeMs || eq.$sys.arriveTimeMs));
  const overlapLeft = minEg2 <= minEg1 && maxEg2 >= minEg1;
  const overlapRight = minEg2 <= maxEg1 && maxEg2 >= maxEg1;
  const overlapMiddle = minEg2 >= minEg1 && maxEg2 <= maxEg1;
  return overlapLeft || overlapRight || overlapMiddle;
}

/**
 * Compute group positions for an eq group
 * @param eqGroup The eqGroup
 * @param positions The positions already taken
 * @param nbEqGroupsBefore The nb of eq groups that are below
 */
export function computeGroupPositions(eqGroup: Equilibre[], positions: Set<number>, nbEqGroupsBefore: number) {
  if (nbEqGroupsBefore > 0) {
    // There are groups that start before
    const availablePositions = [];
    const arrayPositions = Array.from(positions.values());
    // First we have to check if all the positions have been taken by the previous groups
    for (let i = 1; i <= Math.max(...arrayPositions); i++) {
      if (!positions.has(i)) {
        availablePositions.push(i);
      }
    }

    // if some positions are available, it means that we can place the group at these positions,
    // in order to optimize place on the VAQ. But this is possible only if the group fit (material count)
    if (!_.isEmpty(availablePositions) && availablePositions.length >= eqGroup[0].materielCount) {
      eqGroup.map(eq => (eq.$sys.groupPositions = availablePositions));
    } else {
      // If all the previous positions have been taken or there is not enough positions available for the group,
      // We place the group on the the highest position
      const highestPosition = !_.isEmpty(arrayPositions) ? Math.max(...arrayPositions) : 1;
      for (let i = highestPosition + 1; i <= highestPosition + eqGroup[0].materielCount; i++) {
        eqGroup.forEach(eq => eq.$sys.groupPositions.push(i));
      }
    }
  } else {
    // If there is no groups that start before, so the group takes the first position
    const maxLength = eqGroup.map(eq => eq.materielCount).reduce((a, b) => a + b);
    for (let i = 1; i <= maxLength; i++) {
      eqGroup.forEach(eq => eq.$sys.groupPositions.push(i));
    }
  }
}

/**
 * Filter positions regarding previous
 * @param eq The equilibre
 * @param eqGroup The eqGroup
 * @param index The index of the equilibre in the group
 */
export function filterPositions(eq: Equilibre, eqGroup: Equilibre[], index: number) {
  return eq.$sys.groupPositions.filter(
    (value, i) =>
      i + 1 >= eq.position + (index === 0 ? eq.materielCount : eqGroup[index - 1].materielCount) && i + 1 <= eq.position + eq.materielCount,
  );
}

/**
 * Allows to know if a train is in occupation totale
 * @param eqGroup The eqGroup
 * @param eqId The eqId
 * @param train The train
 * @param isArrivee true if it is arrivee, false otherwise
 * @param actualMiQuai The actual miQuai
 * @returns true if it's in occupation totale, false otherwise
 */
export function isTrainInOccupationTotale(
  eqGroup: Equilibre[],
  eqId: number,
  train: Train,
  isArrivee: boolean,
  actualMiQuai: MiQuai,
): boolean {
  if (!actualMiQuai) {
    // Occupation totale
    return true;
  } else {
    // If it's not a new train and the group has others eqs,
    // we check others eqs mi-quai
    if (eqId && eqGroup && eqGroup.length > 1) {
      let linkedEqs: Equilibre[] = [];
      if (isArrivee) {
        linkedEqs = eqGroup.filter(eq => eq.id !== eqId && eq.arrive.id === train.id);
      } else if (!isArrivee) {
        linkedEqs = eqGroup.filter(eq => eq.id !== eqId && eq.depart.id === train.id);
      }

      const miQuais = new Set(linkedEqs.map(e => e.miQuai));
      miQuais.add(actualMiQuai.nom);

      return miQuais.size > 1 || miQuais.has(undefined);
    } else {
      return false;
    }
  }
}

/**
 * Check if a train is passe-minuit
 * - indiceJour < 0 ===> J-1
 * - indiceJour === 0 ===> J
 * - indiceJour > 0 ===> J+1
 * @param train The train
 * @returns true if passe-minuit.
 */
export function isPasseMinuit(train: Train) {
  return (train.sens === Sens.ARRIVEE && train.indiceJour < 0) || (train.sens === Sens.DEPART && train.indiceJour > 0);
}
