/**
 * @file
 * App-wide utility functions.
 */

import * as _ from 'lodash';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { SortDirection } from './models/misc-models';
import { Dropdown } from 'primeng/dropdown';

/**
 * Create a clickable link pointing to a blob
 * and click it programmatically to trigger
 * a download from the browser.
 */
export function downloadFileFromResponse(response: any, fileName: string) {
  const url = window.URL.createObjectURL(response);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove(); // remove the element
}

/**
 * Return true if the given MouseEvent originated
 * on an DOM element that is a child of the DOM
 * element for the given css selector.
 */
export function isMouseEventAChildOfElement(event: MouseEvent, cssSelector: string) {
  const parentElts = event.composedPath && event.composedPath();
  const targetEl = document.querySelector(cssSelector);
  if (parentElts) {
    for (const parentElt of parentElts) {
      if (parentElt === targetEl) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Transform the given object into a query string name=toto&age=42
 */
export function toQueryString(obj: { [k: string]: string }) {
  const fragments: string[] = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      fragments.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return fragments.join('&');
}

/**
 * Sort a list by column
 * @param colName col to sort
 * @param listToSort list to sort
 * @param mapSorting map containing pair of (column - last sort direction)
 */
export function onSortListByCol(colName: string, listToSort: any[], mapSorting: any) {
  mapSorting[colName] = mapSorting[colName] === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
  return mapSorting[colName] === SortDirection.ASC ? _.sortBy(listToSort, [colName]) : _.reverse(_.sortBy(listToSort, [colName]));
}

/**
 * Get the fileName from Content-Disposition
 * @param res response from download request file
 * @return fileName
 */
export function getFileName(res: Response): string {
  // Example of Content-Disposition : "attachment; filename=\toto\""
  const contentDisposition = res.headers?.get('content-disposition');
  return contentDisposition ? contentDisposition.split('"')[1] : contentDisposition;
}

/**
 * Find invalid controls in a form group
 * @param form form group
 * @returns invalid controls
 */
export function findInvalidControls(form: UntypedFormGroup) {
  const invalid = [];
  const controls = form.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  return invalid;
}

export function getFormValidationErrors(form: UntypedFormGroup) {
  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });
}

/**
 * Convert data to FormData
 * @param formData The formData
 * @param data The data
 * @param parentKey The parentKey
 */
export function buildFormData(formData: FormData, data: any, parentKey?: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    const isArray = data instanceof Array;
    Object.keys(data).forEach(key => {
      let newParentKey: string;
      if (parentKey) {
        newParentKey = !isArray ? `${parentKey}.${key}` : `${parentKey}[${key}]`;
      }
      buildFormData(formData, data[key], parentKey ? newParentKey : key);
    });
  } else {
    if (data != null) {
      formData.append(parentKey, data);
    }
  }
}

/**
 * Convert data to FormData
 * @param data The data
 * @returns the formData
 */
export function dataToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

/**
 * Set translate on a given HTML element
 * @param xPosition The x position
 * @param yPosition The y position
 * @param el The HTMLElement
 */
export function setTranslate(xPosition: number, yPosition: number, el: HTMLElement) {
  el.style.transform = 'translate3d(' + xPosition + 'px, ' + yPosition + 'px, 0)';
}

/**
 * @param text The text to format
 * @returns The text formatted
 */
export function toUpperCaseFirstChar(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Computes the text dimensions
 * @param text The text
 * @param font The font
 * @returns The text dimensions
 */
export function computeTextDimensions(text: string, font: string): { width: number; height: number } {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  context.font = font;
  const tsize = { width: context.measureText(text).width, height: parseInt(context.font, 10) };
  return tsize;
}

/**
 * Computes the text dimensions for font avenir heavy
 * @param text The text
 * @param fontSize The font size
 * @returns The text dimensions
 */
export function computeTextDimensionsAvenirHeavy(text: string, fontSize: number): { width: number; height: number } {
  return computeTextDimensions(text, `${fontSize}px Avenir Heavy`);
}

/**
 * Computes the text dimensions for font avenir
 * @param text The text
 * @param fontSize The font size
 * @returns The text dimensions
 */
export function computeTextDimensionsAvenir(text: string, fontSize: number): { width: number; height: number } {
  return computeTextDimensions(text, `${fontSize}px Avenir`);
}

/**
 * Computes the text dimensions of text SVG
 * @param text The text
 * @param font The font
 * @param degrees The degrees if there is a rotation
 * @param anchor The text anchor
 * @returns The text dimensions
 */
export function computeTextSvgDimensions(
  text: string,
  font: {
    size: string;
    family: string;
  },
  degrees?: string,
  anchor?: string,
) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttributeNS(null, 'id', 'svgTmp');
  svg.setAttributeNS(null, 'height', '120');
  svg.setAttributeNS(null, 'width', '100%');

  document.getElementsByTagName('body')[0].appendChild(svg);
  const svgTmp = document.getElementById('svgTmp');

  const textTmp = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  const x = '0';
  const y = '0';
  textTmp.setAttributeNS(null, 'x', x);
  textTmp.setAttributeNS(null, 'y', y);

  textTmp.setAttributeNS(null, 'font-size', font.size);
  textTmp.setAttributeNS(null, 'font-family', font.family);

  if (degrees) {
    textTmp.setAttributeNS(null, 'transform', `rotate(${degrees}, ${x}, ${y})`);
  }

  if (anchor) {
    textTmp.setAttributeNS(null, 'text-anchor', anchor);
  }

  const textContent = document.createTextNode(text);
  textTmp.appendChild(textContent);

  svgTmp.appendChild(textTmp);

  const boundClientRect = textTmp.getBoundingClientRect();

  const dimensions = {
    width: boundClientRect.width,
    height: boundClientRect.height,
  };

  document.getElementsByTagName('body')[0].removeChild(svgTmp);

  return dimensions;
}

/**
 * Computes the text dimensions of text SVG with font family 'Avenir Heavy'
 * @param text The text
 * @param font The font
 * @param degrees The degrees if there is a rotation
 * @param anchor The text anchor
 * @returns The text dimensions
 */
export function computeTextSvgDimensionsAvenirHeavy(text: string, fontSize: string, degrees?: string, anchor?: string) {
  return computeTextSvgDimensions(text, { size: fontSize, family: 'Avenir Heavy' }, degrees, anchor);
}

/**
 * Computes the text dimensions of text SVG with font family 'Avenir'
 * @param text The text
 * @param fontSize The font size
 * @param degrees The degrees if there is a rotation
 * @param anchor The text anchor
 * @returns The text dimensions
 */
export function computeTextSvgDimensionsAvenir(text: string, fontSize: string, degrees?: string, anchor?: string) {
  return computeTextSvgDimensions(text, { size: fontSize, family: 'Avenir' }, degrees, anchor);
}

/**
 * Reset dropdown filter
 * @param dropdown The dropdown
 */
export function resetDropDownFilter(dropdown: Dropdown) {
  dropdown.resetFilter();
}

/**
 *
 * @param text The text
 * @returns the text with only the first char capitalized.
 */
export function capitalizeFirstCharOnly(text: string) {
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
}
