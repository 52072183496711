<div class="bread-crumb">
  <span class="fragment" *ngFor="let fragment of breadcrumb">
    <ng-container *ngIf="fragment.path">
      <a [routerLink]="fragment.path" [queryParams]="fragment.queryParams" [pTooltip]="fragment.tooltip" tooltipPosition="bottom">
        <img *ngIf="fragment.iconPath" [src]="fragment.iconPath" class="headerIcon" />
        {{ fragment.label }}
      </a>
    </ng-container>
    <ng-container *ngIf="!fragment.path">
      <div class="no-path-wrapper" [pTooltip]="fragment.tooltip" tooltipPosition="bottom">
        <img *ngIf="fragment.iconPath" [src]="fragment.iconPath" class="headerIcon" />
        <span class="text-overflow-elipsis">{{ fragment.label }}</span>
      </div>
    </ng-container>
  </span>
</div>
