import * as moment from 'moment';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { WeekDay } from './week-day';

/**
 * @file
 * Date helper functions and variables.
 */
export type DayOfWeek = 'LUNDI' | 'MARDI' | 'MERCREDI' | 'JEUDI' | 'VENDREDI' | 'SAMEDI' | 'DIMANCHE';

export const WEEK_DAYS: DayOfWeek[] = ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'];

// TODO 03/08/2021 Temporary until we see what the backend will send for journee types days
export type DayOfWeek2 = 'Lu' | 'Ma' | 'Me' | 'Je' | 'Ve' | 'Sa' | 'DF' | 'LF';

export const WEEK_DAYS2: DayOfWeek2[] = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'DF', 'LF'];

/**
 * Enum storing the misc date formats.
 */
export enum DATE_FORMATTERS {
  YYYY_MM_DD = 'YYYY-MM-DD',
  YYYY_MM = 'YYYY-MM', // active year/month in calendar
  DD_MM_YYYY = 'DD/MM/YYYY', // user-friendly full date
  DATE_TIME = 'YYYY-MM-DDTHH:mm:ss', // train horaire, horaireMin/horaireMax in a visibility rectangle
  DATE_TIME_2 = 'YYYY-MM-DD HH:mm:ss',
  DD_MM = 'DD/MM',
  HH_MM = 'HH:mm',
  HH_MM_SS = 'HH:mm:ss',
  MMMM = 'MMMM',
  YYYY = 'YYYY',
}

/**
 * Intervals for time repetitions.
 */
export enum REPEAT_UNIT {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

/**
 * Convert an array of seven boolean values to a list of days of the week.
 * Each day that's "true" will be included in the list.
 */
export function booleansToDayNames(selectedDaysOfWeek: (true | false)[]): DayOfWeek[] {
  return selectedDaysOfWeek ? WEEK_DAYS.filter((day, index) => selectedDaysOfWeek[index]) : [];
}
/**
 * Convert a list of day names to a 7-position array of true/false values.
 * Each day present in the list becomes "true".
 */
export function dayNamesToBooleans(dayNames: DayOfWeek[] = []): boolean[] {
  return WEEK_DAYS.map(weekDay => dayNames.includes(weekDay));
}

/**
 * Merge two JS Date objects into one, the first object
 * representing the date and the second the time.
 *
 * Return the generated date as a Moment object.
 *
 * @param date JS Date object from which we extract the * DATE * part (YYYY-MM-DD)
 * @param time JS Date object from which we extract the * TIME * part (HH:mm:ss)
 */
export function mergeDateAndTime(date: Date, time: Date) {
  const d = moment(date);
  const t = moment(time);
  return d.hours(t.hours()).minutes(t.minutes()).seconds(0).clone();
}

/**
 * Return a user-friendly "from DATE to DATE" text.
 *
 * Exemples:
 *   - Le 15/11/2020
 *   - Du 15/11/2020 au 18/11/2020
 *
 * This is used in the travaux list in the GOV sidebar.
 */
export function getUserFriendlyFromDateToDate(startDate: string, endDate: string) {
  const startDateDMY = moment(startDate).format(DATE_FORMATTERS.DD_MM_YYYY);
  const endDateDMY = moment(endDate).format(DATE_FORMATTERS.DD_MM_YYYY);
  const startHour = moment(startDate).format(DATE_FORMATTERS.HH_MM);
  const endHour = moment(endDate).format(DATE_FORMATTERS.HH_MM);

  return startDateDMY === endDateDMY
    ? `Le ${startDateDMY} ${startHour}-${endHour}`
    : `Du ${startDateDMY} ${startHour} au ${endDateDMY} ${endHour}`;
}

/**
 * @param dateToCheck The date to check
 * @returns true if it's a valid date or false
 */
export function isValidDate(dateToCheck: Date): boolean {
  return dateToCheck instanceof Date && !isNaN(dateToCheck.valueOf());
}

/**
 * Get the jour semaine
 * @param date The date
 * @param holidays The holidays
 * @returns The jour semaine
 */
export function getDayAsWeekDay(date: string, holidays: HolidaysTypes.Holiday[]): WeekDay {
  const momentDate = moment(date).clone();
  const day = momentDate.format('dd');
  if (day === 'di' || isPublicHoliday(date, holidays)) {
    return WeekDay.DF;
  }
  return day.toUpperCase() as WeekDay;
}

/**
 * @param date The date
 * @returns The day of the date in format 'dd' upercase
 */
export function getWeekDay(date: string): string {
  return moment(date).clone().format('dd').toUpperCase();
}

/**
 * Check if it's a public holiday
 * (Pentecôte it's actually not a public holiday)
 * @param date The date
 * @param holidays The holidays
 * @returns true if it's a public holiday or false
 */
export function isPublicHoliday(date: string, holidays: HolidaysTypes.Holiday[]): boolean {
  return holidays.find(holiday => holiday.date === date && holiday.type === 'public' && holiday.name !== 'Pentecôte') ? true : false;
}

/**
 * Check if it's a day after a public holiday
 * (Pentecôte it's actually not a public holiday)
 * @param date The date
 * @returns true if it's a public holiday or false
 */
export function isAfterPublicHoliday(date: moment.Moment, holidays: HolidaysTypes.Holiday[]): boolean {
  const nextDate = date.clone().subtract(1, 'days').format(DATE_FORMATTERS.DATE_TIME_2);
  return holidays.find(holiday => holiday.date === nextDate && holiday.type === 'public' && holiday.name !== 'Pentecôte') ? true : false;
}

/**
 * Initialize list of Holidays for a range date
 * @param hd The holidays
 * @param stratDate The start date
 * @param endDate The end date
 */
export function initPublicHolidays(hd: Holidays, startDate: moment.Moment, endDate: moment.Moment): HolidaysTypes.Holiday[] {
  let startYear = moment(startDate).clone().year();
  const endYear = moment(endDate).clone().year();

  let holidays = [];

  while (startYear <= endYear) {
    holidays = holidays.concat(hd.getHolidays(startYear));
    startYear++;
  }

  return holidays;
}

/**
 * Get the list of months between two dates
 * @param startDate The start date
 * @param endDate The end date
 * @returns list of months
 */
export function getMonthsBetweenDates(startDate: string, endDate: string): string[] {
  const months: string[] = [];

  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  while (endDateMoment > startDateMoment || startDateMoment.format('M') === endDateMoment.format('M')) {
    months.push(startDateMoment.format(DATE_FORMATTERS.YYYY_MM));
    startDateMoment.add(1, 'month');
  }

  return months;
}

/**
 * Get time interval for a specifc date
 * @param The date
 * @returns The corresponding time interval
 */
export function getTimeIntervalForOneDate(date: string): { startDatetime: string; endDatetime: string } {
  const startDatetime = `${moment(date).format(DATE_FORMATTERS.YYYY_MM_DD)}T00:00:00`;
  const endDatetime = `${moment(date).format(DATE_FORMATTERS.YYYY_MM_DD)}T23:59:59`;

  return { startDatetime, endDatetime };
}
