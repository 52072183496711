/**
 * @file
 * Models and functions related to equilibre joins.
 */
import { Equilibre, getEquilibreVaqInfo } from './equilibre';
import { EQ_ACTION_ID } from './eq-action';
import { VaqInfo } from './station';

/**
 * ============================
 * ========== MODELS ==========
 * ============================
 */

/**
 * List of all possible types of EQ JOINs -- On the FRONTEND.
 */
export enum EQJOIN_TYPE {
  NONE = 'NONE',
  STANDARD = 'STANDARD',
  COUPE = 'COUPE',
  ACCROCHE = 'ACCROCHE',
  ILLEGAL = 'ILLEGAL', // the user has selected 2 eqs that can't be joined
}

/**
 * EQ JOIN types as they are modeled on the BACKEND.
 */
export enum EQJOIN_TYPE_DTO {
  BUILD_EQUILIBRE = 'BUILD_EQUILIBRE',
  BUILD_COUPE = 'BUILD_COUPE',
  BUILD_ACCROCHE = 'BUILD_ACCROCHE',
}

/**
 * List of all possible EQ JOIN statuses.
 */
export enum EQJOIN_STATUS {
  NONE = 'NONE',
  PENDING = 'PENDING',
  PREVIEW = 'PREVIEW',
  OK = 'OK',
  IMPOSSIBLE = 'IMPOSSIBLE',
}

/**
 * Describe the final information for an eq JOIN.
 *
 * DO NOT confuse with `EqJoinState`, which describes an eq JOIN
 * in the state ** as it is being created **.
 */
export interface EqJoin {
  joinType: EQJOIN_TYPE;
  eq1: Equilibre;
  eq2: Equilibre;
}

// eqJoin with only the 1st eq selected
export interface EqJoinEq1 {
  joinType: EQJOIN_TYPE;
  eq1: Equilibre;
}

// eqJoin when the 2nd eq might not be selected yet
export interface EqJoinPending {
  joinType: EQJOIN_TYPE;
  eq1: Equilibre;
  eq2?: Equilibre;
}

/**
 * ===============================
 * ========== FUNCTIONS ==========
 * ===============================
 */

/**
 * Determine whether the two given eqs can be joined into a unique eq.
 *
 * A small note about ILLEGAL vs. IMPOSSIBLE:
 *   - The joinType is said to be ILLEGAL if the types of eqs in the join are incompatible,
 *     for instance if one of them is a "train de passage".
 *   - The JOIN status is said to be IMPOSSIBLE if either the joinType is ILLEGAL,
 *     or if some conditions are not met on the eq, i.e. train arrive is not before train depart.
 *
 * @param eqJoin The `eq1` and `eq2` to join. They could be any eqs from the GOV.
 *               Depending on what the user is doing, `eq1` and/or `eq2` could be undefined.
 *               `joinType` will be undefined if the user is shift-clicking the eqs on the GOV.
 * @param opts.eq1CompatibleEqIds List of eq ids that are compatible with the first eq.
 *                                This data is returned by the backend.
 */
export function eqJoinComputeStatus(
  { eq1, eq2, joinType }: Partial<EqJoin>,
  opts: { eq1CompatibleEqIds?: number[]; vaqList?: VaqInfo[] } = {},
): { status: EQJOIN_STATUS; message: string; joinType: EQJOIN_TYPE } {
  // console.log(`eqJoinComputeStatus`, { eq1, eq2, joinType, eq1CompatibleEqIds: opts.eq1CompatibleEqIds });

  // ----- FRONTEND Checks -----
  if (!eq1 && !eq2) {
    return { status: EQJOIN_STATUS.NONE, message: `Aucun élément sélectionné`, joinType: EQJOIN_TYPE.NONE };
  }

  // Only the 1st eq has been selected
  if (!eq2 && !eq1.$sys.isDePassage) {
    return { status: EQJOIN_STATUS.PENDING, message: `En cours... (seul le 1er élément a été sélectionné)`, joinType: EQJOIN_TYPE.NONE };
  }

  // ---- From here on out, we know that 2 eqs are selected
  // ---- Check if they are compatible.

  joinType = joinType || eqJoinComputeJoinTypes(eq1, eq2)[0];
  // console.log(`----- confirmed joinType`, joinType);
  const eq1IsTrainArrive = eq1.$sys.isTrainIsole && eq1.$sys.hasArrive;
  const eq1IsTrainDepart = eq1.$sys.isTrainIsole && eq1.$sys.hasDepart;

  switch (joinType) {
    case EQJOIN_TYPE.NONE:
      return { status: EQJOIN_STATUS.NONE, message: ``, joinType };

    case EQJOIN_TYPE.ILLEGAL:
      if (eq1.$sys.isDePassage || eq2.$sys.isDePassage) {
        return {
          status: EQJOIN_STATUS.IMPOSSIBLE,
          message: `Un train de passage sans arrêt ne peut pas être impliqué dans un équilibre.`,
          joinType,
        };
      }
      return {
        status: EQJOIN_STATUS.IMPOSSIBLE,
        message: `La sélection contient un élément ne pouvant pas faire partie d'un équilibre.`,
        joinType,
      };

    // Combination #1 : STANDARD EQ (train isolé + train isolé)
    case EQJOIN_TYPE.STANDARD: {
      // One train must be "arrive" and the other must be "depart".
      if (eq1.$sys.hasArrive !== !eq2.$sys.hasArrive) {
        // eq2 should be the opposite of eq1
        const typeTrain = eq1.$sys.hasArrive ? `d'arrivée` : `de départ`;
        return {
          status: EQJOIN_STATUS.IMPOSSIBLE,
          message: `Vous devez sélectionner un train d'arrivée et un train de départ (actuellement, vous avez 2 trains ${typeTrain}).`,
          joinType,
        };
      }
      // The arrive train's horaire must be anterior to the depart train's horaire.
      const arriveTimeMs = eq1IsTrainArrive ? eq1.$sys.arriveTimeMs : eq2.$sys.arriveTimeMs;
      const departTimeMs = eq1IsTrainArrive ? eq2.$sys.departTimeMs : eq1.$sys.departTimeMs;
      if (arriveTimeMs >= departTimeMs) {
        return {
          status: EQJOIN_STATUS.IMPOSSIBLE,
          message: `Le train d'arrivée doit avoir un horaire antérieur à celui du train de départ.`,
          joinType,
        };
      }
      break;
    }
    // Combination #2 : COUPE (train isolé "depart" + standard eq)
    case EQJOIN_TYPE.COUPE: {
      // The train isolé's horaire must be stricly AFTER the standard eq's arrive horaire.
      const trainIsoleHoraire1 = eq1IsTrainDepart ? eq1.depart.dateHeure : eq2.depart.dateHeure;
      const standardEqHoraire1 = eq1IsTrainDepart ? eq2.arrive.dateHeure : eq1.arrive.dateHeure;
      if (trainIsoleHoraire1 <= standardEqHoraire1) {
        return {
          status: EQJOIN_STATUS.IMPOSSIBLE,
          message: `L'horaire du train isolé au départ doit être strictement supérieur à l'horaire d'arrivée de l'équilibre sélectionné.`,
          joinType,
        };
      }
      break;
    }

    // Combination #3 : ACCROCHE (train isolé "arrive" + standard eq)
    case EQJOIN_TYPE.ACCROCHE: {
      // The train isolé's horaire must be stricly BEFORE the standard eq's depart horaire.
      const trainIsoleHoraire2 = eq1IsTrainArrive ? eq1.arrive.dateHeure : eq2.arrive.dateHeure;
      const standardEqHoraire2 = eq1IsTrainArrive ? eq2.depart.dateHeure : eq1.depart.dateHeure;
      if (trainIsoleHoraire2 >= standardEqHoraire2) {
        return {
          status: EQJOIN_STATUS.IMPOSSIBLE,
          message: `L'horaire du train isolé à l'arrivée doit être strictement inférieur à l'horaire de départ de l'équilibre sélectionné.`,
          joinType,
        };
      }
      break;
    }
  }

  // Check cich
  if (opts.vaqList) {
    const vaqEq1 = getEquilibreVaqInfo(eq1, opts.vaqList);
    const vaqEq2 = getEquilibreVaqInfo(eq2, opts.vaqList);

    if (vaqEq1.cich && vaqEq2.cich && vaqEq1.cich !== vaqEq2.cich) {
      const message = `Il n'est pas possible d'effectuer cette opération : les trains sont associés à deux PR différents.`;
      return { status: EQJOIN_STATUS.IMPOSSIBLE, message, joinType };
    }
  }

  // ----- BACKEND Checks -----

  // If the backend provided a list of compatible eqs,
  // the 2nd eq for the eqJoin must be in that list.
  if (opts.eq1CompatibleEqIds !== undefined && opts.eq1CompatibleEqIds.indexOf(eq2.id) === -1) {
    const message = `Le deuxième équilibre sélectionné n'est pas compatible avec le premier.`;
    return { status: EQJOIN_STATUS.IMPOSSIBLE, message, joinType };
  }

  return {
    status: EQJOIN_STATUS.OK,
    message: `Les 2 éléments sélectionnés sont compatibles.`,
    joinType,
  };
}

/**
 * Compute the join types that could be made between the two given eqs.
 *
 * NB. This function just figures out the JOIN types,
 * it doesn't check that the eqs are actually compatible
 * (no horaire checks for instance).
 */
export function eqJoinComputeJoinTypes(eq1: Equilibre, eq2?: Equilibre): EQJOIN_TYPE[] {
  const eq1IsTrainIsole = eq1.$sys.isTrainIsole;
  const eq2IsTrainIsole = eq2?.$sys.isTrainIsole;
  const eq1IsTrainArrive = eq1IsTrainIsole && eq1.$sys.hasArrive;
  const eq2IsTrainArrive = eq2IsTrainIsole && eq2?.$sys.hasArrive;
  const eq1IsTrainDepart = eq1IsTrainIsole && eq1.$sys.hasDepart;
  const eq2IsTrainDepart = eq2IsTrainIsole && eq2?.$sys.hasDepart;
  const eq1IsDePassage = eq1.$sys.isDePassage;
  const eq2IsDePassage = eq2?.$sys.isDePassage;

  // Only the 1st eq has been selected
  if (!eq2) {
    if (eq1.$sys.isDePassage) {
      // 1st eq is train de passage ==> eqJOIN IMPOSSIBLE
      return [EQJOIN_TYPE.ILLEGAL];
    } else {
      // Possible eqJoin types are deduced from the 1st eq's possible actions
      const mapping = {
        [EQ_ACTION_ID.START_EQJOIN_STANDARD]: EQJOIN_TYPE.STANDARD,
        [EQ_ACTION_ID.START_COUPE]: EQJOIN_TYPE.COUPE,
        [EQ_ACTION_ID.START_ACCROCHE]: EQJOIN_TYPE.ACCROCHE,
      };
      // If the eq has no actions, then an eqJoin is considered impossible.
      return eq1.$sys.actions.length > 0 ? eq1.$sys.actions.map(eqActionId => mapping[eqActionId]) : [EQJOIN_TYPE.ILLEGAL];
    }
  }
  // One of the trains is "de passage" ==> eqJOIN IMPOSSIBLE
  // None of the trains is "train isolé" ==> eqJOIN IMPOSSIBLE
  else if (eq1IsDePassage || eq2IsDePassage || (!eq1IsTrainIsole && !eq2IsTrainIsole)) {
    return [EQJOIN_TYPE.ILLEGAL];
  }
  // Combination #1 : Train isolé + Train isolé ==> STANDARD
  else if (eq1IsTrainIsole && eq2IsTrainIsole) {
    return [EQJOIN_TYPE.STANDARD];
  }
  // Combination #2 : Train isolé "depart" + Standard eq ==> COUPE
  else if (eq1IsTrainDepart || eq2IsTrainDepart) {
    return [EQJOIN_TYPE.COUPE];
  }
  // Combination #3 : Train isolé "arrive" + Standard eq ==> ACCROCHE
  else if (eq1IsTrainArrive || eq2IsTrainArrive) {
    return [EQJOIN_TYPE.ACCROCHE];
  }
}

/**
 * Return the properties that need to be "harmonized"
 * between two eqs to be joined in an eqJoin.
 *
 * - For coupes or accroches, the harmo info will be determined automatically.
 * - eq1 and eq2 will be translated to "eqArrive" and "eqDepart".
 *
 * @param eq1 First eq in the JOIN.
 * @param eq2 Second eq in the JOIN.
 */
export function eqJoinGetHarmonizeInfo({ eq1, eq2, joinType }: EqJoin): EqJoinHarmoInfo {
  let typeMateriel: string;
  let materielCount: string;
  let vaqName: string;
  let miQuai: string;

  const eqTrainIsole = eq1.$sys.isTrainIsole ? eq1 : eq2;
  const eqFull = eq1.$sys.isTrainIsole ? eq2 : eq1;
  const eqArrive = eqTrainIsole.$sys.hasArrive ? eqTrainIsole : eqFull;
  const eqDepart = eqTrainIsole.$sys.hasDepart ? eqTrainIsole : eqFull;

  // For a COUPE or ACCROCHE, use the `typeMateriel` and `materielCount`
  // from the train isolé and the `vaqName` from the full eq.
  if (joinType === EQJOIN_TYPE.COUPE || joinType === EQJOIN_TYPE.ACCROCHE) {
    typeMateriel = eqTrainIsole.typeMateriel ? eqTrainIsole.typeMateriel : '';
    materielCount = `${eqTrainIsole.materielCount}`;
    vaqName = eqFull.$sys.vaqName;
  }
  // For STANDARD eqs, use the values common between the 2 eqs if they exist.
  else {
    if (eqArrive.typeMateriel === eqDepart.typeMateriel) {
      typeMateriel = eqArrive.typeMateriel ? eqArrive.typeMateriel : '';
    }
    materielCount = eqArrive.materielCount === eqDepart.materielCount ? `${eqArrive.materielCount}` : '';
    vaqName = eqArrive.$sys.vaqName === eqDepart.$sys.vaqName ? eqArrive.$sys.vaqName : '';
    miQuai = eqArrive.miQuai === eqDepart.miQuai ? eqArrive.miQuai : '';
  }

  const harmoInfo: EqJoinHarmoInfo = {
    typeMateriel: { arrive: eqArrive.$sys.typeMatName, depart: eqDepart.$sys.typeMatName, commonValue: typeMateriel },
    materielCount: { arrive: `${eqArrive.materielCount}`, depart: `${eqDepart.materielCount}`, commonValue: materielCount },
    vaqName: { arrive: eqArrive.$sys.vaqName, depart: eqDepart.$sys.vaqName, commonValue: vaqName },
    miQuai: { arrive: eqArrive.miQuai, depart: eqDepart.miQuai, commonValue: miQuai },
  };

  return harmoInfo;
}

export interface EqJoinHarmoInfo {
  // for `typeMateriel`, `arrive` and `depart` contain the matName, but `commonValue` contains the matType
  typeMateriel: { arrive: string; depart: string; commonValue: string };
  materielCount: { arrive: string; depart: string; commonValue: string };
  vaqName: { arrive: string; depart: string; commonValue: string };
  miQuai: { arrive: string; depart: string; commonValue: string };
}

/**
 * Convert an eqJoin type to its backend counterpart value.
 */
export function eqJoinTypeToDto(joinType: EQJOIN_TYPE) {
  const MAPPING = {
    [EQJOIN_TYPE.STANDARD]: EQJOIN_TYPE_DTO.BUILD_EQUILIBRE,
    [EQJOIN_TYPE.COUPE]: EQJOIN_TYPE_DTO.BUILD_COUPE,
    [EQJOIN_TYPE.ACCROCHE]: EQJOIN_TYPE_DTO.BUILD_ACCROCHE,
  };
  return MAPPING[joinType];
}
