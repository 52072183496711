import { Equilibre, createEquilibreFromDto } from './equilibre';
import { VaqInfo } from './station';
import { EquilibreDto, Train } from './transportation-plan';
import { TrainComparisonDTO } from './transportation-plan-comparison';

export interface PasseMinuitComparisonDTO {
  modifiedTrains: TrainComparisonDTO[];
  addedGroups: EquilibreDto[][];
  deletedGroups: EquilibreDto[][];
  modifiedMrs: EquilibreComparisonDTO[];
  modifiedGroups?: AssemblageDTO[];
}

export interface PasseMinuitComparison {
  modifiedTrains: TrainComparisonDTO[];
  addedMrs: Equilibre[][];
  deletedMrs: Equilibre[][];
  modifiedMrs: EquilibreComparison[];
  modifiedGroups: Assemblage[];
}

export interface ModifiedTrain {
  modifiedFields: string[];
  refTrain: Partial<Train>;
}

export interface EquilibreComparison {
  modifiedFields: string[];
  refMaterielRoulant: Equilibre;
  otherMaterielRoulant: Equilibre;
}

export interface EquilibreComparisonDTO {
  modifiedFields: string[];
  refMaterielRoulant: EquilibreDto | PartialEquilibre;
  otherMaterielRoulant: EquilibreDto | PartialEquilibre;
}

export interface AssemblageDTO {
  refGroup: EquilibreDto[] | PartialEquilibre[];
  otherGroup: EquilibreDto[] | PartialEquilibre[];
  addedTrains: Train[] | PartialTrain[];
  deletedTrains: Train[] | PartialTrain[];
}

export interface Assemblage {
  refGroup: Equilibre[];
  otherGroup: Equilibre[];
  addedTrains: Train[];
  deletedTrains: Train[];
}

/**
 * Represents a partial equilibre for passe-minuit apply synchro response
 */
export interface PartialEquilibre {
  arrive: PartialTrain;
  depart: PartialTrain;
}

/**
 * Represents a partial train for passe-minuit apply synchro response
 */
interface PartialTrain {
  sens: string;
  numero: string;
  cich: string;
  dateHeure: string;
}

export function createPasseMinuitComparisonFromDto(
  passeMinuitComparisonDTO: PasseMinuitComparisonDTO,
  vaqList: VaqInfo[],
): PasseMinuitComparison {
  const addedDto: Equilibre[][] = [];
  passeMinuitComparisonDTO.addedGroups.forEach(groups => {
    const groupDto = [];
    groups.forEach(mr => {
      groupDto.push(createEquilibreFromDto(mr, vaqList));
    });
    addedDto.push(groupDto);
  });
  const deletedDto: Equilibre[][] = [];
  passeMinuitComparisonDTO.deletedGroups.forEach(groups => {
    const groupDto = [];
    groups.forEach(mr => {
      groupDto.push(createEquilibreFromDto(mr, vaqList));
    });
    deletedDto.push(groupDto);
  });
  const modifiedMrs: EquilibreComparison[] = [];
  passeMinuitComparisonDTO.modifiedMrs.forEach(mr => {
    modifiedMrs.push({
      modifiedFields: mr.modifiedFields,
      refMaterielRoulant: createEquilibreFromDto(mr.refMaterielRoulant as EquilibreDto, vaqList),
      otherMaterielRoulant: createEquilibreFromDto(mr.otherMaterielRoulant as EquilibreDto, vaqList),
    });
  });
  const modifiedGroups: Assemblage[] = [];
  passeMinuitComparisonDTO.modifiedGroups.forEach(gr => {
    const refGroups = [];
    const otherGroups = [];
    const addedTrains = [];
    const deletedTrains = [];
    gr.refGroup.forEach(eq => refGroups.push(createEquilibreFromDto(eq, vaqList)));
    gr.otherGroup.forEach(eq => otherGroups.push(createEquilibreFromDto(eq, vaqList)));
    gr.addedTrains.forEach(tr => addedTrains.push(createPartialTrain(tr)));
    gr.deletedTrains.forEach(tr => deletedTrains.push(createPartialTrain(tr)));
    modifiedGroups.push({
      refGroup: refGroups,
      otherGroup: otherGroups,
      addedTrains: addedTrains,
      deletedTrains: deletedTrains,
    });
  });
  return {
    modifiedTrains: passeMinuitComparisonDTO.modifiedTrains,
    addedMrs: addedDto,
    deletedMrs: deletedDto,
    modifiedMrs: modifiedMrs,
    modifiedGroups: modifiedGroups,
  };
}

export function createPartialEquilibre(arrive: Train, depart: Train): PartialEquilibre {
  return {
    arrive: arrive ? createPartialTrain(arrive) : null,
    depart: depart ? createPartialTrain(depart) : null,
  } as PartialEquilibre;
}

function createPartialTrain(train: Train): PartialTrain {
  return {
    sens: train.sens,
    numero: train.numero,
    cich: train.cich,
    dateHeure: train.dateHeure,
  } as PartialTrain;
}
