import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to manage display text (when it's empty)
 */
@Pipe({
  name: 'text',
})
export class TextPipe implements PipeTransform {
  transform(value: any, emptyText = '-'): any {
    if (typeof value === 'undefined' || value === null) {
      return emptyText;
    }

    return value;
  }
}
