import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { USER_PERMISSION } from '@app/auth';
import { BaseComponent } from '@app/base.component';
import { TP_ACTION } from '@app/models';
import { MenuItem } from 'primeng/api';

/**
 * Component representing synchronize PDT menu
 */
@Component({
  selector: 'app-synchronize-pdt-menu',
  templateUrl: './synchronize-pdt-menu.component.html',
  styleUrls: ['./synchronize-pdt-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SynchronizePdtMenuComponent extends BaseComponent implements OnInit {
  @Input() userPermissions: USER_PERMISSION[] = [];
  @Input() isGovSynchronizedHouat: boolean;
  @Input() isGovSynchronizedPasseMinuit: boolean;
  @Input() isGovNotEditable: boolean;
  @Input() modifCount: number;

  @Output() synchronizeTp = new EventEmitter<TP_ACTION>();

  menuItems: MenuItem[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.menuItems = [];
    if (this.userPermissions.includes(USER_PERMISSION.SYNCHRO_HOUAT)) {
      this.menuItems.push({ label: 'HOUAT', command: () => this.onSynchronizeTp(TP_ACTION.SYNCHRONIZE_HOUAT) });
    }

    if (this.userPermissions.includes(USER_PERMISSION.SYNCHRO_PASSE_MINUIT)) {
      this.menuItems.push({ label: 'Passe-minuit', command: () => this.onSynchronizeTp(TP_ACTION.SYNCHRONIZE_PASSE_MINUIT) });
    }
  }

  /**
   * Called on click
   */
  onSynchronizeTp(tpAction: TP_ACTION) {
    if (this.modifCount > 0) {
      this.dialog.show('SYNCHRONIZE__CANT_SYNCHRONIZE_BECAUSE_GOV_IS_DIRTY');
    } else {
      this.synchronizeTp.emit(tpAction);
    }
  }
}
