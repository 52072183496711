import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '@app/shared';

// Components
import { ChangePwdFormComponent } from './change-pwd-form/change-pwd-form.component';

// Services
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './services/auth.interceptor';

@NgModule({
  imports: [SharedModule],
  declarations: [ChangePwdFormComponent],
  exports: [ChangePwdFormComponent],
})
export class AuthModule {
  // The constructor below will only be executed if the user does NOT
  // import this module via the static methods.
  constructor(@Optional() @SkipSelf() parentModule?: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthInterceptor,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
    };
  }
}
