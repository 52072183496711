/**
 * @file
 * This page lets any component log the user out simply by taking them to a specific URL
 * instead of having to emit an event or inject a service.
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/auth';

@Component({
  selector: 'app-page-logout',
  templateUrl: './page-logout.component.html',
  styleUrls: ['./page-logout.component.scss'],
})
export class PageLogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.auth.logout().subscribe(() => {
      this.router.navigate(['/auth']);
    });
  }
}
