import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

/**
 * Component representing GOV Settings Menu
 */
@Component({
  selector: 'app-gov-settings-menu',
  templateUrl: './gov-settings-menu.component.html',
  styleUrls: ['./gov-settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GovSettingsMenuComponent implements OnInit {
  /** Event for GOV data filtering */
  @Output() showGovDataFilters = new EventEmitter<void>();

  /** Event for GOV display settings */
  @Output() showUserPreferences = new EventEmitter<void>();

  menuItems: MenuItem[];

  constructor() {}

  ngOnInit(): void {
    this.menuItems = [
      { label: `Filtres de données`, command: () => this.onShowGovDataFilters() },
      { label: `Préférences d'affichage`, command: () => this.onShowUserPreferences() },
    ];
  }

  /**
   * Emits event to show GOV data filters form dialog
   */
  onShowGovDataFilters() {
    this.showGovDataFilters.emit();
  }

  /**
   * Emits event to show GOV display settings form dialog
   */
  onShowUserPreferences() {
    this.showUserPreferences.emit();
  }
}
