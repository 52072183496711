import { Train } from './transportation-plan';

export interface TransportationPlanComparison {
  modifiedTrains: TrainComparisonDTO[];
  addedTrains: Train[];
  deletedTrains: Train[];
}

export interface TrainComparisonDTO {
  /** train rid */
  trainId: number;
  modifiedFields: string[];
  refTrain: Train;
  otherTrain: Train;
}

export enum ModifiedField {
  HORAIRE = 'HORAIRE',
  VOIE_A_QUAI = 'VOIE_A_QUAI',
  TYPE_CIRCULATION = 'TYPE_CIRCULATION',
  VOIE_EN_LIGNE = 'VOIE_EN_LIGNE',
  ORIGINE = 'ORIGINE',
  DESTINATION = 'DESTINATION',
  ITINERAIRE = 'ITINERAIRE',
  TYPE_MATERIEL = 'MATERIEL',
  NB_ELEMENTS = 'NB_ELEMENTS',
  MI_QUAI = 'MI_QUAI',
}
