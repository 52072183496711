<div class="flex row center settings_tab editPwd">
  <img src="/assets/img/change-pwd.jpg" alt="" />
  <div class="editPwd_content">
    <h1>Modification du mot de passe</h1>
    <form [formGroup]="form" (ngSubmit)="submitNewPwd()" class="flex column">
      <input type="password" placeholder="Mot de passe actuel" formControlName="oldPassword" />
      <input type="password" placeholder="Nouveau mot de passe" formControlName="password" />
      <div class="errors" [hidden]="hideErrorForPasswordField">
        <p>8 carractères minimum, un chiffre, un caractère spécial</p>
      </div>
      <input type="password" placeholder="Confirmer le nouveau mot de passe" formControlName="confirmPassword">
      <button type="submit" class="og-btn og-btn--primary og-btn--large" [disabled]="!form.valid">
        <span>Confirmer</span>
      </button>
    </form>
  </div>
</div>
