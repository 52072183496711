import { CompareTrainCategory } from './compare-train-category';
import { Equilibre } from './equilibre';
import { Assemblage } from './passe-minuit-comparison';
import { TrainTypeLabel } from './train-type';
import { Train } from './transportation-plan';

export interface CompareElement {
  compareType: CompareTrainCategory;
  elementType: CompareElementType; // Train / Equilibre / Assemblage
  compareTrain: CompareTrain;
  compareEquilibre: CompareEquilibre;
  compareAssemblage: Assemblage;
  state: string; // ACCEPTED / REJECTED / NONE
}

export interface CompareTrain {
  trainType: TrainTypeLabel; // Arrivée / Départ / Passage
  /** 0 = refTrain / 1 = otherTrain, if passage 0 = arrivee / 1 = depart */
  trains: Train[];
  compareType: CompareTrainCategory;
  modifiedField?: string;
  refValue?: string;
  targetValue?: string;
}

export interface CompareEquilibre {
  arrivee: CompareTrain;
  depart: CompareTrain;
  voieAQuai: string;
  equilibre: Equilibre;
  modifiedField?: string;
  refValue?: string;
  targetValue?: string;
  otherEquilibre?: Equilibre;
  groupEquilibre?: Equilibre[];
}

export enum CompareTrainState {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  NONE = 'NONE',
}

export enum CompareEqState {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  NONE = 'NONE',
}
export enum CompareElementType {
  TRAIN = 'Train',
  EQUILIBRE = 'Equilibre',
  ASSEMBLAGE = 'Assemblage',
}

export function createCompareTrain(
  trainType: TrainTypeLabel,
  trains: Train[],
  compareType: CompareTrainCategory,
  modifiedField?: string,
  refValue?: string,
  targetValue?: string,
): CompareTrain {
  return {
    trainType,
    trains,
    compareType,
    modifiedField,
    refValue,
    targetValue,
  };
}

export function createCompareEquilibre(
  equilibre: Equilibre,
  compareType: CompareTrainCategory,
  modifiedField?: string,
  refValue?: string,
  targetValue?: string,
  otherEquilibre?: Equilibre,
): CompareEquilibre {
  return {
    arrivee: createCompareTrain(TrainTypeLabel.ARRIVEE, [equilibre.arrive], compareType, modifiedField, refValue, targetValue),
    depart: createCompareTrain(TrainTypeLabel.DEPART, [equilibre.depart], compareType, modifiedField, refValue, targetValue),
    voieAQuai: equilibre.miQuai ?? '',
    equilibre,
    modifiedField,
    refValue,
    targetValue,
    otherEquilibre,
  };
}