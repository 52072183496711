import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { MultiselectItem } from './multiselect.component';

@Directive({
  selector: '[appMultiselectKeyboard]',
})
export class MultiselectKeyboardDirective implements OnDestroy {
  @Input() items: MultiselectItem[] | undefined;
  private timer = null;
  private keyRegistered = '';
  private readonly delayTimer = 1500;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key.length === 1) {
      this.handleKeyboard(event.key);
    }
  }
  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    this.clearTimer();
  }

  /* ----------------------------------------- */
  /* private methods */
  /* ----------------------------------------- */
  private handleKeyboard(key: string): void {
    if (this.el) {
      if (this.keyRegistered.length < 4) {
        this.el.nativeElement.scrollTop = 0;
        this.keyRegistered += key.toString();
        this.scrollContent();
        if (this.keyRegistered.length === 1) {
          this.timer = setTimeout(() => {
            this.keyRegistered = '';
            this.timer = null;
          }, this.delayTimer);
        }
      } else {
        this.reset();
      }
    } else {
      this.reset();
    }
  }

  /**
   * timer
   */
  private clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private reset(): void {
    this.clearTimer();
    this.keyRegistered = '';
  }

  /**
   * scrollContent
   */
  private scrollContent(): void {
    const len = this.keyRegistered.length;
    const index = this.findPosition(len);
    const elContent = this.el.nativeElement.querySelector(`[data-type="results"]`);
    if (index >= 0) {
      const li = elContent?.querySelectorAll('li')[index];
      const posY = li ? li.offsetTop : 0;
      li.querySelector('label')?.focus();
      this.el.nativeElement.scrollTop = posY;
    } else if (this.keyRegistered.length > 1) {
      this.keyRegistered = this.keyRegistered.substring(0, len - 1);
      this.scrollContent();
    } else {
      this.reset();
    }
  }

  /**
   * findPosition
   */
  private findPosition(len: number): number {
    let index = -1;
    if (this.items) {
      index = this.items.findIndex(
        (obj: MultiselectItem) => obj.label.substring(0, len).toLowerCase() === this.keyRegistered.toLowerCase(),
      );
    }

    return index;
  }
}
