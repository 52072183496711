import { Component, OnInit } from '@angular/core';

import { CookieService } from '@app/services';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent implements OnInit {
  static readonly WAS_SHOWN_COOKIE = 'wasInfoPageShown';
  static readonly EXPIRATION_DATE = '2020-11-30T23:59:59'; // expiration date for both this page and its cookie

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    // Set a cookie so that this page doesn't show up more than once.
    this.cookieService.set(PageInfoComponent.WAS_SHOWN_COOKIE, 'true', { expires: PageInfoComponent.EXPIRATION_DATE });
  }
}
