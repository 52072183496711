/**
 * @file
 * Handle all backend interactions
 * pertaining to the "plan de transport" (equilibres, conflicts...).
 */
import { Injectable } from '@angular/core';

import { of, Observable, forkJoin } from 'rxjs';
import { mergeMap, map, delay } from 'rxjs/operators';

import {
  TransportationPlanDto,
  TpLightDto,
  EquilibreDto,
  Station,
  AnalyzedConflictsDto,
  ConflictDto,
  SourcePlanTransport,
  Equilibre,
  createDtoFromEquilibre,
  EQJOIN_TYPE,
  EqJoin,
  eqJoinTypeToDto,
  EqJoinHarmoInfo,
  EqGroupHighlight,
  StatutConflictDto,
  PasseMinuitComparisonDTO,
} from '@app/models';
import { dataToFormData, toQueryString } from '@app/utils';
import { BaseService } from './base-service';
import { StationService } from './station.service';
import { ImportPdtRequest } from '@app/models/import-pdt-request';
import { TransportationPlanComparison } from '@app/models/transportation-plan-comparison';

//

@Injectable({
  providedIn: 'root',
})
export class TransportationPlanService extends BaseService {
  constructor(private stationService: StationService) {
    super();
  }

  /**
   * Load all the DTOs to instantiate GovData (except the travaux ops):
   *   - Transportation plan DTO
   *   - Conflict DTOs
   *   - EqGroup Highlight DTOs
   *   - Additional station info based on the current GOV's parametrageId.
   *
   * @param tpRid       Identifier for the transportation plan to load.
   *                    Either a YYYY-MM-DD date e.g. "2019-07-06", or "2019A1" for "journées types".
   * @param stationCode The current station code.
   */
  loadGovDataDtos(
    stationCode: string,
    tpRid: string,
  ): Observable<{ tpDto: TransportationPlanDto; conflictDtos: ConflictDto[]; eqGroupHighlights: EqGroupHighlight[] }> {
    const loadTpDtoAndStationParamsAndInfra$ = this.loadTpByRid(stationCode, tpRid).pipe(
      // Load station parametrage info and store it on the `station` object in the cache.
      mergeMap(tpDto => this.stationService.loadStationParametrage(stationCode, tpDto.parametrageId).pipe(map(() => tpDto))),
      // Load station parametrage info and store it on the `station` object in the cache.
      mergeMap(tpDto => this.stationService.loadStationInfraByParametrageId(stationCode, tpDto.parametrageId).pipe(map(() => tpDto))),
    );
    const loadConflictDtos$ = this.loadConflictDtosForCurrentTp(stationCode, tpRid);
    const loadEqGroupHighlights$ = this.loadEqGroupHighlights__TEMP(stationCode, tpRid);

    return forkJoin([loadTpDtoAndStationParamsAndInfra$, loadConflictDtos$, loadEqGroupHighlights$]).pipe(
      map(([tpDto, conflictDtos, eqGroupHighlights]) => ({ tpDto, conflictDtos, eqGroupHighlights })),
    );
  }

  //
  // ----- Equilibres -----
  //

  /**
   * Save an * EXISTING * eqGroup on the backend
   * and return the updated eqGroup along with the latest PDT modifCount.
   */
  saveEqGroup(
    eqGroup: Equilibre[],
    originalEqGroupId: string,
    opts: { stationCode: string; tpId: number; tpRid: string; forceMiQuaiDisplay: boolean },
  ): Observable<{ newEqGroupDtos: EquilibreDto[][]; modifCount: number }> {
    // Remove front-specific props before sending data to the backend
    const eqGroupDto = eqGroup.map(eq => createDtoFromEquilibre(eq));

    let obs$: Observable<any>;
    if (originalEqGroupId === 'eqGroup_') {
      obs$ = this.backend.post(`api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres`, eqGroupDto[0]);
    } else {
      obs$ = this.backend.patch(
        `api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres?forceMiQuaiDisplay=${opts.forceMiQuaiDisplay}`,
        eqGroupDto,
      );
    }

    return obs$.pipe(
      mergeMap((newEqGroupDto: EquilibreDto[]) =>
        this.loadTpModifCount(opts.stationCode, opts.tpRid).pipe(map(modifCount => ({ newEqGroupDtos: [newEqGroupDto], modifCount }))),
      ),
    );
  }

  /**
   * Load all the eqGroup highlights for the given GOV.
   *
   * @TODO: waiting for backend... returning dummy data for now...
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadEqGroupHighlights__TEMP(stationCode: string, tpRid: string): Observable<EqGroupHighlight[]> {
    // const hl1: EqGroupHighlight = { eqGroupId: 'eqGroup_4665382', color: '#2ECC71' };  // green
    // const hl2: EqGroupHighlight = { eqGroupId: 'eqGroup_4668757-4666668', color: '#E67E22' };  // orange
    // return of([hl1, hl2]);
    return of([]);
  }

  /**
   * Save an eqGroup highlight and return the updated list of eqGroupHighlights
   * for the given GOV along with the latest PDT modifCount.
   */
  saveEqGroupHighlight__TEMP(
    eqGroupHighlight: EqGroupHighlight,
    opts: { stationCode: string; tpId: number; tpRid: string },
  ): Observable<{ eqGroupHighlights: EqGroupHighlight[]; modifCount: number }> {
    // console.log(`saveEqGroupHighlight`, eqGroupHighlight);

    // @TODO: replace with actual backend request
    const saveEqGroupHighlightTEMP$ = this.loadEqGroupHighlights__TEMP(opts.stationCode, opts.tpRid).pipe(
      delay(500),
      // TEMP: append the new highlight to the list of highlights returned by the backend
      map(highlights => highlights.concat(eqGroupHighlight)),
    );

    return saveEqGroupHighlightTEMP$.pipe(
      mergeMap(eqGroupHighlights =>
        this.loadTpModifCount(opts.stationCode, opts.tpRid).pipe(map(modifCount => ({ eqGroupHighlights, modifCount }))),
      ),
    );
  }

  /**
   * Split the given equilibre by turning it into 2 "trains isolés",
   * and return the generated "trains isolé" eqGroups, along with the latest PDT modifCount.
   */
  splitEq(
    eqId: number,
    opts: { stationCode: string; tpId: number; tpRid: string },
  ): Observable<{ newEqGroupDtos: EquilibreDto[][]; modifCount: number }> {
    return this.backend.post(`api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres/${eqId}/break`, {}).pipe(
      // tap(DATA => console.log(`[TpService] splitEq() -- DATA returned from backend`, DATA)),
      mergeMap(newEqGroupDtos =>
        this.loadTpModifCount(opts.stationCode, opts.tpRid).pipe(map(modifCount => ({ newEqGroupDtos, modifCount }))),
      ),
    );
  }

  /**
   * Delete an equilibre
   * @param eqGroup The equilibre group
   * @param ignore Allows to know if we ignore and not delete
   * @param opts The opts
   * @returns observable
   */
  deleteEq(
    eqGroup: Equilibre[],
    ignore: boolean,
    opts: { stationCode: string; tpId: number; tpRid: string; groupIncluded?: boolean },
  ): Observable<{ newEqGroupDtos: EquilibreDto[][]; modifCount: number }> {
    const eqId = eqGroup[0].id;
    let url = `api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres/${eqId}`;
    if (opts.groupIncluded) {
      url += `?groupIncluded=true`;
    }
    return this.backend.delete(url).pipe(
      // tap(DATA => console.log(`[TpService] deleteEq() -- DATA returned from backend`, DATA)),
      mergeMap(() =>
        this.loadTpByRid(opts.stationCode, opts.tpRid).pipe(
          map(tp => ({
            newEqGroupDtos: ignore ? this.getEquilibresFromTp(eqGroup, tp.equilibresLists) : [],
            modifCount: tp.modificationCount,
          })),
        ),
      ),
    );
  }

  /**
   * Move the equilibres that are on a given VAQ toward to the first IGNORE VAQ.
   * Return the equlibres updated, along with the latest PDT modifCount.
   */
  ignoreEqs(
    vaqName: string,
    opts: { stationCode: string; tpId: number; tpRid: string },
  ): Observable<{ newEqGroupDtos: EquilibreDto[][]; modifCount: number }> {
    return this.backend
      .patch(`api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres/ignore?voieOrigine=${vaqName}`, {})
      .pipe(
        mergeMap(newEqGroupDtos =>
          this.loadTpModifCount(opts.stationCode, opts.tpRid).pipe(map(modifCount => ({ newEqGroupDtos, modifCount }))),
        ),
      );
  }

  /**
   * Join the two given eqs as a single eq.
   */
  joinEqs(
    eqJoin: EqJoin,
    opts: { stationCode: string; tpId: number; tpRid: string; harmoInfo: EqJoinHarmoInfo },
  ): Observable<{ newEqGroupDtos: EquilibreDto[][]; modifCount: number }> {
    const urlParams: { [k: string]: string } = {
      action: eqJoinTypeToDto(eqJoin.joinType),
      idequilibre2: `${eqJoin.eq2.id}`,
      typeMat: opts.harmoInfo.typeMateriel.commonValue,
      nbElem: opts.harmoInfo.materielCount.commonValue,
      vaq: opts.harmoInfo.vaqName.commonValue,
    };

    if (opts.harmoInfo.miQuai.commonValue) {
      // miQuai is not mandatory
      urlParams['miQuai'] = opts.harmoInfo.miQuai.commonValue;
    }

    const url = `api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres/${eqJoin.eq1.id}/form?${toQueryString(urlParams)}`;
    const body = {};

    return this.backend.post(url, body).pipe(
      // tap(DATA => console.log(`[TpService] joinEqs() -- DATA returned from backend`, DATA)),
      mergeMap((newEqGroupDto: EquilibreDto[]) =>
        this.loadTpModifCount(opts.stationCode, opts.tpRid).pipe(map(modifCount => ({ newEqGroupDtos: [newEqGroupDto], modifCount }))),
      ),
    );
  }

  /**
   * Return the ids of all eqs that are compatible to be joined with the given eq.
   *
   * Note. The backend actually returns EquilibreDto's but we map them to simple eqIds
   * that we will extract from govData later on. This is to avoid having to rehydrate the eqs.
   *
   * @param eqId Equilibre that could be either a train isolé or a full eq.
   * @param joinType The type of eqJoin the user is trying to form : EQUILIBRE | COUPE | ACCROCHE
   */
  getCompatibleEqIdsForEqJoin(eqId: number, joinType: EQJOIN_TYPE, opts: { stationCode: string; tpId: number }): Observable<number[]> {
    // console.log(`[TpService] getCompatibleEqIdsForEqJoin(${eqId}) -- joinType = ${joinType}`);

    const joinTypeDto = eqJoinTypeToDto(joinType);
    const url = `api/gares/${opts.stationCode}/plandetransports/${opts.tpId}/equilibres/${eqId}/eligibility/form?action=${joinTypeDto}`;
    const body = {};

    return this.backend
      .post(url, body)
      .pipe
      // tap((DATA: number[]) => console.log(`[TpService] getCompatibleEqIdsForEqJoin() -- DATA returned from backend`, DATA)),
      ();
  }

  //
  // ----- TransportationPlan -----
  //

  /**
   * Load the TP for the given station and RID (date YYYY-MM-DD).
   */
  loadTpByRid(stationCode: string, tpRid: string): Observable<TransportationPlanDto> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/rid/${tpRid}/latest`);
  }

  /**
   * Load the modification count for the TP for the given station and RID (date YYYY-MM-DD).
   */
  loadTpModifCount(stationCode: string, tpRid: string): Observable<number> {
    return this.loadTpByRid(stationCode, tpRid).pipe(map(tp => tp.modificationCount));
  }

  /**
   * Return the list of transportation plans for the given date range.
   *
   * @param stationCode Station code
   * @param startDate   Start date in the "YYYY-MM-DD" format.
   * @param endDate     End date in the "YYYY-MM-DD" format.
   */
  loadTransportationPlansForDateRange(stationCode: string, startDate: string, endDate: string): Observable<TpLightDto[]> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports?debut=${startDate}&fin=${endDate}`).pipe(
      // tap(DATA => console.log(`loadTransportationPlansForDateRange`, DATA)),
      map(plans => (plans === null ? [] : plans)),
    );
  }

  confirmTpChanges(tpId: number, stationCode: string): Observable<TransportationPlanDto> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpId}/publish`, {});
  }

  cancelTpChanges(tpId: number, stationCode: string): Observable<TransportationPlanDto> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpId}/reset`, {});
  }

  /**
   * Re-expose the `getStation()` method to avoid injecting 2 services in the consumer.
   */
  getStationFromCache(stationCode: string): Station | undefined {
    return this.cache.getStation(stationCode);
  }

  //
  // ----- Conflicts -----
  //

  /**
   * Load conflict DTOs for the * VALIDATED * (CURRENT ??) transportation plan.
   */
  loadConflictDtosForCurrentTp(stationCode: string, tpRid: string): Observable<ConflictDto[]> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/rid/${tpRid}/conflits`);
  }

  /**
   * Analyse les conflits créés et résolus en comparant le PDT courant et le PDT validé.
   */
  analyzeConflicts(tpId: number, stationCode: string): Observable<AnalyzedConflictsDto> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/compare/VALIDATED...${tpId}/conflits`);
  }

  /**
   *
   * @param stationCode The station code
   * @param tpRid The tp rid
   * @param statutConflictDto The statut conflict dto
   * @returns an observable with the statut conflict updated
   */
  updateStatutConflict(stationCode: string, tpRid: string, statutConflictDto: StatutConflictDto): Observable<StatutConflictDto> {
    return this.backend.patch(`api/gares/${stationCode}/plandetransports/rid/${tpRid}/conflits/${statutConflictDto.id}`, statutConflictDto);
  }

  //
  // ----- Import -----
  //

  importGOV(stationCode: string, importPdtRequest: ImportPdtRequest): Observable<any> {
    let formData = new FormData();

    if (
      importPdtRequest.dataSource === SourcePlanTransport[SourcePlanTransport.HOUAT_API] ||
      importPdtRequest.dataSource === SourcePlanTransport[SourcePlanTransport.HOUAT_ONLY_API]
    ) {
      delete importPdtRequest.file;
    }

    formData = dataToFormData(importPdtRequest);

    return this.backend.post(`api/gares/${stationCode}/plandetransports/import`, formData);
  }

  //
  // ----- Import -----
  //

  /**
   * Si on a reçu un conflit donné, on lance une résolution seulement sur ce conflit,
   * sinon on lance une optimisation globale.
   */
  optimizeTp(stationCode: string, tpId: number, conflict?: ConflictDto): Observable<TransportationPlanDto> {
    const formdata = new FormData();
    let optimGlobale = 'true';

    if (conflict) {
      optimGlobale = 'false';
      formdata.append('conflit_id', conflict.identifier.toString());
    }

    formdata.append('optim_globale', optimGlobale);

    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpId}/optimise`, formdata);
  }

  //
  // ----- Synchronize -----
  //

  importSynchro(stationCode: string, date: string): Observable<number> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/import-synchro/${date}`, {});
  }

  compareWithSynchroPdt(stationCode: string, rid: string): Observable<TransportationPlanComparison> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/compare/${rid}/synchro`);
  }

  getMergedPdtWithSynchro(stationCode: string, rid: string): Observable<TransportationPlanDto> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/compare/${rid}/synchro-merged`);
  }

  getMergedPdtWithSynchroPasseMinuit(stationCode: string, rid: string, forceSynchro = false): Observable<TransportationPlanDto> {
    return this.backend.get(
      `api/gares/${stationCode}/plandetransports/compare/${rid}/synchro-passe-minuit-merged?forceSynchro=${forceSynchro}`,
    );
  }

  importAndSynchroPdt(
    stationCode: string,
    date: string,
    rid: string,
  ): Observable<{ tpComparison: TransportationPlanComparison; tpDto: TransportationPlanDto }> {
    const importSynchro$ = this.importSynchro(stationCode, date);
    const synchroPdt$ = this.synchroPdt(stationCode, rid);

    return importSynchro$.pipe(mergeMap(() => synchroPdt$));
  }

  synchroPdt(stationCode: string, rid: string): Observable<{ tpComparison: TransportationPlanComparison; tpDto: TransportationPlanDto }> {
    const compareWithSynchroPdt$ = this.compareWithSynchroPdt(stationCode, rid);
    const getMergedPdtWithSynchro$ = this.getMergedPdtWithSynchro(stationCode, rid);

    return forkJoin([compareWithSynchroPdt$, getMergedPdtWithSynchro$]).pipe(map(([tpComparison, tpDto]) => ({ tpComparison, tpDto })));
  }

  synchroPasseMinuit(stationCode: string, rid: string, forceSynchro = false): Observable<PasseMinuitComparisonDTO> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/compare/${rid}/synchro-passe-minuit?forceSynchro=${forceSynchro}`);
  }

  synchroPasseMinuitAndLoadMergedPdt(
    stationCode: string,
    rid: string,
    forceSynchro = false,
  ): Observable<{ passeMinuitComparison: PasseMinuitComparisonDTO; tpDto: TransportationPlanDto }> {
    const synchroPasseMinuit$ = this.synchroPasseMinuit(stationCode, rid, forceSynchro);
    const getMergedPdtWithSynchroPasseMinuit$ = this.getMergedPdtWithSynchroPasseMinuit(stationCode, rid, forceSynchro);

    return forkJoin([synchroPasseMinuit$, getMergedPdtWithSynchroPasseMinuit$]).pipe(
      map(([passeMinuitComparison, tpDto]) => ({ passeMinuitComparison, tpDto })),
    );
  }

  /**
   * Apply synchronize HOUAT
   * @param stationCode The station code
   * @param rid The rid
   * @param tpComparison The tp comparison
   * @returns message
   */
  applySynchroHouat(stationCode: string, rid: string, tpComparison: TransportationPlanComparison): Observable<string> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/compare/${rid}/synchro-apply`, tpComparison);
  }

  /**
   * Apply synchronize passe-minuit
   * @param stationCode The station code
   * @param rid The rid
   * @param passeMinuitComparison The passe minuit comparison
   * @returns message
   */
  applySynchroPasseMinuit(
    stationCode: string,
    rid: string,
    passeMinuitComparison: PasseMinuitComparisonDTO,
    forceSynchro = false,
  ): Observable<string> {
    return this.backend.post(
      `api/gares/${stationCode}/plandetransports/compare/${rid}/synchro-passe-minuit-apply?forceSynchro=${forceSynchro}`,
      passeMinuitComparison,
    );
  }

  /**
   * Get the ignored equilibres from the TP
   * @param eqGroup The equilibre group
   * @param equilibresLists The list of equilibres
   * @returns ignored equilibres
   */
  private getEquilibresFromTp(eqGroup: Equilibre[], equilibresLists: EquilibreDto[][]): EquilibreDto[][] {
    for (const equilibre of equilibresLists) {
      for (const e of equilibre) {
        if (eqGroup.find(eq => eq.id === e.id)) {
          return [equilibre];
        }
      }
    }
    return [];
  }

  /**
   * Optimizes the allocation of miquais for Day+1 groups
   * @param stationCode The station code
   * @param tpId the id of the transportattion plan
   * @returns TP for the given station and tpId
   */
  optimMiQuaisPasseMinuit(stationCode: string, tpId: number): Observable<TransportationPlanDto> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpId}/optimise-miquais-j-plus-1`, {});
  }
}
