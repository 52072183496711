import { NgModule } from '@angular/core';

import { DialogModule } from 'primeng/dialog';
import { SharedModule } from '../shared.module';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogComponent } from './dialog.component';

@NgModule({
  imports: [SharedModule, DialogModule, CheckboxModule],
  declarations: [DialogComponent],
  exports: [DialogComponent],
})
export class CustomDialogModule {}
