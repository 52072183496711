import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Station, TypeModelisation } from '@app/models';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-travaux-menu',
  templateUrl: './travaux-menu.component.html',
  styleUrls: ['./travaux-menu.component.scss'],
})
export class TravauxMenuComponent implements OnInit {
  @Input() station: Station;

  menuItems: MenuItem[];

  /** Allows to know if we have in conception module */
  isConception: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.menuItems = [
      { label: `Voies en gare`, command: () => this.gotoTravauxVEGList() }, // can't use a routerLink, cause the link is dynamic
    ];

    switch (this.station.typeModelisation) {
      case TypeModelisation.MACRO:
        this.menuItems.push({ label: `Indisponibilités d'itinéraires`, command: () => this.gotoTravauxIndisposItinerairesList() });
        break;
      case TypeModelisation.MICRO:
        // this.menuItems.push({ label: `ZEP/SEL`, routerLink: ['/', this.station.code, 'travaux', 'zep-sel'], disabled: true });
        break;
      case TypeModelisation.NONE:
        console.error(`The type of modelisation ${this.station.typeModelisation} does not exist`);
    }

    this.isConception = this.router.url.includes('conception');
  }

  // Go to the travaux veg list ** FOR THE ACTIVE MONTH AND YEAR **.
  private gotoTravauxVEGList() {
    const module = this.router.url.includes('conception') ? 'conception' : 'adaptation';
    const when = this.route.snapshot.queryParamMap.get('when');
    const queryParams = when ? { when } : null;
    this.router.navigate(['/', this.station.code, module, 'travaux', 'veg'], { queryParams });
  }

  /**
   * Go to the "indisponibilités d'itinéraires" list ** FOR THE ACTIVE MONTH AND YEAR **.
   */
  private gotoTravauxIndisposItinerairesList() {
    const module = this.router.url.includes('conception') ? 'conception' : 'adaptation';
    const when = this.route.snapshot.queryParamMap.get('when');
    const queryParams = when ? { when } : null;
    this.router.navigate(['/', this.station.code, module, 'travaux', 'indispos-itineraires'], { queryParams });
  }
}
