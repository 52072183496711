/**
 * @file
 * Colors to highlight (the train numbers of) an equilibre.
 */

/**
 * Interface describing a specific action.
 */
export interface EqHighlightColor {
  id: string;
  label: string;
  hexColor: string; // hexa color code, e.g. #2ECC71
}

/**
 * List of all actions that may be triggered from an eq.
 */
export const ALL_EQ_HIGHLIGHT_COLORS: EqHighlightColor[] = [
  { id: 'no-color', label: 'Pas de couleur', hexColor: '' },
  { id: 'yellow', label: 'Jaune', hexColor: '#ffe52d' },
  { id: 'orange', label: 'Orange', hexColor: '#ffbd4a' },
  { id: 'red', label: 'Rouge', hexColor: '#ff7171' },
  { id: 'blue', label: 'Bleu', hexColor: '#84ccf1' },
  { id: 'green', label: 'Vert', hexColor: '#55d58f' },
  { id: 'gray', label: 'Gris', hexColor: '#9b9b9b' },
];
