import { BackendService } from '@app/backend';
import { AppCacheService } from './app-cache.service';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

export abstract class BaseService {
  protected http = inject(HttpClient);
  protected backend = inject(BackendService);
  protected cache = inject(AppCacheService);
}
