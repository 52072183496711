/**
 * @file
 * Interfaces for misc UI elements.
 */
import { Params } from '@angular/router';

import * as moment from 'moment';

export interface Size {
  width: number;
  height: number;
}

export interface Margins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const MARGINS_DEFAULT: Margins = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export interface HourRange {
  hourStart: number;
  hourEnd: number;
}

export interface VisibleHours {
  firstHour: number;
  numHours: number;
}

export interface VisibleHours2 {
  firstHour: moment.Moment;
  numHours: number;
}

export interface VisibleVaqs {
  firstVaqPosition: number;
  numVaqs: number;
}

export enum ZoomAction {
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',
  ALL = 'ALL',
  NONE = 'NONE', // used when highlighting an eqGroup or conflict, when the zoom factor shouldn't change
}

// Determines which info will be used to create the "origine" and "destination" labels
// on the GOV. This preference is station-specific.
export enum TypeLabelOrigDest {
  ORIG_DEST = 'ORIG_DEST', // Use either the ORIGINE or DESTINATION station
  VEL = 'VEL', // VOIE_EN_LIGNE
  VAG3_SUD = 'VAG3_SUD', // VOIE_AVANT_GARE_3_SUD
  LIBDIR = 'LIBDIR', // LIBDIR
}

export enum TypeLabelOrigDestLabel {
  ORIG_DEST = 'Origine / Destination',
  VEL = 'Voie en ligne',
  VAG3_SUD = 'Voie avant gare de niveau 3',
  LIBDIR = 'Libellé directionnel',
}

export enum CardinalDirection {
  NORD = 'NORD',
  SUD = 'SUD',
  EST = 'EST',
  OUEST = 'OUEST',
}

export enum SortDirection {
  NONE = 'NONE',
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface BreadcrumbFragment {
  label: string;
  path?: string[];
  tooltip?: string;
  iconPath?: string;
  queryParams?: Params;
}
