<!-- ===== HEADERS ===== -->
<div class="indispo-iti header flex row">
  <div *ngFor="let col of columns" class="vaq">{{col.name}}</div>
</div>
<!-- ===== END HEADERS ===== -->

<ng-container *ngFor="let colData of data">
  <div class="indispo-iti flex row">
    <div *ngFor="let col of columns" class="vaq">
      <ng-container *ngIf="col.format ; else other"> {{col.dataProperty}} {{ col.format(colData[col.dataProperty]) }} </ng-container>
      <ng-template #other> {{ colData[col.dataProperty] }} </ng-template>
    </div>
  </div>
</ng-container>
