/**
 * Used in train component to display train information
 */
export interface TrainDisplay {
  /** ARRIVEE / DEPART / PASSAGE */
  trainType: string;
  numero: string;
  numeroColor: string;
  origDest: string;
  dateHeure: string;

  // For compare train
  origDestTarget?: string;
  origDestColor?: string;
  dateHeureTarget?: string;

  // For compare train, others values than origDest and dateHeure
  modifiedFieldLabel?: string;
  refValue?: string;
  targetValue?: string;
}
