/**
 * @file
 * Abstraction layer to handle writing and reading cookies in the browser.
 *
 * Since cookies are text-only, this service will (de)serialize the data
 * to and from JSON so that non-text values are supported.
 */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Set a cookie.
   *
   * @param key The cookie identifier.
   * @param value The cookie value. Doesn't have to be a string.
   * @param opts.expires Optional. Datetime in the format 1995-12-17T03:24:00.
   *                     No expiration is set if this param is undefined.
   */
  set(key: string, value: any, opts: { expires?: string } = {}) {
    const cookieStr = `${key}=${JSON.stringify(value)}` + (opts.expires ? `;expires=${new Date(opts.expires)}` : '');
    this.document.cookie = cookieStr;
  }

  /**
   * Return the cookie value stored under `key`.
   */
  get(key: string): any {
    const cookie = this.document.cookie.split('; ').find(row => row.startsWith(key));
    if (cookie && cookie.split('=')[1] !== 'undefined') {
      return JSON.parse(cookie.split('=')[1]);
    }
  }
}
