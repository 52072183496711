declare const require;

export const environment = {
  production: false,
  envName: 'vm',
  VERSION: require('../../package.json').version,
  backendURI: '{OG_API_URI}',
  basic_key: 'crmClient1',
  basic_pass: 'crmSuperSecret',
};
