/**
 * @file
 * Utility functions related to equilibres and eqGroups.
 */
import * as _ from 'lodash';

import {
  EquilibreDto,
  Train,
  ColorByType,
  TypeMaterielColor,
  GovColorMode,
  TypeCirculationColor,
  VoieEnLigneColor,
  TypeCategorieColor,
} from './transportation-plan';
import { Equilibre } from './equilibre';
import { EqGroupHighlight } from './eqgroup-highlight';
import { TransportationPlanComparison } from './transportation-plan-comparison';
import { CompareTrainCategory, CompareTrainCategoryColor } from './compare-train-category';
import { PasseMinuitComparisonDTO } from './passe-minuit-comparison';

/**
 * =========================================
 * ============ eqGroup Helpers ============
 * =========================================
 */

/**
 * Compute a unique id for the given eqGroup.
 */
export function eqGroupGetId(eqGroup: EquilibreDto[]) {
  return 'eqGroup_' + eqGroup.map(eq => eq.id).join('-');
}

/**
 * Insert the given eq in the given eqGroup.
 */
export function eqGroupUpdateEq(eqGroup: Equilibre[], newEq: Equilibre): Equilibre[] {
  return eqGroup.map(e => (e.id === newEq.id ? newEq : e));
}

/**
 * Update the "voie à quai" info in all eqs of the given eqGroup,
 * but ONLY IF IT HAS CHANGED.
 */
export function eqGroupUpdateVaq(eqGroup: Equilibre[], vaqName: string): Equilibre[] {
  return eqGroup.map(eq => {
    // Safeguard: only proceed if VAQ has actually changed
    const eqVaqName = eq.arrive ? eq.arrive.voieFin : eq.depart.voieDebut;
    if (eqVaqName === vaqName) {
      return eq;
    }

    if (eq.arrive) {
      eq.arrive.voieFin = vaqName;
      // invalidate itineraires and mark them as "non fixed"
      eq.arrive.itineraireRid = undefined;
      eq.arrive.isItineraireFixed = false;
    }
    if (eq.depart) {
      eq.depart.voieDebut = vaqName;
      eq.depart.itineraireRid = undefined;
      eq.depart.isItineraireFixed = false;
    }

    return eq; // USELESS - The original eq was modified by reference!
  });
}

/**
 * Update the miQuaiDisplay property for all eqs of the group.
 * @param eqGroup The eqGroup
 * @param miQuaiDisplay The miQuaiDisplay
 */
export function eqGroupUpdateMiQuaiDisplay(eqGroup: Equilibre[], miQuaiDisplay: string) {
  eqGroup.forEach(eq => {
    if (eq.miQuaiDisplay === miQuaiDisplay) {
      return eq;
    }
    eq.miQuaiDisplay = miQuaiDisplay;
    if (eq.arrive) {
      eq.arrive.isItineraireFixed = false;
    }
    if (eq.depart) {
      eq.depart.isItineraireFixed = false;
    }
    return eq;
  });
}

/**
 * Take the trains in a given "source" eq and update them
 * everywhere they appear in the given eqGroup.
 */
export function eqGroupSyncTrainsFromEq(sourceEqDto: EquilibreDto, eqGroup: Equilibre[]) {
  if (sourceEqDto.arrive) {
    eqGroup = eqGroup.map(eq => (eq.id !== sourceEqDto.id ? eqOverwriteTrain(eq, sourceEqDto.arrive) : eq));
  }
  if (sourceEqDto.depart) {
    eqGroup = eqGroup.map(eq => (eq.id !== sourceEqDto.id ? eqOverwriteTrain(eq, sourceEqDto.depart) : eq));
  }

  return eqGroup;
}

/**
 * Overwrite any trains matching `train.id` in `eq`.
 */
function eqOverwriteTrain(eq: Equilibre, train: Train): Equilibre {
  const updEq: Equilibre = { ...eq };
  if (updEq.arrive && updEq.arrive.id === train.id) {
    updEq.arrive = train;
  }
  if (updEq.depart && updEq.depart.id === train.id) {
    updEq.depart = train;
  }

  return updEq;
}

/**
 * Merge the two given "train isolé" eqGroups into a single eqGroup.
 *
 * This is used when splitting an eq into trains isolés.
 * The returned, fake eqGroup is used to draw the "split" line(s) between the trains isolés.
 *
 * @param eqGroup1 An eqGroup containing only "arrive" trains.
 * @param eqGroup2 An eqGroup containing only "depart" trains.
 */
export function mergeTrainIsoleEqGroups(eqGroup1: Equilibre[], eqGroup2: Equilibre[]): EquilibreDto[] {
  const arriveTrains: Train[] = sortEqGroupByTypeMat__TEMP(eqGroup1).map(eq => eq.arrive);
  const departTrains: Train[] = sortEqGroupByTypeMat__TEMP(eqGroup2).map(eq => eq.depart);

  // Use eqGroup1 as the starting point, but include both arrive and depart trains.
  return eqGroup1.map((eq, index) => {
    const eqDto: EquilibreDto = {
      id: eq.id,
      typeMateriel: eq.typeMateriel,
      materielCount: eq.materielCount,
      position: eq.position,
      miQuai: eq.miQuai,
      arrive: arriveTrains[index],
      depart: departTrains[index],
    };
    return eqDto;
  });
}

/**
 * Sort the eqs in the given eqGroup by the `eq.typeMateriel`prop.
 *
 * TEMPORARY (20-NOV-2020)
 * This is a temporary function because the backend doesn't return
 * the eqGroups in the proper order after an eqSplit.
 */
function sortEqGroupByTypeMat__TEMP(eqGroup: Equilibre[]) {
  return _.sortBy(eqGroup, ['typeMateriel']);
}

/**
 * ===========================================
 * ============ Equilibre Helpers ============
 * ===========================================
 */

// @TODO: Delete (2-DEC-2020)
/**
 * Return the values for the given prop in BOTH `eq.arrive` AND `eq.depart`.
 *
 * This function is to support "trains isolés", i.e. eqs with a missing .arrive or missing .depart.
 */
// export function eqGetTrainProps(eq: EquilibreDto, propName: string): any[] {
//   return [
//       _.get(eq, `arrive.${propName}`),
//       _.get(eq, `depart.${propName}`),
//     ]
//     .filter(Boolean)  // remove falsy values
//     ;
// }

/**
 * Return the value for the given prop in the requested train,
 * either `eq.arrive` OR `eq.depart`.
 */
export function eqGetTrainProp(eq: EquilibreDto, propName: string, forArriveTrain?: boolean) {
  // A specific train was requested: arrive or depart.
  if (typeof forArriveTrain === 'boolean') {
    const arriveOrDepart = forArriveTrain ? 'arrive' : 'depart';
    return _.get(eq, `${arriveOrDepart}.${propName}`);
  }
  // If no specific train was requested, return what could be found (either arrive or depart).
  return eq.arrive ? eq.arrive[propName] : eq.depart[propName];
}

/**
 * Return the correct color code for the given equilibre and color mode.
 *
 * - For color mode TYPE_MATERIEL:
 *   The color is based on eq.typeMateriel.
 *
 * - For color mode TYPE_CIRCULATION:
 *   The color is based on eq.arrive.typeCirculation or eq.depart.typeCirculation.
 *   @TODO: Les métiers doivent décider quel typeCirculation utiliser (arrive ou depart)
 *   quand les deux trains sont présents dans l'équilibre.
 *
 * - For color mode VOIE_EN_LIGNE:
 *   The color is based on eq.arrive.voieDebut or eq.arrive.voieFin.
 *   This mode is used for train numbers, so `forArriveTrain` must be defined.
 *
 * @see https://opengov-project.atlassian.net/wiki/spaces/OP/pages/1271889929
 */
export function eqFindColorByType(eq: EquilibreDto, colorMapping: ColorByType[], colorMode?: GovColorMode, forArrive?: boolean): string {
  // force using the "arrive" train when no pref is defined
  forArrive = forArrive !== undefined ? forArrive : true;

  if (!colorMode) {
    colorMode = getColorMode(colorMapping);
  }

  // Extract the value of the property that will determine the equilibre's color.
  let propUsedAsType: string;
  switch (colorMode) {
    case GovColorMode.TYPE_MATERIEL:
      propUsedAsType = eq.typeMateriel;
      break;
    case GovColorMode.TYPE_CIRCULATION:
      propUsedAsType = forArrive ? eq.arrive?.typeCirculation : eq.depart?.typeCirculation;
      break;
    case GovColorMode.VOIE_EN_LIGNE:
      // This mode is used for train numbers, so `forArrive` must be defined.
      if (forArrive === undefined) {
        throw new Error(
          `Cannot use color mode ${GovColorMode.VOIE_EN_LIGNE} without specifying to which train is applies ("arrive" or "depart") [param "forArriveTrain" is undefined]`,
        );
      }
      propUsedAsType = forArrive ? eq.arrive && eq.arrive.voieDebut : eq.depart && eq.depart.voieFin;
      break;
    case GovColorMode.CATEGORIE:
      propUsedAsType = forArrive ? eq.arrive?.metadonnees?.CATEGORIE : eq.depart?.metadonnees?.CATEGORIE;
      break;
    case GovColorMode.NO_COLOR:
    default:
      // do nothing
      break;
  }

  const colorMap = colorMapping.find(mapping => mapping.type === propUsedAsType);
  return colorMap ? colorMap.couleurGov : '#000000';
}

/**
 * Find the color of the eq if one of the trains are in the comparison.
 * If it's the case we choose the more important color in this order (deleted, added, changed).
 * If any of its trains is in the comparison, we set to default color black.
 * @param eq The eq
 * @param tpComparison The tp comparison
 * @param passeMinuitComparison the passe minuit comparison
 * @returns The color for the eq
 */
export function eqFindColorByComparison(
  eq: EquilibreDto,
  tpComparison: TransportationPlanComparison,
  passeMinuitComparison: PasseMinuitComparisonDTO,
): string {
  let arriveeComparison: CompareTrainCategory;
  let departComparison: CompareTrainCategory;

  // TODO 22/08/2023 Do we simplify with list typed 'any' ?
  if (eq.arrive) {
    if (
      (tpComparison && tpComparison.addedTrains.find(train => eq.arrive.id === train.id)) ||
      (passeMinuitComparison && passeMinuitComparison.addedGroups.find(added => added.find(e => eq.id === e.id)))
    ) {
      arriveeComparison = CompareTrainCategory.TRAINS_ADDED;
    } else if (
      (tpComparison && tpComparison.deletedTrains.find(train => eq.arrive.id === train.id)) ||
      (passeMinuitComparison && passeMinuitComparison.deletedGroups.find(deleted => deleted.find(e => eq.id === e.id)))
    ) {
      arriveeComparison = CompareTrainCategory.TRAINS_DELETED;
    } else if (
      (tpComparison && tpComparison.modifiedTrains.find(train => eq.arrive.id === train.trainId)) ||
      (passeMinuitComparison && passeMinuitComparison.modifiedTrains.find(train => eq.arrive.id === train.trainId))
    ) {
      arriveeComparison = CompareTrainCategory.TRAINS_MODIFIED;
    } else if (
      passeMinuitComparison &&
      passeMinuitComparison.modifiedMrs.find(mr => eq.arrive.id === (mr.refMaterielRoulant.arrive as Train).id)
    ) {
      arriveeComparison = CompareTrainCategory.EQS_MODIFIED;
    } else if (
      passeMinuitComparison &&
      passeMinuitComparison.modifiedGroups.find(mg => mg.refGroup.find(refEq => eq.arrive?.id === refEq.arrive?.id))
    ) {
      arriveeComparison = CompareTrainCategory.ASSEMBLAGES_MODIFIED;
    }
  }

  if (eq.depart) {
    if (
      (tpComparison && tpComparison.addedTrains.find(train => eq.depart.id === train.id)) ||
      (passeMinuitComparison && passeMinuitComparison.addedGroups.find(added => added.find(e => eq.id === e.id)))
    ) {
      departComparison = CompareTrainCategory.TRAINS_ADDED;
    } else if (
      (tpComparison && tpComparison.deletedTrains.find(train => eq.depart.id === train.id)) ||
      (passeMinuitComparison && passeMinuitComparison.deletedGroups.find(deleted => deleted.find(e => eq.id === e.id)))
    ) {
      departComparison = CompareTrainCategory.TRAINS_DELETED;
    } else if (
      (tpComparison && tpComparison.modifiedTrains.find(train => eq.depart.id === train.trainId)) ||
      (passeMinuitComparison && passeMinuitComparison.modifiedTrains.find(train => eq.depart.id === train.trainId))
    ) {
      departComparison = CompareTrainCategory.TRAINS_MODIFIED;
    } else if (
      passeMinuitComparison &&
      passeMinuitComparison.modifiedMrs.find(mr => eq.depart.id === (mr.refMaterielRoulant.depart as Train).id)
    ) {
      departComparison = CompareTrainCategory.EQS_MODIFIED;
    } else if (
      passeMinuitComparison &&
      passeMinuitComparison.modifiedGroups.find(mg => mg.refGroup.find(refEq => eq.depart?.id === refEq.depart?.id))
    ) {
      arriveeComparison = CompareTrainCategory.ASSEMBLAGES_MODIFIED;
    }
  }

  if (arriveeComparison === CompareTrainCategory.TRAINS_DELETED || departComparison === CompareTrainCategory.TRAINS_DELETED) {
    return CompareTrainCategoryColor.TRAINS_DELETED;
  } else if (arriveeComparison === CompareTrainCategory.TRAINS_ADDED || departComparison === CompareTrainCategory.TRAINS_ADDED) {
    return CompareTrainCategoryColor.TRAINS_ADDED;
  } else if (arriveeComparison === CompareTrainCategory.TRAINS_MODIFIED || departComparison === CompareTrainCategory.TRAINS_MODIFIED) {
    return CompareTrainCategoryColor.TRAINS_MODIFIED;
  } else if (arriveeComparison === CompareTrainCategory.EQS_MODIFIED || departComparison === CompareTrainCategory.EQS_MODIFIED) {
    return CompareTrainCategoryColor.EQS_MODIFIED;
  } else if (
    arriveeComparison === CompareTrainCategory.ASSEMBLAGES_MODIFIED ||
    departComparison === CompareTrainCategory.ASSEMBLAGES_MODIFIED
  ) {
    return CompareTrainCategoryColor.ASSEMBLAGES_MODIFIED;
  } else {
    return '#000000';
  }
}

/**
 * Return the color mode found in the given color mapping.
 */
function getColorMode(colorMapping: ColorByType[]): GovColorMode {
  if (colorMapping[0] instanceof TypeCirculationColor) {
    return GovColorMode.TYPE_CIRCULATION;
  } else if (colorMapping[0] instanceof TypeMaterielColor) {
    return GovColorMode.TYPE_MATERIEL;
  } else if (colorMapping[0] instanceof VoieEnLigneColor) {
    return GovColorMode.VOIE_EN_LIGNE;
  } else if (colorMapping[0] instanceof TypeCategorieColor) {
    return GovColorMode.CATEGORIE;
  } else {
    return GovColorMode.NO_COLOR;
  }
}

/**
 * Return the color to use as a background color for the train numbers,
 * aka "highlight color".
 */
export function eqFindHighlightColor(eqGroupId: string, eqGroupHighlights: EqGroupHighlight[]) {
  const eqGHighlight = eqGroupHighlights.find(hl => hl.eqGroupId === eqGroupId);
  return eqGHighlight ? eqGHighlight.color : '';
}

/**
 * Return true if the given eq contains the given train number.
 *
 * This is used when doing a client-side search.
 */
export function eqContainsTrainNumber(eq: Equilibre | EquilibreDto, trainNumber: string) {
  return (
    (eq.arrive && eq.arrive.numero && eq.arrive.numero.toLowerCase().indexOf(trainNumber.toLowerCase()) !== -1) ||
    (eq.depart && eq.depart.numero && eq.depart.numero.toLowerCase().indexOf(trainNumber.toLowerCase()) !== -1)
  );
}
