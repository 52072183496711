import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../admin.service';
import { environment } from 'src/environments/environment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BackendServiceError } from '@app/backend';

declare let require;
const GIT = require('../../../../assets/git.version.json');

@Component({
  selector: 'app-admin-info',
  templateUrl: './admin-info.component.html',
  styleUrls: ['./admin-info.component.scss'],
})
export class AdminInfoComponent implements OnInit {
  info: any;
  frontEndVersion: string;
  frontEndHash: string;
  backendURI: string;
  backendErrorMsg: string;

  constructor(
    private adminService: AdminService,
    private dialogRef: DynamicDialogRef,
  ) {}

  ngOnInit() {
    this.backendURI = environment.backendURI;
    this.frontEndVersion = environment.VERSION;
    this.frontEndHash = GIT.hash;

    this.adminService.getInfo().subscribe({
      next: (info: any) => (this.info = info),
      error: (err: BackendServiceError) => this.handleBackendError(err),
    });
  }

  /** Close dialog when btn is clicked */
  closeDialogInfo() {
    this.dialogRef.close();
  }

  /**
   *  Handling backend error
   * @param err Backend Error
   */
  private handleBackendError(err: BackendServiceError) {
    console.log('error', err);
    this.backendErrorMsg = err.originalError.error;
  }
}
