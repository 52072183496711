<div class="calendar-wrapper" [class.no-border]="!showTitle && !showActions">
  <div class="calendar-header">
    <ng-content *ngIf="showTitle" select="[calendarTitle]"></ng-content>
    <div *ngIf="showTitle && showActions" class="vertical-separation"></div>
    <div *ngIf="showActions" class="calendar-actions">
      <div class="calendar-actions-checkbox">
        <label class="title">Journée</label>
        <ul class="list">
          <li *ngFor="let dayOfWeek of weekDays; let i = index">
            <input type="checkbox" class="day-checkbox pointer" [id]="weekDays[i]" (click)="onToggleGroupDays($event, weekDays[i])" />
            <label class="label pointer" [for]="weekDays[i]">{{ weekDays[i] }}</label>
          </li>
        </ul>
      </div>
      <button type="button" *ngIf="showResetCalendar" class="reset-calendar" (click)="onResetCalendar()">Réinitialiser</button>
    </div>
  </div>
  <div *ngIf="showTitle || showActions" class="horizontal-separator"></div>
  <div>
    <table class="calendar">
      <thead>
        <th class="month"></th>
        <th *ngFor="let n of NUMBER_OF_DAYS" class="day-d">{{ n }}</th>
      </thead>
      <tbody>
        <tr *ngFor="let date of mapDates | keyvalue : returnZero ">
          <td class="month">{{ (date.key | titlecase) }}</td>
          <td
            *ngFor="let d of date.value"
            class="day-dd"
            [ngClass]="{
                        'disabled-day' : d.isDisabled,
                        'public-holiday' : d.isPublicHoliday,
                        'selected': d.isSelected,
                        'no-cursor': disableDatesSelection
                    }"
            (click)="onSelectDate(d)"
          >
            {{ d.date.format('dd') | titlecase }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
