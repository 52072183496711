/**
 * @file
 * All file types that can be exported from a GOV.
 */

export enum EXPORT_TYPES {
  GROIX = 'GROIX',
  PDF_DIALOG = 'PDF_DIALOG',
  PDF_PAGE = 'PDF_PAGE',
  OPENGOV = 'OPENGOV',
  CONFLICTS = 'CONFLICTS',
  TST = 'TST',
  ROULEMENTS = 'ROULEMENTS',
  CORRESPONDANCES = 'CORRESPONDANCES',
  BASE_GARE = 'BASE_GARE',
}
