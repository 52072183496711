/**
 * @file
 * An eqGroup highlight is when a color is associated
 * to an equilibre to highlight the train numbers of
 * this equilibre on the GOV.
 */
export interface EqGroupHighlight {
  eqGroupId: string;
  color: string;
}
