/**
 * @file
 * Allow page "activation" only if the `:station` URL param exists
 * and it can be matched with a station in the local cache (localStorage).
 */
import { inject } from '@angular/core';
import { AppCacheService } from './app-cache.service';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

export const stationGuard: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  const stationCode = next.paramMap.get('station');
  const station = inject(AppCacheService).getStation(stationCode);
  const router = inject(Router);
  if (!station) {
    return router.createUrlTree(['/station']);
  } else {
    return true;
  }
};
