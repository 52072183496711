/**
 * @file
 * Models and functions related to equilibre actions.
 */

/**
 * List of all possible actions.
 */
export enum EQ_ACTION_ID {
  DUPLICATE_EQ = 'DUPLICATE_EQ',
  DUPLICATE_TRAIN = 'DUPLICATE_TRAIN',
  SPLIT_EQ = 'SPLIT_EQ',
  START_EQJOIN_STANDARD = 'START_EQJOIN_STANDARD',
  START_COUPE = 'START_COUPE',
  START_ACCROCHE = 'START_ACCROCHE',
  COMPLETE_EQJOIN_STANDARD = 'COMPLETE_EQJOIN_STANDARD',
  COMPLETE_COUPE = 'COMPLETE_COUPE',
  COMPLETE_ACCROCHE = 'COMPLETE_ACCROCHE',
  DELETE_TRAIN = 'DELETE_TRAIN',
  DELETE_EQ = 'DELETE_EQ',
  DELETE_EQ_GROUP = 'DELETE_EQ_GROUP',
}

/**
 * Interface describing a specific action.
 */
export interface EqAction {
  id: EQ_ACTION_ID;
  label: string;
  styleClass: string;
}

/**
 * List of all actions that may be triggered from an eq.
 */
export const ALL_EQ_ACTIONS: { [actionId: string]: EqAction } = {
  [EQ_ACTION_ID.DUPLICATE_EQ]: {
    id: EQ_ACTION_ID.DUPLICATE_EQ,
    label: `Dupliquer l'équilibre`,
    styleClass: `link-duplicated eq-train`,
  },
  [EQ_ACTION_ID.DUPLICATE_TRAIN]: {
    id: EQ_ACTION_ID.DUPLICATE_TRAIN,
    label: `Dupliquer le train`,
    styleClass: `link-duplicated eq-train`,
  },
  [EQ_ACTION_ID.SPLIT_EQ]: {
    id: EQ_ACTION_ID.SPLIT_EQ,
    label: `Rompre le lien d'équilibre`,
    styleClass: 'link-split-eq',
  },
  [EQ_ACTION_ID.START_EQJOIN_STANDARD]: {
    id: EQ_ACTION_ID.START_EQJOIN_STANDARD,
    label: `Établir un lien d'équilibre`,
    styleClass: 'link-eqjoin',
  },
  [EQ_ACTION_ID.START_COUPE]: {
    id: EQ_ACTION_ID.START_COUPE,
    label: `Former une coupe`,
    styleClass: 'link-coupe',
  },
  [EQ_ACTION_ID.START_ACCROCHE]: {
    id: EQ_ACTION_ID.START_ACCROCHE,
    label: `Former une accroche`,
    styleClass: 'link-accroche',
  },
  [EQ_ACTION_ID.COMPLETE_EQJOIN_STANDARD]: {
    id: EQ_ACTION_ID.COMPLETE_EQJOIN_STANDARD,
    label: `Établir l'équilibre`,
    styleClass: 'link-eqjoin',
  },
  [EQ_ACTION_ID.COMPLETE_COUPE]: {
    id: EQ_ACTION_ID.COMPLETE_COUPE,
    label: `Former la coupe`,
    styleClass: 'link-coupe',
  },
  [EQ_ACTION_ID.COMPLETE_ACCROCHE]: {
    id: EQ_ACTION_ID.COMPLETE_ACCROCHE,
    label: `Former l'accroche`,
    styleClass: 'link-accroche',
  },
  [EQ_ACTION_ID.DELETE_TRAIN]: {
    id: EQ_ACTION_ID.DELETE_TRAIN,
    label: `Supprimer`,
    styleClass: 'link-delete-border',
  },
  [EQ_ACTION_ID.DELETE_EQ]: {
    id: EQ_ACTION_ID.DELETE_EQ,
    label: `Supprimer l'équilibre`,
    styleClass: 'link-delete-border',
  },
  [EQ_ACTION_ID.DELETE_EQ_GROUP]: {
    id: EQ_ACTION_ID.DELETE_EQ_GROUP,
    label: `Supprimer le groupe d'équilibres`,
    styleClass: 'link-delete-border',
  },
};
