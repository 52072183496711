import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BackendServiceError } from './backend.service-error';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  protected http = inject(HttpClient);
  protected router = inject(Router);
  private readonly backendURI: string;

  constructor() {
    this.backendURI = environment.backendURI;
  }

  get(uri: string, options = {}): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    return this.http.get(url, Object.keys(options).length === 0 ? undefined : options).pipe(catchError(err => this.handleError(err)));
  }

  post(uri: string, body: any, options?: { headers: HttpHeaders }): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    return this.http.post(url, body, options).pipe(catchError(err => this.handleError(err)));
  }

  patch(uri: string, body: any, options?: { headers: HttpHeaders }): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    return this.http.patch(url, body, options).pipe(catchError(err => this.handleError(err)));
  }

  put(uri: string, body: any, options?: { headers: HttpHeaders }): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    return this.http.put(url, body, options).pipe(catchError(err => this.handleError(err)));
  }

  delete(uri: string, options?: { headers: HttpHeaders }): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    return this.http.delete(url, options).pipe(catchError(err => this.handleError(err)));
  }

  //

  private handleError(err: any): Observable<any> {
    let customError: any;
    if (err instanceof HttpErrorResponse) {
      // console.error('----- [BackendService] Erreur attrapée -----', err);
      if (err.status === 0) {
        customError = BackendServiceError._NO_NETWORK(err, 'Pas de réseau');
      } else if (400 === err.status) {
        customError = BackendServiceError._400(err, 'Identifiants inconnus');
        this.router.navigateByUrl('auth');
      } else if (401 === err.status) {
        customError = BackendServiceError._401(err, 'Token invalide');
        this.router.navigateByUrl('auth');
      } else {
        customError = BackendServiceError._UNKNOWN(err, err.message || 'Erreur inconnue');
      }
    }
    return throwError(() => customError || err);
  }
}
