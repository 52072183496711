<section class="date-nav">
  <div class="buttons flex center">
    <app-group-button [buttonItems]="buttonItems">
    </app-group-button>

    <div *ngIf="currentMonth" class="button month" (click)="monthMenu.toggle($event)">
      <span>{{ months[currentMonth - 1] | titlecase }}</span>
    </div>
    <p-menu #monthMenu [popup]="true" [model]="monthMenuItems"></p-menu>

    <div *ngIf="selectedYear" class="button year"
      (click)="yearMenu.toggle($event); toggleYear = !toggleYear">
      <span>{{ currentYearLabel }}</span>
      <img [@rotatedState]="!toggleYear? 'default': 'rotated'" class="arrow" src="/assets/img/arrow-subperiod-down.svg" />
    </div>
    <p-menu #yearMenu [popup]="true" [model]="yearMenuItems" (onHide)="onHide()"></p-menu>
  </div>
</section>
