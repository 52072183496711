import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { anonymousGuard, authGuard } from '@app/auth';

import { PageLoginComponent } from './page-login/page-login.component';
import { PageLogoutComponent } from './page-logout/page-logout.component';
import { PageStationSelectionComponent } from './page-station-selection/page-station-selection.component';
import { PageChangePwdComponent } from './page-change-pwd/page-change-pwd.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { stationGuard } from './services/station.guard';

const routes: Routes = [
  // ----- ADMIN ROUTES -----
  // ----- THESE ROUTES MUST BE DECLARED * BEFORE * OTHER PATH-LESS ROUTES -----

  { path: 'admin-panel', redirectTo: 'admin' },
  {
    path: 'admin',
    canActivate: [authGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },

  // ----- FRONT-END ROUTES -----

  {
    path: 'auth',
    component: PageLoginComponent,
    canActivate: [anonymousGuard],
  },
  {
    path: 'logout',
    component: PageLogoutComponent,
  },

  // ----- Protected Routes -----
  {
    path: '',
    canActivate: [authGuard],
    children: [
      { path: '', redirectTo: 'station', pathMatch: 'full' },
      { path: 'station', component: PageStationSelectionComponent },
      { path: 'change-pwd', component: PageChangePwdComponent },
      { path: 'info', component: PageInfoComponent },
      {
        path: ':station/calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [stationGuard],
      },
      {
        path: ':station/conception',
        loadChildren: () => import('./conception/conception.module').then(m => m.ConceptionModule),
        canActivate: [stationGuard],
      },
      {
        path: ':station/:module/travaux',
        loadChildren: () => import('./travaux/travaux.module').then(m => m.TravauxModule),
        canActivate: [stationGuard],
      },
      {
        path: ':station/settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [stationGuard], // DO NOT use `canActivateChild` here because the ":station" param is not accessible to child routes
      },
      {
        path: 'gov',
        loadChildren: () => import('./gov-ui/gov-ui.module').then(m => m.GovUiModule),
        canActivateChild: [stationGuard], // DO NOT use `canActivate` here because the ":station" param only appears in child routes
      },
      // ADMIN & DEV PAGES
      { path: 'admin-panel', redirectTo: 'admin' },
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
      { path: 'dev', loadChildren: () => import('./dev/dev.module').then(m => m.DevModule) },
    ],
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
