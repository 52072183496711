import { Component, OnInit, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';

import * as _ from 'lodash';

import { Station, WorkMode, BreadcrumbFragment } from '@app/models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() station: Station;
  @Input() workMode: WorkMode;
  @Input() isTravauxPage = false;
  @Input() bcFragments: BreadcrumbFragment[]; // Optional, additional breadcrumb fragments
  // They will be prepended to the "base fragments".
  @Input() bcCalendarWhen: string; // String in the YYYY-MM format indicating the active year/month for the "Calendar" link.
  @Input() workModeLabel: string;

  breadcrumb: BreadcrumbFragment[]; // Final breadcrumb

  private hasAdditionalFragments: boolean; // true if the user supplied additional breadcrumb fragments

  ngOnInit(): void {
    this.hasAdditionalFragments = !!this.bcFragments;
    this.buildBreadcrumb();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Update the breadcrumb whenever the active year/month changes for the "Calendar" link in the BC.
    if (changes.bcCalendarWhen && !changes.bcCalendarWhen.firstChange) {
      this.buildBreadcrumb();
    }
  }

  private buildBreadcrumb() {
    this.breadcrumb = [];
    // Fragment 1

    // if station is selected
    if (this.station) {
      this.breadcrumb.push({
        label: this.station.nom,
        path: ['/station'],
        queryParams: { station: this.station.code },
        tooltip: `Retour Page d'accueil gare`,
      });
    } else {
      // else adminPage BreadCrumb begins with Home Label redirecting to homepage
      this.breadcrumb.push({
        label: 'Accueil',
        path: ['/'],
        tooltip: 'Retour Liste des gares',
      });
    }

    // Fragment 2
    if (this.workMode === WorkMode.Conception) {
      this.breadcrumb.push({
        label: this.workModeLabel ? this.workModeLabel : this.workMode,
        path: this.hasAdditionalFragments ? ['/', this.station.code, 'conception'] : undefined,
        queryParams: this.bcCalendarWhen ? { when: this.bcCalendarWhen } : undefined,
        tooltip: !_.isEmpty(this.bcFragments) ? 'Voir une autre journée type' : undefined,
        iconPath: `/assets/img/${this.hasAdditionalFragments ? 'ic-header-conception-clickable.svg' : 'ic-header-conception-not-clickable.svg'}`,
      });
    } else if (this.workMode === WorkMode.Adaptation) {
      this.breadcrumb.push({
        label: this.workMode,
        path: this.hasAdditionalFragments ? ['/', this.station.code, 'calendar'] : undefined,
        queryParams: this.bcCalendarWhen ? { when: this.bcCalendarWhen } : undefined,
        tooltip: !_.isEmpty(this.bcFragments) ? 'Voir une autre date' : undefined,
        iconPath: `/assets/img/${this.hasAdditionalFragments ? 'ic-header-adaptation-clickable.svg' : 'ic-header-adaptation-not-clickable.svg'}`,
      });
    } else if (this.workMode === WorkMode.Admin) {
      this.breadcrumb.push({
        label: this.workMode,
        path: this.hasAdditionalFragments ? ['/admin'] : undefined,
        tooltip: !_.isEmpty(this.bcFragments) ? "Retour Page d'accueil Admin" : undefined,
        iconPath: !this.bcFragments ? '/assets/img/ic-header-spanner-black.svg' : '/assets/img/ic-header-spanner-purple.svg',
      });
    }
    // Are there any additional (optional) breadcrumb fragments?
    if (this.bcFragments) {
      this.bcFragments.forEach(bcFragment => this.breadcrumb.push(bcFragment));
    }
  }
}
