/**
 * Station parameters that need to be passed to an EquilibreUi2
 */
import { GOV_COMMENT_DISPLAY_AS } from '@app/gov-ui';
import { TypeLabelOrigDest, CardinalDirection } from './misc-models';
import { ColorByType } from './transportation-plan';

export interface StationPreferences {
  showAmorces: boolean;
  showCommentsAs: GOV_COMMENT_DISPLAY_AS;
  showPasseMinuitTrainNumbers: boolean;
  showTypeMateriel: boolean;
  showTravauxOpsName: boolean;
  showOrigineDestEquilInfo: boolean;
  origineDestEquilInfoType: TypeLabelOrigDest;
  colorsLineByType: ColorByType[];
  colorsTrainNumberByType: ColorByType[];
  showPasseMinuitTrainHours: boolean;
}

/**
 * Mapping of a station orientations (NORD/SUD or EST/OUEST)
 * to either the "gauche" or "droite" direction.
 */
export type StationOrientationMapping = {
  [key in keyof typeof CardinalDirection]: 'droite' | 'gauche';
};

export const STATION_ORIENTATION_MAPPING_DEFAULT: StationOrientationMapping = {
  [CardinalDirection.NORD]: 'gauche',
  [CardinalDirection.SUD]: 'droite',
  [CardinalDirection.EST]: 'gauche',
  [CardinalDirection.OUEST]: 'droite',
};
