import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ValidationErrors,
  UntypedFormGroup,
  UntypedFormBuilder,
  ControlValueAccessor,
  Validator,
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { OptionItem } from '@app/admin/models';

@Component({
  selector: 'app-select-with-padlock',
  templateUrl: './select-with-padlock.component.html',
  styleUrls: ['./select-with-padlock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWithPadlockComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectWithPadlockComponent),
      multi: true,
    },
  ],
})
export class SelectWithPadlockComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() label: string;
  @Input() items: OptionItem[];

  @Output() selectChanged = new EventEmitter<number | string>();
  @Output() padlockChanged = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  isDisabled = false;

  unlockedTooltipMessage = "Déverrouiller l'itinéraire";
  lockedTooltipMessage = "Imposer l'itinéraire";

  private subs: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      dropdownValue: [],
      isLocked: [],
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  // ---------- Form API — START ----------

  /**
   * Populate the form with data received from the parent form.
   *
   * Note that it might be PARTIAL data.
   */
  writeValue(data: { dropdownValue: any; isLocked: boolean }): void {
    // console.log(`[DropdownWithPadlock] writeValue`, data);
    this.form.patchValue(data, { emitEvent: false });
  }

  private _onChange: (_: any) => void = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this.subs.push(
      this.form.valueChanges
        .pipe
        // tap(formData => console.log(`[DropdownWithPadlock] valueChanges...`, formData)),
        ()
        .subscribe(fn),
    );
  }

  private _onTouched: () => void = () => {};
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // Called when disabled status is set via Angular Form API.
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
    this.isDisabled = isDisabled; // set our local disabled prop
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return this.form.errors;
  }

  // ---------- Form API — END ----------

  get isLocked() {
    return this.form.get('isLocked');
  }

  // Padlock is disabled when dropdown is empty.
  get isPadlockDisabled(): boolean {
    const formData = this.form && this.form.value;
    return formData && (formData.dropdownValue === undefined || formData.dropdownValue === -1);
  }

  // Toggle the padlock, optionally forcing a specific value
  togglePadlock(forceIsLocked?: boolean) {
    if (this.isDisabled || this.isPadlockDisabled) {
      return;
    }
    const wasLocked = this.form.get('isLocked').value;
    const isLocked = forceIsLocked !== undefined ? forceIsLocked : !wasLocked;
    // Update the field + Emit if the value has changed
    if (wasLocked !== isLocked) {
      this.form.patchValue({ isLocked });
      this.padlockChanged.emit(isLocked);
    }
  }

  onItemSelected(event: Event) {
    this.lockPadlock(); // lock whenever a value is selected
    const v = (event.target as HTMLInputElement).value;
    this.selectChanged.emit(v);
  }

  private lockPadlock() {
    this.togglePadlock(true);
  }
}
