/**
 * @file
 * Service handling GOV "certification" operations.
 */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TransportationPlanDto } from '@app/models';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class CertificationService extends BaseService {
  constructor() {
    super();
  }

  /**
   * Contact an endpoint exposed by OpenGOV Temps Réel
   * to determine whether the given GOV has already been imported into OGTR.
   *
   * Official specs (https://opengov-project.atlassian.net/browse/OP-1928):
   *
   *   OGTR ENDPOINT:
   *     govLoaded(gare, date)
   *   Possible responses:
   *     - false: The current GOV has not yet been imported into OGTR.
   *              The certification can be cancelled.
   *     - true:  The current GOV has not already been imported into OGTR.
   *              The certification can NOT be cancelled.
   *
   * @param stationCode GOV station code.
   * @param tpRid       GOV date in the YYYY-MM-DD format. Example: 2020-05-06.
   */
  wasGovImportedIntoOGTR(stationCode: string, tpRid: string): Observable<boolean> {
    return this.backend.get(`api/gares/${stationCode}/plandetransports/${tpRid}/ogtr-loaded`);
    // .pipe(
    //   tap(data => console.log(`wasGovImportedIntoOGTR`, data))
    // );
  }

  /**
   * Mark the given GOV as "certified".
   *
   * @param stationCode GOV station code.
   * @param tpRid       GOV date in the YYYY-MM-DD format. Example: 2020-05-06.
   */
  certifyGov(stationCode: string, tpRid: string): Observable<TransportationPlanDto> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpRid}/certify`, {});
    // .pipe(
    //   tap(data => console.log(`certifyGov`, data))
    // );
  }

  /**
   * Mark the given GOV as "not certified".
   *
   * @param stationCode GOV station code.
   * @param tpRid       GOV date in the YYYY-MM-DD format. Example: 2020-05-06.
   */
  unCertifyGov(stationCode: string, tpRid: string): Observable<TransportationPlanDto> {
    return this.backend.post(`api/gares/${stationCode}/plandetransports/${tpRid}/uncertify`, {});
    // .pipe(
    //   tap(data => console.log(`unCertifyGov`, data))
    // );
  }

  //

  /**
   * Contact an endpoint exposed by OpenGOV Temps Réel
   * to determine whether the current GOV is certifiable.
   *
   * Official specs (https://opengov-project.atlassian.net/browse/OP-1928):
   *
   *   ENDPOINT:
   *     certifyGOV(gare, date)
   *   Possible responses:
   *     - ACQUITTED: The current GOV is "certifiable", i.e. eligible to be imported into OGTR.
   *     - ALREADY_LOADED: The current GOV is not certifiable because another GOV was already imported into OGTR.
   */
  // isGovCertifiable__DEPREC(stationCode: string, tpRid: string): Observable<boolean> {
  //   return of(opts.mockResponseTemp)
  //     .pipe(
  //       delay(1000),
  //     );
  // }
}
