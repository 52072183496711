<app-header></app-header>

<section class="page-station-selection">
  <div class="cols">
    <div class="col col-left">
      <div class="content">
        <div class="title">
          <h3 *ngIf="currentStation">{{ currentStation.nom }}</h3>
        </div>
        <div class="station-img">
          <img src="/assets/img/illu-station.jpg" />
        </div>
        <div class="station-actions" *ngIf="currentStation">
          <button *ngIf="stations && stations.length > 1" class="og-btn-flat og-btn-flat--icon"
            (click)="goToStation()">
            <img src="/assets/img/ic-change-station-default.svg">
            <span>Changer de gare</span>
          </button>

          <button class="og-btn-flat og-btn-flat--icon"
            (click)="saveCurrentStationAndGoTo('settings')">
            <img src="/assets/img/ic-station-settings-default.svg">
            <span>Paramètres gare</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col col-right">
      <div class="content">
        <section class="select-station" *ngIf="!currentStation">
          <p>Sélectionnez une gare :</p>
          <div class="station-list-wrapper">
            <ul class="station-list">
              <li *ngFor="let station of stations" (click)="selectStation(station.code)">{{ station.nom }}</li>
            </ul>
          </div>
        </section>

        <section class="select-work-mode" *ngIf="currentStation">
          <a href="javascript:void(0)" class="item" (click)="saveCurrentStationAndGoTo('conception')">
            <span class="icon conception" title="Conception"></span>
            <div class="content">
              <h4>Conception</h4>
              <p>Création et édition de journées types</p>
            </div>
            <div>
              <span class="arrow" title="Flèche"></span>
            </div>
          </a>
          <a href="javascript:void(0)" class="item" (click)="saveCurrentStationAndGoTo('calendar')">
            <span class="icon adaptation" title="Adaptation"></span>
            <div class="content">
              <h4>Adaptation</h4>
              <p>Modification du GOV jusqu'à la veille 17:00</p>
            </div>
            <div>
              <span class="arrow" title="Flèche"></span>
            </div>
          </a>
          <!--
          <a href="javascript:void(0)" class="item" (click)="saveCurrentStationAndGoTo('travaux')">
            <img src="/assets/img/ic-mode-work.svg" alt="Travaux">
            <div class="content">
              <h4>Travaux</h4>
              <p>Import et création d'opérations travaux</p>
            </div>
          </a>
          -->
        </section>
      </div>
    </div>
  </div>
  <!-- .cols -->
</section>
