import { Component } from '@angular/core';
import { AdminInfoComponent } from '@app/admin/admin-refonte/admin-info/admin-info.component';
import { BaseComponent } from '@app/base.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-info-menu',
  templateUrl: './info-menu.component.html',
  styleUrls: ['./info-menu.component.scss'],
})
export class InfoMenuComponent extends BaseComponent {
  constructor(
    protected dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  /** when info btn is clicked in the header */
  openInfoMenu() {
    this.dialogRef = this.dialogService.open(AdminInfoComponent, {
      header: 'Admin - Informations',
      styleClass: 'dialog-with-header',
    });
  }
}
