<div class="certify-component">
  <!-- Bouton "Certifier" -->
  <div *ngIf="!isGovCertified; else notCertified">
    <button type="button" id="btn-certify" class="og-btn-icon og-btn-icon--primary" (click)="certify()"
      pTooltip="Certifier" tooltipPosition="bottom">
      <img src="/assets/img/ic-header-certify-default.svg" alt="Certifier">
    </button>
  </div>

  <!-- Bouton "Annuler" -->
  <ng-template #notCertified>
    <div>
      <button type="button" id="btn-uncertify"
        class="og-btn-outline og-btn-outline--success og-btn-outline--icon og-btn-outline--success--validate"
        (click)="unCertify()">
        <img src="/assets/img/ic-header-certify-on.svg" alt="Certifié" />
        <span>Certifié</span>
      </button>
    </div>
  </ng-template>
</div>
