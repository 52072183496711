import { JourSemaine } from '@app/conception/models/conception';

export enum WeekDay {
  Lu = 'Lu',
  Ma = 'Ma',
  Me = 'Me',
  Je = 'Je',
  Ve = 'Ve',
  Sa = 'Sa',
  DF = 'DF',
  LF = 'LF',
}

/**
 * Converts JourSemaine to WeekDay
 * @param jourSemaine The jour semaine
 * @returns The equivalent week day
 */
export function jourSemaineToWeekDay(jourSemaine: JourSemaine): WeekDay {
  let weekDay: string;
  if (jourSemaine !== JourSemaine.DF && jourSemaine !== JourSemaine.LF) {
    weekDay = jourSemaine.charAt(0) + jourSemaine.charAt(1).toLowerCase();
  } else {
    weekDay = jourSemaine;
  }
  return WeekDay[weekDay];
}

/**
 * Converts WeekDay to JourSemaine
 * @param weekDay The week day
 * @returns The equivalent jour semaine
 */
export function weekDayToJourSemaine(weekDay: WeekDay): JourSemaine {
  const jourSemaine = weekDay.charAt(0) + weekDay.charAt(1).toUpperCase();
  return JourSemaine[jourSemaine];
}
