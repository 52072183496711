/**
 * @file
 * Redirect authenticated users * AWAY * from the current page.
 * In other words, this guard only allows anonymous users to activate the page.
 */

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const anonymousGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isLoggedIn = inject(AuthService).isLoggedIn();
  if (isLoggedIn) {
    return router.createUrlTree(['/station']);
  } else {
    return true;
  }
};
