import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Activate the "fr" locale for Moment
import * as moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

// Custom modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './header';
import { AuthModule } from './auth';

// Components
import { AppComponent } from './app.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageLogoutComponent } from './page-logout/page-logout.component';
import { PageStationSelectionComponent } from './page-station-selection/page-station-selection.component';
import { PageChangePwdComponent } from './page-change-pwd/page-change-pwd.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

// Services
import { AppInjector } from './app-injector';

@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    PageLogoutComponent,
    PageStationSelectionComponent,
    PageChangePwdComponent,
    PageInfoComponent,
    PageNotFoundComponent,
  ],
  imports: [
    // Core modules
    BrowserModule,
    BrowserAnimationsModule, // Required by PrimeNG
    // Custom modules
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HeaderModule,
    AuthModule.forChild(), // this is only to access exported components
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
