import { trigger, state, style, transition, animate } from '@angular/animations';

/**
 * The fade-in/fade-out animation.
 */
export const fadeAnimation = trigger('simpleFadeAnimation', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0 }), animate(300)]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(300, style({ opacity: 0 }))),
]);

/**
 * The smooth collapse animation.
 */
export const smoothCollapseAnimation = trigger('smoothCollapse', [
  state(
    'close',
    style({
      height: '0',
      overflow: 'hidden',
      opacity: '0',
      visibility: 'hidden',
      'padding-top': '0',
      'padding-bottom': '0',
    }),
  ),
  state(
    'open',
    style({
      overflow: 'hidden',
    }),
  ),
  transition('close <=> open', animate('250ms')),
]);

/**
 * The rotated animation.
 */
export const rotatedAnimation = trigger('rotatedState', [
  state('default', style({ transform: 'rotate(0)' })),
  state('rotated', style({ transform: 'rotate(180deg)' })),
  transition('default <=> rotated', animate('250ms')),
]);

export const removeRowAnimation = trigger('removeRow', [
  transition(':leave', [
    style({ backgroundColor: '#fee4e4' }),
    animate(
      '300ms ease-out',
      style({
        overflow: 'hidden',
        height: '0',
        visibility: 'hidden',
        'padding-top': '0',
        'padding-bottom': '0',
      }),
    ),
  ]),
]);
