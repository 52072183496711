/**
 * @file
 * State of the Equilibre Form (in the sidebar).
 *
 * This state consists of sub-properties of `SidebarStatePrivate.eqFormInfo`
 */
import { EquilibreDto } from './transportation-plan';

export interface EqFormState {
  isFormDirty: boolean;
  isFormInvalid: boolean;
  eqDto: EquilibreDto; // Eq being edited
}
