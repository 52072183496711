import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isAdmin = false;

  menuItems: MenuItem[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.menuItems = [
      { label: `Interface d'administration`, routerLink: ['/admin'], icon: 'link admin', visible: this.isAdmin },
      { label: `Changer le mot de passe`, routerLink: ['/change-pwd'], icon: 'link password' },
      { label: `Se déconnecter`, command: () => this.clickLogout(), icon: 'link logout' },
    ];
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  clickLogout() {
    this.subscriptions.push(
      this.dialog
        .show({ title: 'Déconnexion', content: 'Voulez-vous vraiment vous déconnecter ?', showCancel: true })
        .subscribe(clickedOk => clickedOk && this.router.navigate(['/logout'])),
    );
  }
}
