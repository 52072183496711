/**
 * @file
 * Types and subtypes of conflicts.
 */
import { ConflictCategory, Conflict, StatutConflictValue } from './conflict';

export interface ConflictType {
  type: string;
  libelle: string;
  category: ConflictCategory;
  concernedByStatut: boolean;
  sousTypes: ConflictSubtype[];
}

export interface ConflictSubtype {
  sousType: string;
  libelle: string;

  // frontend-computed prop
  $conflicts: Conflict[];
  statut: StatutConflictValue;
}
