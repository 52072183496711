/**
 * @file
 * An dropdown item as in used in DropDownComponent.
 */
export class DropDownItem {
  index: number;
  id: any;
  value: string;

  constructor(index: number, id: any, value: string) {
    this.index = index;
    this.id = id !== undefined ? id : 'NA';
    this.value = `${value}`.trim() || 'NA';
  }
}
