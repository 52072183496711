<div class="dropdown-padlock" [formGroup]="form">
  <div class="label-row">
    <label>{{ label }}</label>
    <img
      [src]="'/assets/img/' + (isLocked.value ? 'ic-itinerary-locked.svg' : 'ic-itinerary-unlocked.svg')"
      class="padlock pointer"
      [class.disabled]="isDisabled || isPadlockDisabled"
      [ngClass]="isLocked.value ? 'locked' : 'unlocked'"
      (click)="togglePadlock()"
      [pTooltip]="isLocked.value ? unlockedTooltipMessage : lockedTooltipMessage"
      tooltipPosition="top"
    />
  </div>
  <input type="checkbox" formControlName="isLocked" class="d-none" />
  <select formControlName="dropdownValue" (change)="onItemSelected($event)">
    <option value="null" disabled>-</option>
    <option *ngFor="let item of items" [value]="item.key">{{ item.label }}</option>
  </select>
</div>
