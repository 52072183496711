<p-dialog
  [(visible)]="isDialogVisible"
  [header]="dialogInfo.title"
  [showHeader]="showHeader"
  [modal]="true"
  [style]="{width: dialogInfo.width + 'px'}"
  [baseZIndex]="10000"
  [ngClass]="showHeader ? 'header' : 'no-header'"
>
  <pre class="content">{{ dialogInfo.content }}</pre>
  <p-checkbox
    *ngIf="dialogInfo.checkboxLabel"
    class="p-element"
    [(ngModel)]="dialogInfo.checkboxOk"
    [binary]="true"
    [label]="dialogInfo.checkboxLabel"
    name="checkboxMiquai"
    value="{{ dialogInfo.checkboxOk }}"
  />

  <p-footer>
    <button type="button" (click)="cancel()" class="og-btn-outline og-btn-outline--alternative og-btn-outline--large"
      [ngClass]="dialogInfo.showCancel ? 'show': 'hide'">
      <span>{{ dialogInfo.cancelButtonLabel }}</span>
    </button>
    <button type="button" (click)="confirm()" class="og-btn og-btn--large confirm"
      [ngClass]="(dialogInfo.showConfirm ? 'show': 'hide') + ' og-btn--' + (dialogInfo.confirmButtonClass || 'primary')">
      <span>{{ dialogInfo.confirmButtonLabel }}</span>
    </button>
  </p-footer>
</p-dialog>
