import { Pipe, PipeTransform } from '@angular/core';
import { WeekDay } from './week-day';

@Pipe({
  name: 'weekDaySort',
})
export class WeekDaySortPipe implements PipeTransform {
  weekDays = Object.values(WeekDay).map(wd => wd.toUpperCase());

  transform(value: any[]): any[] {
    return value?.sort((a, b) => this.weekDays.indexOf(a) - this.weekDays.indexOf(b));
  }
}
