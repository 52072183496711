/**
 * Enum used for train creation form
 */
export enum TrainType {
  ARRIVEE = 'arrivee',
  DEPART = 'depart',
  PASSAGE = 'passage',
}

export enum TrainTypeLabel {
  ARRIVEE = 'Arrivée',
  DEPART = 'Départ',
  PASSAGE = 'Passage',
}
