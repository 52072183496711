import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BaseComponent } from '@app/base.component';
import { AuthService } from '@app/auth';
import { BackendServiceError } from '@app/backend';
import { CookieService } from '@app/services';
import { PageInfoComponent } from '@app/page-info/page-info.component';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss'],
})
export class PageLoginComponent extends BaseComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  errorMsg = '';

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private cookieService: CookieService,
  ) {
    super();
  }

  ngOnInit() {
    this.setPageTitle('Connexion');
    this.form = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
    // Reset error message whenever the form changes.
    this.subscriptions.push(this.form.valueChanges.subscribe(() => (this.errorMsg = '')));
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  //

  submit() {
    if (this.form.valid) {
      this.spinner.show();

      const { login, password } = this.form.value;
      this.subscriptions.push(
        this.auth.login(login, password).subscribe({
          next: () => {
            this.spinner.hide();
            const isAdmin = this.auth.isAdminTEMP();
            // Show the info page if it was never shown and if we are before its expiration date.
            const showInfoPage =
              moment().isBefore(moment(PageInfoComponent.EXPIRATION_DATE)) && !this.cookieService.get(PageInfoComponent.WAS_SHOWN_COOKIE);
            const dest = isAdmin ? ['/admin'] : showInfoPage ? ['/info'] : ['/station'];
            this.router.navigate(dest);
          },
          error: (err: BackendServiceError) => {
            this.spinner.hide();
            if (err.errorCode === 400 && err.message === 'Identifiants inconnus') {
              this.errorMsg = `Identifiants incorrects.`;
            } else if (err.errorCode === 0 && err.message === 'Pas de réseau') {
              this.errorMsg = `Connexion impossible. Veuillez vérifier votre connexion réseau et réessayer.`;
            } else {
              this.errorMsg = `Connexion impossible. Veuillez contacter l'équipe OpenGOV. (Code erreur ${err.errorCode})`;
            }
          },
        }),
      );
    }
  }
}
