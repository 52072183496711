import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';

import { BaseComponent } from '@app/base.component';
import { USER_PERMISSION } from '@app/auth';
import { GovData, Station, TP_ACTION, WorkMode, BreadcrumbFragment, EXPORT_TYPES } from '@app/models';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() station: Station | null;
  @Input() workMode = WorkMode.None;
  @Input() workModeLabel: string;
  @Input() govData: GovData | null;
  @Input() govDataHash: string; // Keep this! Not used as is, but triggers ngOnChanges (which `this.govData` won't).
  @Input() userPermissions: USER_PERMISSION[] = [];
  @Input() isAdmin = false;
  @Input() isCalendarPage = false;
  @Input() isConceptionPage = false;
  @Input() isGOVPage = false;
  @Input() bcFragments: BreadcrumbFragment[];
  @Input() bcCalendarWhen: string;
  @Input() lastAppliedJtDescription: string;
  @Input() isAdminMode = false;

  @Output() optimizeTp = new EventEmitter<void>();
  @Output() synchronizeTp = new EventEmitter<TP_ACTION>();
  @Output() updateTp = new EventEmitter<{ action: TP_ACTION }>();
  @Output() export = new EventEmitter<{ what: EXPORT_TYPES; data?: any }>();
  @Output() applyJourneeType = new EventEmitter<void>();
  @Output() showGovDataFilters = new EventEmitter<void>();
  @Output() showUserPreferences = new EventEmitter<void>();

  version: string;
  headerWidth: number;

  // Permissions
  canOptimize = false;
  canCertify = false;
  canExport = false;
  canViewTravaux = false;
  canValidateJT = false;
  canApplyJT = false;
  canApplyGovSettings = false;
  canSynchronize = false;

  constructor() {
    super();
    this.version = environment.VERSION;
  }

  ngOnInit() {
    // Calendar has a vertical scrollbar. We must reduce the width to accommodate it.
    this.headerWidth = this.isCalendarPage ? window.innerWidth - 20 : window.innerWidth;

    // Init user permissions
    this.canExport = !!this.govData;
    // Station-specific permissions
    if (this.station) {
      this.canOptimize = !!this.govData && this.userPermissions.includes(USER_PERMISSION.OPTIMISATION_GLOBALE);
      this.canCertify =
        this.workMode === WorkMode.Adaptation && !!this.govData && this.userPermissions.includes(USER_PERMISSION.CERTIFICATION);
      this.canViewTravaux = this.isCalendarPage || this.isConceptionPage;
      this.canValidateJT = this.workMode === WorkMode.Conception && this.userPermissions.includes(USER_PERMISSION.EDITION);
      this.canApplyJT =
        this.workMode === WorkMode.Adaptation &&
        !!this.govData && // To not display in the calendar
        this.userPermissions.includes(USER_PERMISSION.EDITION);
      this.canApplyGovSettings = (this.workMode === WorkMode.Conception || this.workMode === WorkMode.Adaptation) && !!this.govData;
      this.canSynchronize =
        !!this.govData &&
        this.workMode === WorkMode.Adaptation &&
        (this.userPermissions.includes(USER_PERMISSION.SYNCHRO_PASSE_MINUIT) ||
          this.userPermissions.includes(USER_PERMISSION.SYNCHRO_HOUAT));
    }
  }

  // Relay some Output events to the parent component
  onOptimizeTp() {
    this.optimizeTp.emit();
  }
  onUpdateTp(event) {
    this.updateTp.emit(event);
  }
  onExport(event) {
    this.export.emit(event);
  }
  onApplyJourneeType() {
    this.applyJourneeType.emit();
  }
  onShowGovDataFilters() {
    this.showGovDataFilters.emit();
  }
  onShowUserPreferences() {
    this.showUserPreferences.emit();
  }
  onSynchronizeTp(tpAction: TP_ACTION) {
    this.synchronizeTp.emit(tpAction);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.headerWidth = document.body.clientWidth;
  }
}
