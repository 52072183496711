import { Component, EventEmitter, Output, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DialogService } from '@app/shared';
import { User } from '../models/user';

@Component({
  selector: 'app-change-pwd-form',
  templateUrl: './change-pwd-form.component.html',
  styleUrls: ['./change-pwd-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePwdFormComponent implements OnInit {
  @Input() user: User;
  @Output() changePwd = new EventEmitter<{ oldPassword: string; newPassword: string }>();
  // @Output() showOverlay = new EventEmitter<boolean>();

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: DialogService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[0-9])(?=.*[#$@$!%*?&,;:=+"\'()§])[A-Za-z\\d#$@$!%*?&].{7,}')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('(?=.*[0-9])(?=.*[#$@$!%*?&,;:=+"\'()§])[A-Za-z\\d#$@$!%*?&].{7,}')]],
    });
  }

  submitNewPwd() {
    if (this.form.valid) {
      const oldPasswordForm = this.form.get('oldPassword');
      const oldPassword: string = oldPasswordForm && oldPasswordForm.value;

      const passwordForm = this.form.get('password');
      const newPassword: string = passwordForm && passwordForm.value;

      const confirmPasswordForm = this.form.get('confirmPassword');
      const confirmPassword: string = confirmPasswordForm && confirmPasswordForm.value;

      if (newPassword === confirmPassword) {
        this.changePwd.emit({ oldPassword, newPassword });
        this.form.reset();
      } else {
        // this.toggleOverlay(true);
        this.dialog.show({ title: 'Erreur', content: 'Les mots de passe ne sont pas identiques.' });
        // .subscribe(() => this.toggleOverlay(false));
      }
    }
  }

  get hideErrorForPasswordField() {
    const fieldName = 'password';
    return this.form.controls[fieldName] && (this.form.controls[fieldName].valid || this.form.controls[fieldName].pristine);
  }
}
