import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component representing apply journee type button
 */
@Component({
  selector: 'app-apply-journee-type-button',
  templateUrl: './apply-journee-type-button.component.html',
  styleUrls: ['./apply-journee-type-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyJourneeTypeButtonComponent {
  /** Description of the last JT */
  @Input() lastAppliedJtDescription: string;

  /** Apply journee type event */
  @Output() applyJourneeType = new EventEmitter<void>();

  /**
   * Calls when clicking on the button
   */
  applyJT() {
    this.applyJourneeType.emit();
  }
}
