import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface MultiselectItem {
  label: string;
  value: any;
  srcImg?: string;
  nb?: number;
  disabled?: boolean;
}

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiselectComponent), multi: true }],
})
export class MultiselectComponent implements OnChanges, ControlValueAccessor {
  @Output() changeSelection = new EventEmitter<string[]>();

  @Input({ required: true }) id!: string;
  @Input({ required: true }) items: MultiselectItem[] = [];
  @Input() placeholder = '-';
  @Input() labelAll = 'Tous';
  @Input() hasBtnClose = false;
  @Input() hasSelectAll = true;
  @Input() hasIcons = false;

  isDisabled = false;
  isShowList = false;
  positionList: 'up' | 'down' = 'down';
  itemsSelected: MultiselectItem[] = [];
  form: FormRecord<FormControl<boolean>> | undefined;

  private readonly hMax = 300; // css input Height + list max-height
  private timer = null;
  private valuesForm: any | undefined;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.computePosition();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private el: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.form) {
      this.initForm();
    }
    setTimeout(() => {
      this.computePosition();
      this.cdRef.markForCheck();
    });
  }

  /* ----------------------------------------- */
  /* interface ControlValueAccessor 
  /* ----------------------------------------- */
  writeValue(data: any): void {
    this.valuesForm = data;
    if (!this.form) {
      this.initForm();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdRef.markForCheck();
  }

  /* ----------------------------------------- */
  /* public methods */
  /* ----------------------------------------- */
  onHideList(): void {
    this.isShowList = false;
  }

  onClickInput(): void {
    this.isShowList = !this.isShowList;
  }

  onFocusInField(): void {
    this.clearTimer();
    this.isShowList = true;
  }

  onFocusOut(): void {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.isShowList = false;
      this.cdRef.markForCheck();
    }, 250);
  }

  onToggleSelectAll(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.addAllItems();
    } else {
      this.removeAllItems();
    }
    this.onChange(this.valuesForm);
    this.changeSelection.emit(this.valuesForm);
  }

  onChangeCheckbox(event: Event, data: MultiselectItem): void {
    if ((event.target as HTMLInputElement).checked) {
      this.addItem(data);
    } else {
      this.removeItem(data);
    }
    this.onChange(this.valuesForm);
    this.changeSelection.emit(this.valuesForm);
  }

  /* ----------------------------------------- */
  /* private methods */
  /* ----------------------------------------- */

  private initForm(): void {
    this.itemsSelected = [];
    this.form = new FormRecord({});
    for (const item of this.items) {
      const isChecked = this.valuesForm ? this.valuesForm.includes(item.value) : false;
      const formCtrl = new FormControl(isChecked);
      this.form?.addControl(`${item.value}`, formCtrl);

      if (isChecked) {
        this.itemsSelected.push(item);
      }
    }
  }

  private addAllItems(): void {
    this.itemsSelected = [...this.items];
    this.valuesForm = [];
    for (const item of this.items) {
      this.valuesForm.push(item.value);
      this.form?.get(`${item.value}`)?.setValue(true);
    }
  }

  private removeAllItems(): void {
    this.itemsSelected = [];
    this.valuesForm = [];
    for (const item of this.items) {
      this.form?.get(`${item.value}`)?.setValue(false);
    }
  }

  private addItem(item: MultiselectItem): void {
    this.itemsSelected.push(item);
    const arr = this.valuesForm ? this.valuesForm : [];
    this.valuesForm = [...arr, item.value];
  }

  private removeItem(item: MultiselectItem): void {
    const index = this.itemsSelected.findIndex((d: MultiselectItem) => d.value === item.value);
    if (index >= 0) {
      this.itemsSelected.splice(index, 1);
    }

    const index2 = this.valuesForm.findIndex(s => s === item.value);
    if (index2 >= 0) {
      this.valuesForm.splice(index2, 1);
    }
  }

  private computePosition(): void {
    const coords = this.el.nativeElement.getBoundingClientRect();
    if (coords) {
      this.positionList = coords.y + this.hMax > window.innerHeight ? 'up' : 'down';
    }
  }

  private clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}
