import { IndispoItineraireSelection } from '@app/models/indispo-itineraire-selection';
import { TravauxOpSelection } from '@app/models/travaux-op-selection';

export interface ServiceAnnuel {
  id: number;
  codeGare: string;
  annee: number;
  nom: string;
  dateDebut: string;
  dateFin: string;
  periodeList: Periode[];
}

export interface Periode {
  id: number;
  indice: string;
  nom: string;
  dateDebut: string;
  dateFin: string;
  sousPeriodeList: SousPeriode[];
  updateDate: string;

  isChevauchementDebut?: boolean;
  isChevauchementFin?: boolean;
}

export interface SousPeriode {
  id: number;
  nom: string;
  dateDebut: string;
  dateFin: string;
  journeeTypeList: JourneeType[];
  updateDate: string;
}

export interface JourneeType {
  id: number; // Journee type id == tpRid
  nom: string;
  commentaire?: string;

  jourSemaineList?: JourSemaine[];

  dateList?: string[];
  origin: string;
  originDetails: string;

  etat?: string;

  updateDate?: string;

  nomSousPeriode?: string;
  nomPeriode?: string;
  indicePeriode?: number;

  operationTravauxSelections?: TravauxOpSelection[];
  indispoItineraireSelections?: IndispoItineraireSelection[];

  /** Number of travaux availables for the JT */
  nbTravauxAvailable?: number;
}

export enum JourneeTypeOrigin {
  GROIX_FILE = 'GROIX_FILE',
  OPENGOV_FILE = 'OPENGOV_FILE',
  GOV_ADAPTATION = 'GOV_ADAPTATION',
  JOURNEE_TYPE = 'JOURNEE_TYPE',
}

export enum JourSemaine {
  LU = 'LU',
  MA = 'MA',
  ME = 'ME',
  JE = 'JE',
  VE = 'VE',
  SA = 'SA',
  DF = 'DF',
  LF = 'LF',
}

export enum EtatJourneeType {
  ACTIVE = 'ACTIVE',
  VALIDATED = 'VALIDATED',
  DISCARDED = 'DISCARDED',
}
