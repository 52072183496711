<div class="validate-journee-type-component">
  <!-- Bouton "Valider journée type" -->
  <div *ngIf="!isGOVJTValidated; else jtNotValidated">
    <button
      type="button"
      class="og-btn-icon og-btn-icon--primary"
      id="btn-validate"
      (click)="validate()"
      pTooltip="Valider la journée type"
      tooltipPosition="bottom"
    >
      <img src="/assets/img/ic-header-validate-jt-default.svg" alt="Valider" />
    </button>
  </div>

  <!-- Bouton "Annuler" -->
  <ng-template #jtNotValidated>
    <div>
      <button type="button" id="btn-unvalidate"
        class="og-btn-outline og-btn-outline--success og-btn-outline--icon og-btn-outline--success--validate"
        (click)="unValidate()">
        <img src="/assets/img/ic-header-jt-validated.svg" alt="Validée" />
        <span>Validée</span>
      </button>
    </div>
  </ng-template>
</div>
