<div class="color-palette flex row" [ngStyle]="paletteCssStyles">
  <div
    *ngFor="let availableColor of availableColors"
    class="circle-color-palette"
    [ngClass]="{ 'circle-selected' : colorSelected === availableColor.hexColor}"
  >
    <p
      class="color pointer"
      [style.backgroundColor]="availableColor.hexColor"
      [attr.title]="availableColor.name"
      (click)="onSelectColor(availableColor)"
    ></p>
  </div>
</div>
