import { Component } from '@angular/core';

/**
 * Component to display a drop indication on drag n drop
 */
@Component({
  selector: 'app-drop-indication',
  templateUrl: './drop-indication.component.html',
  styleUrls: ['./drop-indication.component.scss'],
})
export class DropIndicationComponent {}
