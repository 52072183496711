import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Select button with two options cancel or validate
 */
@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent {
  @Input() validated = false;
  @Input() canceled = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() validate = new EventEmitter<void>();

  /** The selected option */
  selectedOption: any;

  CANCEL = 'CANCEL';
  VALIDATE = 'VALIDATE';

  constructor() {}

  onCancel() {
    this.selectedOption = this.selectedOption === this.CANCEL ? undefined : this.CANCEL;
    this.cancel.emit();
  }

  onValidate() {
    this.selectedOption = this.selectedOption === this.VALIDATE ? undefined : this.VALIDATE;
    this.validate.emit();
  }
}
