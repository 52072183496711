import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/base.component';
import { AuthService, User } from '@app/auth';

@Component({
  selector: 'app-page-change-pwd',
  templateUrl: './page-change-pwd.component.html',
  styleUrls: ['./page-change-pwd.component.scss'],
})
export class PageChangePwdComponent extends BaseComponent implements OnInit, OnDestroy {
  user: User;

  constructor(private auth: AuthService) {
    super();
  }

  ngOnInit() {
    this.setPageTitle('Modification du mot de passe');
    this.user = this.auth.getCurrentUser();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  //

  onChangePwd(pwdInfo: { oldPassword: string; newPassword: string }) {
    this.subscriptions.push(
      this.auth.updatePassword(pwdInfo, this.user.username).subscribe({
        next: () => {
          this.dialog.show({ title: 'Succès', content: 'Votre mot de passe a été modifié avec succès.' });
        },
        error: err => {
          this.dialog.show({ title: 'Erreur', content: err.message });
        },
      }),
    );
  }
}
