export class AppError extends Error {
  errorCode: any;
  originalError: any;

  constructor(originalError: any, errorCode: any, message?: string) {
    super(message);

    this.errorCode = errorCode;
    this.originalError = originalError;
  }
}
