<div class="apply-journee-type-component">
  <!-- Button "Appliquer journée type" -->
  <div *ngIf="!lastAppliedJtDescription">
    <button type="button" class="og-btn-icon" (click)="applyJT()" pTooltip="Appliquer une journée type" tooltipPosition="bottom">
      <img src="/assets/img/ic-apply-jt.svg" alt="Appliquer journée type" />
    </button>
  </div>

  <div *ngIf="lastAppliedJtDescription" class="block-applied" (click)="applyJT()">
    <div class="block-applied-content">
      <img src="/assets/img/ic-apply-jt.svg" alt="Appliquer journée type" />
      <div class="block-applied-text text-overflow-elipsis">
        <div class="jt-applied">Journée type appliquée :</div>
        <div class="last-jt-applied text-overflow-elipsis" [pTooltip]="lastAppliedJtDescription" tooltipPosition="bottom">
          {{ lastAppliedJtDescription }}
        </div>
      </div>
    </div>
  </div>
</div>
