<div class="accordion-item">
  <div class="accordion-item-header" (click)="onToggleAccordion()">
    <div class="accordion-item-toggle">
      <img [@rotatedState]="!showBody? 'default': 'rotated'" src="/assets/img/arrow-subperiod-down.svg" />
    </div>
    <div class="accordion-item-header-title">
      <!-- For header we display a default header OR a template -->
      <ng-template #headerDefault>
        <div class="accordion-item-header-title-default">{{ header }}</div>
      </ng-template>
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : headerDefault"></ng-container>
    </div>
  </div>
  <div [@smoothCollapse]="!showBody? 'close': 'open'" class="accordion-item-body">
    <!-- For body we display a default body OR a template -->
    <div *ngIf="!bodyTemplate" class="accordion-item-body-default">
      <ng-content select="[bodyContent]"></ng-content>
    </div>
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </div>
  <div *ngIf="hasBottom" class="accordion-item-bottom">
    <ng-content select="[bottomContent]"></ng-content>
  </div>
</div>
