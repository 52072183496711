import { HttpErrorResponse } from '@angular/common/http';
import { AppError } from '../app-error';

export class BackendServiceError extends AppError {
  static createErrorMessage(error: HttpErrorResponse, errorCode: number, message: string): BackendServiceError {
    return new BackendServiceError(error, errorCode, message);
  }

  static _400(error: HttpErrorResponse, message: string): BackendServiceError {
    return BackendServiceError.createErrorMessage(error, 400, message);
  }

  static _401(error: HttpErrorResponse, message: string): BackendServiceError {
    return BackendServiceError.createErrorMessage(error, 401, message);
  }

  static _UNKNOWN(error: HttpErrorResponse, message: string): BackendServiceError {
    return BackendServiceError.createErrorMessage(error, error.status, message);
  }

  static _NO_NETWORK(error: HttpErrorResponse, message: string): BackendServiceError {
    return BackendServiceError.createErrorMessage(error, 0, message);
  }
}
