import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@app/base.component';
import { TP_ACTION } from '@app/models';

@Component({
  selector: 'app-validate-journee-type-button',
  templateUrl: './validate-journee-type-button.component.html',
  styleUrls: ['./validate-journee-type-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateJourneeTypeButtonComponent extends BaseComponent {
  @Input() isGOVJTValidated: boolean;
  @Input() modifCount: number;

  @Output() updateTp = new EventEmitter<{ action: TP_ACTION }>();

  constructor() {
    super();
  }

  validate() {
    if (this.modifCount > 0) {
      this.dialog.show('JT__CANT_VALIDATE_BECAUSE_GOV_IS_DIRTY');
    } else {
      this.updateTp.emit({ action: TP_ACTION.VALIDATE_JT });
    }
  }

  unValidate() {
    this.updateTp.emit({ action: TP_ACTION.UNVALIDATE_JT });
  }
}
