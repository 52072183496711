import { Component, Input, OnInit } from '@angular/core';
import { GroupButtonItem } from './group-button-item';

@Component({
  selector: 'app-group-button',
  templateUrl: './group-button.component.html',
  styleUrls: ['./group-button.component.scss']
})
export class GroupButtonComponent implements OnInit {
  @Input() buttonItems: GroupButtonItem[];

  constructor() { }

  ngOnInit(): void {
  }

}
