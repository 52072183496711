/**
 * @file
 * A class holding all the GOV's infrastructure (voies à quai, voies en ligne...).
 * Typically, this data is NON-EDITABLE and related to a specific station.
 */
import * as _ from 'lodash';

import { Station, VoieAQuai, VoieEnLigne, Itineraire, TypeModelisation, VoieAvantGare, VoieSubType } from './station';
import {
  ColorByType,
  TypeMateriel,
  TypeCirculation,
  GovColorMode,
  TypeCirculationColor,
  TypeMaterielColor,
  VoieEnLigneColor,
  TypeCategorie,
  TypeCategorieColor,
} from './transportation-plan';
import { ConflictType } from './conflict-type';

export class GovInfra {
  station: Station; // needed by GovChart
  vaqList: VoieAQuai[];
  vagMap: Map<number, VoieAvantGare[]> = new Map<number, VoieAvantGare[]>();
  listVoieEnLigne: VoieEnLigne[];
  listItineraire: Itineraire[];
  conflictTypes: ConflictType[];
  materielTypes: TypeMateriel[];
  circulationTypes: TypeCirculation[];
  categoryTypes: TypeCategorie[];
  typeModelisation: TypeModelisation; // Which type of itinerary should be displayed in the eqForm: MACRO, MICRO

  colorsLineByType: ColorByType[];
  colorsTrainNumberByType: ColorByType[];

  /** Allows to know if there is a schema to display features linked to the schema */
  hasSchema: boolean;

  constructor(station: Station) {
    this.station = station;

    // Extract misc props from the "station"
    // Empty arrays are for UNIT TESTS, when all values might not be defined.
    if (station.infrastructure) {
      this.vaqList = station.infrastructure.listVoieAQuai ? processVaqs(station.infrastructure.listVoieAQuai) : [];
      this.listVoieEnLigne = station.infrastructure.listVoieEnLigne || [];
      this.listItineraire = station.infrastructure.listItineraire || [];
      this.typeModelisation = station.typeModelisation;
      if (station.infrastructure.listVoieAvantGare) {
        station.infrastructure.listVoieAvantGare.forEach(vag => {
          if (this.vagMap.has(vag.niveau)) {
            this.vagMap.get(vag.niveau).push(vag);
          } else {
            this.vagMap.set(vag.niveau, [vag]);
          }
        });
      }
    } else {
      this.vaqList = [];
      this.listVoieEnLigne = [];
      this.listItineraire = [];
      this.typeModelisation = TypeModelisation.NONE;
    }

    this.materielTypes = station.typesMateriel !== undefined ? station.typesMateriel : [];
    this.circulationTypes = station.typesCirculation !== undefined ? station.typesCirculation : [];
    this.categoryTypes = station.typesCategories !== undefined ? station.typesCategories : [];
    this.conflictTypes = station.typesConflit !== undefined ? station.typesConflit : [];
    this.hasSchema = !!station.infraSchema;

    // Create color mappings
    this.colorsLineByType = this.getColorMapping(this.station.couleurGovTraitRef);
    this.colorsTrainNumberByType = this.getColorMapping(this.station.couleurGovNumeroTrainRef);
  }

  getVaqIgnore(): VoieAQuai {
    return this.vaqList.find(vaq => vaq.subType === VoieSubType.IGNORE);
  }

  /**
   * Create a color mapping that is used to colorize some
   * GOV elements such as equilibre lines or train numbers.
   *
   * The mapping associates a domain value to an hexadecimal color code.
   *
   * There are 3 possible types of mappings, based on the given `colorMode` param:
   *   - By "type circulation". Example mapping: TypeCirculationColor {type: "TER", couleurGov: "#00FF00"}
   *   - By "type materiel". Example mapping: TypeMaterielColor {type: "22", couleurGov: "#F79646"}
   *   - By "voie en ligne". Example mapping: VoieEnLigneColor {type: "2G3", couleurGov: "#FF0000"}
   */
  private getColorMapping(colorMode: GovColorMode): ColorByType[] {
    switch (colorMode) {
      case GovColorMode.TYPE_CIRCULATION:
        return this.circulationTypes.map(typeCircu => new TypeCirculationColor(typeCircu.type, typeCircu.couleurGov));
      case GovColorMode.TYPE_MATERIEL:
        return this.materielTypes.map(typeMat => new TypeMaterielColor(typeMat.type, typeMat.couleurGov));
      case GovColorMode.VOIE_EN_LIGNE:
        return this.listVoieEnLigne.map(voie => new VoieEnLigneColor(voie.nom, voie.couleur));
      case GovColorMode.CATEGORIE:
        return this.categoryTypes.map(typeCat => new TypeCategorieColor(typeCat.categorie, typeCat.couleur));
      default:
        return [];
    }
  }
}

//
// Local Helpers
//

/**
 * Sort platforms and add a `position` property to each.
 */
function processVaqs(vaqs: VoieAQuai[]) {
  vaqs = _.sortBy(vaqs, ['positionGOV']);
  return vaqs.map((vaq, index) => ({ ...vaq, position: index }) as VoieAQuai);
}
