/**
 * @file
 * Application access token.
 */
export interface Token {
  access_token: string | null;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
}
