<div class="export-menu-component">
  <div class="block-export-menu">
    <button type="button" class="og-btn-icon" (click)="menu.toggle($event)" pTooltip="Menu export" tooltipPosition="bottom">
      <img src="/assets/img/ic-export-default.svg" alt="Exporter" />
    </button>
    <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
  </div>

  <!-- Form to obtain min and max values for exporting Correspondances -->
  <p-dialog
    header="Export Correspondances"
    [(visible)]="isDialogExportCorrespondancesVisible"
    [style]="{width: '400px'}"
    [maximizable]="true"
    [baseZIndex]="10000"
  >
    <p>Veuillez entrer l'écart minimum et l'écart maximum entre l'arrivée et le départ de deux trains en correspondance.</p>
    <form #heroForm="ngForm" class="form-correspondances">
      <div class="form-group">
        <label>Min</label>
        <input class="input-minutes" type="number" name="min" min="0" required [(ngModel)]="correspFormModel.min" /> minutes
      </div>
      <div class="form-group">
        <label>Max</label>
        <input class="input-minutes" type="number" name="max" min="0" required [(ngModel)]="correspFormModel.max" /> minutes
      </div>
    </form>
    <p-footer>
      <button type="button" (click)="closeDialogExportCorrespondances(false)"
        class="og-btn-outline og-btn-outline--alternative og-btn-outline--large">
        <span>Annuler</span>
      </button>
      <button type="button" (click)="closeDialogExportCorrespondances(true)"
        class="og-btn og-btn--primary og-btn--large">
        <span>Continuer</span>
      </button>
    </p-footer>
  </p-dialog>
</div>
