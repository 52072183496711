import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isLoggedIn = inject(AuthService).isLoggedIn();
  if (!isLoggedIn) {
    return router.createUrlTree(['/auth']);
  } else {
    return true;
  }
};
