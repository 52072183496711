import { CompareElement } from './compare-element';

export enum CompareTrainCategory {
  TRAINS_ADDED = 'TRAINS_ADDED',
  TRAINS_DELETED = 'TRAINS_DELETED',
  TRAINS_MODIFIED = 'TRAINS_MODIFIED',
  EQS_MODIFIED = 'EQS_MODIFIED',
  ASSEMBLAGES_MODIFIED = 'ASSEMBLAGES_MODIFIED',
}

export enum CompareTrainCategoryColor {
  TRAINS_ADDED = '#00B050', // $green
  TRAINS_DELETED = '#F34747', // $red
  TRAINS_MODIFIED = '#FD8014', // $orange
  EQS_MODIFIED = '#FD8014', // $orange
  ASSEMBLAGES_MODIFIED = '#FD8014', // $orange
}

export enum CompareTrainSubCategory {
  TRAINS_ADDED = 'TRAINS_ADDED',
  TRAINS_DELETED = 'TRAINS_DELETED',
  MODIFICATION_HORAIRE = 'MODIFICATION_HORAIRE',
  MODIFICATION_VAQ = 'MODIFICATION_VAQ',
  MODIFICATION_TC = 'MODIFICATION_TC',
  MODIFICATION_VEL = 'MODIFICATION_VEL',
  MODIFICATION_ITINERAIRE = 'MODIFICATION_ITINERAIRE',
  MODIFICATION_OD = 'MODIFICATION_OD',
  MODIFICATION_NB_ELEMENTS = 'MODIFICATION_NB_ELEMENTS',
  MODIFICATION_TYPE_MATERIEL = 'MODIFICATION_TYPE_MATERIEL',
  ASSEMBLAGES_MODIFIED = 'ASSEMBLAGES_MODIFIED',
  MODIFICATION_OCCUPATION = 'MODIFICATION_OCCUPATION',
}

export interface CompareTrainByCategory {
  category: CompareTrainCategory;
  compareTrainsBySubCategory: CompareTrainBySubCategory[];
}

export interface CompareTrainBySubCategory {
  subCategory: CompareTrainSubCategory;
  title: string;
  compareElements: CompareElement[];

  // For testing
  allRejected?: boolean;
  allAccepted?: boolean;
}
