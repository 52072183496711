/**
 * @file
 * Misc models for Transportation Plan.
 */

export const CONCEPTION_TP_DATE = '1960-01-01';

export enum TransportationPlanStatus {
  CURRENT = 'CURRENT',
  VALIDATED = 'VALIDATED',
  IMPORTED = 'IMPORTED',
  ARCHIVED = 'ARCHIVED',
  CERTIFIED = 'CERTIFIED',
  JT_VALIDATED = 'JT_VALIDATED', // Only for Conception
  DISCARDED = 'DISCARDED', // Only for Conception

  // Front only
  SYNCHRO_MERGED_HOUAT = 'SYNCHRO_MERGED_HOUAT',
  SYNCHRO_MERGED_PASSE_MINUIT = 'SYNCHRO_MERGED_PASSE_MINUIT',
}

export enum TypeVoie {
  VOIE_EN_LIGNE = 'VOIE_EN_LIGNE',
  VOIE_A_QUAI = 'VOIE_A_QUAI',
}

export interface EquilibreDto {
  id: number;
  typeMateriel: string;
  materielCount: number;
  position: number;
  miQuai?: string;
  miQuaiDisplay?: string;
  arrive: Train;
  depart: Train;
}

export interface Train {
  id: number;
  numero: string;
  origine: string;
  destination: string;
  commentaire: string;
  amorce: number;
  typeCirculation: string;
  dateHeure: string;
  indiceJour: number;
  // direction: string;   // REMOVED - Feb 2021
  typeVoieDebut: TypeVoie;
  voieDebut: string;
  typeVoieFin: TypeVoie;
  voieFin: string;

  itineraireRid: string;
  isItineraireFixed: boolean;
  itineraireComposition?: string;

  dePassage: boolean;
  createdByOG: boolean;

  // For train de passage
  changeParite: boolean;
  sens: string;

  // TODO 17/08/2023 TO DELETE
  direction: string;

  cich: string;

  metadonnees?: {
    HEURE_ROULEMENT?: string;
    CATEGORIE?: string; // NEW - Feb 2021
    LIBDIR?: string; // NEW - Feb 2021
    F2K_TRAIN_NOT_FOUND?: string;
    F2K_ITI_NOT_FOUND?: string;
  };
}

export interface ManoeuvreDto {
  id: number;
  depart: Train;
  arrivee: Train;
  isGeneree: boolean;
}

/**
 * A full transportation plan, with all its eqGroups.
 */
export interface TransportationPlanDto {
  id: number;
  parametrageId: number; // NEW - Feb 2021
  date: string;
  etat: TransportationPlanStatus;
  modificationCount: number;
  codeGare: string;
  equilibresLists: EquilibreDto[][];
  origine: string;
  rid: string;
  lastModifiedDate: string;
  lastAppliedJt: string;
  manoeuvres: ManoeuvreDto[];
}

/**
 * A lightweight version of `TransportationPlanDto`,
 * used when manipulating lists of TPs such as on the calendar page.
 */
export interface TpLightDto {
  id: number;
  // infrastructureId: number;
  date: string;
  etat: TransportationPlanStatus;
  modificationCount: number;
  codeGare: string;
  // equilibresLists: EquilibreDto[][];
  origine: string;
  // rid: string;
  // lastModifiedDate: string;

  // Addition prop, not present in `TransportationPlanDto`
  importDate: string; // Date in YYYY-MM-DD format
}
export interface TypeCirculation {
  type: string;
  couleurGov: string;
}

export interface TypeMateriel {
  couleurGov: string;
  isCategorieA: boolean;
  isReversible: boolean;
  longueur: number;
  modeTraction: string;
  nom: string;
  type: string;
  typeEngin: string;
}

export interface TypeCategorie {
  categorie: string;
  couleur: string;
}

export class ColorByType {
  type: string;
  couleurGov: string;

  constructor(type: string, couleurGov: string) {
    this.type = type;
    this.couleurGov = couleurGov;
  }
}

export class TypeCirculationColor extends ColorByType {}

export class TypeMaterielColor extends ColorByType {}

export class VoieEnLigneColor extends ColorByType {}

export class TypeCategorieColor extends ColorByType {}

export enum GovColorMode {
  TYPE_CIRCULATION = 'TYPE_CIRCULATION',
  TYPE_MATERIEL = 'TYPE_MATERIEL',
  VOIE_EN_LIGNE = 'VOIE_EN_LIGNE',
  CATEGORIE = 'CATEGORIE',
  NO_COLOR = 'NO_COLOR',
}

export enum F2K_METADATA_KEY {
  F2K_TRAIN_NOT_FOUND = 'F2K_TRAIN_NOT_FOUND',
  F2K_ITI_NOT_FOUND = 'F2K_ITI_NOT_FOUND',
}

export enum F2K_METADATA_LABEL {
  F2K_TRAIN_NOT_FOUND = 'Trains absents du fichier 2000',
  F2K_ITI_NOT_FOUND = 'Trains avec un parcours fichier 2000 incohérent avec l’infrastructure OpenGOV',
}

export enum GovColorModeLabel {
  TYPE_CIRCULATION = 'Type circulation',
  TYPE_MATERIEL = 'Type matériel',
  VOIE_EN_LIGNE = 'Voie en ligne',
  CATEGORIE = 'Catégorie',
  NO_COLOR = 'Désactivé',
}

export enum TP_ACTION {
  // Optimization
  OPTIMIZE_TP = 'OPTIMIZE_TP',
  TP_OPTIMIZED = 'TP_OPTIMIZED',
  // Drag & Drop
  DRAG_EQGROUP = 'DRAG_EQGROUP',
  EQGROUP_DRAGGED = 'EQGROUP_DRAGGED',
  // Edition
  SUBMIT_EQFORM = 'SUBMIT_EQFORM',
  EQFORM_SAVED = 'EQFORM_SAVED',
  EQFORM_CREATED = 'EQFORM_CREATED',
  SPLIT_EQ = 'SPLIT_EQ',
  EQ_SPLITTED = 'EQ_SPLITTED',
  JOIN_EQS_STANDARD = 'JOIN_EQS_STANDARD',
  JOIN_EQS_COUPE = 'JOIN_EQS_COUPE',
  JOIN_EQS_ACCROCHE = 'JOIN_EQS_ACCROCHE',
  EQS_JOINED_STANDARD = 'EQS_JOINED_STANDARD',
  EQS_JOINED_COUPE = 'EQS_JOINED_COUPE',
  EQS_JOINED_ACCROCHE = 'EQS_JOINED_ACCROCHE',
  HIGHLIGHT_EQGROUP = 'HIGHLIGHT_EQGROUP',
  EQGROUP_HIGHLIGHTED = 'EQGROUP_HIGHLIGHTED',
  // IGNORE (move equilibres on IGNORE VAQ)
  IGNORE_EQUILIBRES = 'IGNORE_EQUILIBRES',
  EQUILIBRES_IGNORED = 'EQUILIBRES_IGNORED',
  IGNORE_EQUILIBRE = 'IGNORE_EQUILIBRE',
  DELETE_EQUILIBRE = 'DELETE_EQUILIBRE',
  TRAIN_DELETED = 'TRAIN_DELETED',
  EQUILIBRE_DELETED = 'EQUILIBRE_DELETED',
  EQGROUP_DELETED = 'EQ_GROUP_DELETED',
  // Analysis
  ANALYZE_CONFLICTS = 'ANALYZE_CONFLICTS',
  CONFLICTS_ANALYZED = 'CONFLICTS_ANALYZED',
  // Confirm/Cancel changes
  CONFIRM_TP_CHANGES = 'CONFIRM_TP_CHANGES',
  CANCEL_TP_CHANGES = 'CANCEL_TP_CHANGES',
  TP_CHANGES_CONFIRMED = 'TP_CHANGES_CONFIRMED',
  TP_CHANGES_CANCELED = 'TP_CHANGES_CANCELED',
  // Certification
  CERTIFY_GOV = 'CERTIFY_GOV',
  UNCERTIFY_GOV = 'UNCERTIFY_GOV',
  GOV_CERTIFIED = 'GOV_CERTIFIED',
  GOV_UNCERTIFIED = 'GOV_UNCERTIFIED',
  // Journee type
  VALIDATE_JT = 'VALIDATE_JT',
  UNVALIDATE_JT = 'UNVALIDATE_JT',
  JT_VALIDATED = 'JT_VALIDATED',
  JT_UNVALIDATED = 'JT_UNVALIDATED',
  APPLY_JT = 'APPLY_JT',
  JT_APPLIED = 'JT_APPLIED',
  // Synchronization
  SYNCHRONIZE_HOUAT = 'SYNCHRONIZE_HOUAT',
  TP_SYNCHRONIZED = 'TP_SYNCHRONIZED',
  LEAVE_SYNCHRONIZE = 'LEAVE_SYNCHRONIZE',
  SYNCHRONIZE_LEFT = 'SYNCHRONIZE_LEFT',
  VALIDATE_SYNCHRONIZE = 'VALIDATE_SYNCHRONIZE',
  SYNCHRONIZE_VALIDATED = 'SYNCHRONIZE_VALIDATED',
  RESET_SYNCRHONIZE = 'RESET_SYNCRHONIZE',
  SYNCHRONIZE_RESETED = 'SYNCHRONIZE_RESETED',
  SYNCHRONIZE_PASSE_MINUIT = 'SYNCHRONIZE_PASSE_MINUIT',
  SYNCHRONIZE_PASSE_MINUIT_FORCE = 'SYNCHRONIZE_PASSE_MINUIT_FORCE',
  VALIDATE_SYNCHRONIZE_PASSE_MINUIT_FORCE = 'VALIDATE_SYNCHRONIZE_PASSE_MINUIT_FORCE',
  //
  NONE = 'NONE',
}
