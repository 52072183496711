import { NgModule } from '@angular/core';

import { DialogModule } from 'primeng/dialog';

import { SharedModule } from '@app/shared';

// Components
import { HeaderComponent } from './header/header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CertifyButtonComponent } from './certify-button/certify-button.component';
import { OptimizeButtonComponent } from './optimize-button/optimize-button.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { GovSettingsMenuComponent } from './gov-settings-menu/gov-settings-menu.component';
import { ExportMenuComponent } from './export-menu/export-menu.component';
import { TravauxMenuComponent } from './travaux-menu/travaux-menu.component';
import { ValidateJourneeTypeButtonComponent } from './validate-journee-type-button/validate-journee-type-button.component';
import { ApplyJourneeTypeButtonComponent } from './apply-journee-type-button/apply-journee-type-button.component';
import { SynchronizePdtMenuComponent } from './synchronize-pdt-menu/synchronize-pdt-menu.component';
import { InfoMenuComponent } from './info-menu/info-menu.component';

@NgModule({
  imports: [
    DialogModule, // Used in ExportDropdownComponent for the "export preferences" form
    SharedModule,
  ],
  declarations: [
    HeaderComponent,
    BreadcrumbComponent,
    CertifyButtonComponent,
    OptimizeButtonComponent,
    UserMenuComponent,
    GovSettingsMenuComponent,
    TravauxMenuComponent,
    ExportMenuComponent,
    ValidateJourneeTypeButtonComponent,
    ApplyJourneeTypeButtonComponent,
    SynchronizePdtMenuComponent,
    InfoMenuComponent,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
