/**
 * @file
 * A conflict in a transportation plan.
 */
import * as moment from 'moment';
import * as _ from 'lodash';

import { Equilibre, isPasseMinuit } from './equilibre';
import { eqGroupGetId } from './eq-utils';
import { EquilibreDto, Train } from './transportation-plan';
import { ConflictSubtype, ConflictType } from './conflict-type';
import { TrainInfo } from './train-info';

/**
 * ============================
 * ========== MODELS ==========
 * ============================
 */

/**
 * A raw conflict, as returned by the backend.
 */
export interface ConflictDto {
  identifier: number;
  sousType: string;
  category: ConflictCategory;
  trainIds: number[];
  description: string;
  opeRid?: number; // NEW 22-OCT-2020: References a travauxOp.id - Set only if the conflict is linked to a travauxOp
  statut: StatutConflictDto;
}

/**
 * A raw statut conflict, as returned by the backend.
 */
export interface StatutConflictDto {
  id: number;
  value: StatutConflictValue;
  label: string;
  commentaire?: string;
}

/**
 * Enum for statut conflict
 * The order is important as it is order by priority
 * NON_TRAITE > EN_COURS > MAINTENU > FAUX_CONFLIT
 */
export enum StatutConflictValue {
  NON_TRAITE = 'NON_TRAITE',
  EN_COURS = 'EN_COURS',
  MAINTENU = 'MAINTENU',
  FAUX_CONFLIT = 'FAUX_CONFLIT',
}

/**
 * Map for statut conflict label
 */
export const StatutConflictLabel = {
  NON_TRAITE: 'Non traité',
  EN_COURS: 'En cours',
  MAINTENU: 'Maintenu',
  FAUX_CONFLIT: 'Faux conflit',
};

/**
 * A conflict enriched with frontend-specific properties.
 */
export interface Conflict {
  // Original props
  id: number;
  sousType: string;
  description: string;
  trainIds: number[];
  opeRid?: number; // NEW 22-OCT-2020: References a travauxOp.id - Set only if the conflict is linked to a travauxOp
  statut: StatutConflict;

  // Frontend-specific properties.
  category: ConflictCategory;
  eqGroupIds: string[]; // Ids of eqGroups implicated in the conflict
  eqGroups: Equilibre[][]; // Actual eqGroups implicated in the conflict
  // This prop is populated only when returning from state
  hourAffected: number; // Hour affected by the conflict
}

export interface StatutConflict {
  id: number;
  value: StatutConflictValue;
  label: string;
  commentaire?: string;

  // Frontend-specific properties.
  /** Img src corresponding to the statut */
  imgSrc?: string;
}

export enum ConflictCategory {
  GENERAL = 'GENERAL',
  INCOHERENCE_DONNEES = 'INCOHERENCE_DONNEES',
  TRAIN_NON_PLACE = 'TRAIN_NON_PLACE',
}

/**
 * ============================
 * ======== FUNCTIONS =========
 * ============================
 */

/**
 * Create a frontend-friendly conflict from a backend conflict.
 *
 * @param conflictDto       The conflict as returned from the backend.
 * @param opts.eqGroupDtos  All eqGroups in the GOV
 */
export function createConflictFromDto(conflictDto: ConflictDto | Conflict, opts: { eqGroupDtos?: EquilibreDto[][] } = {}): Conflict {
  const eqGroupDtos = opts.eqGroupDtos || [];
  // eqGroups implicated in the current conflict
  const conflictEqGroupDtos = conflictDto.trainIds.map(trainId => getEqGroupDtoForTrainId(trainId, eqGroupDtos));
  const conflictEqGroupIds = conflictEqGroupDtos.map(eqGroupDto => eqGroupGetId(eqGroupDto));

  const conflict: Conflict = {
    // Map backend properties
    id: isConflictDto(conflictDto) ? conflictDto.identifier : conflictDto.id,
    sousType: conflictDto.sousType,
    description: conflictDto.description,
    trainIds: conflictDto.trainIds,
    opeRid: conflictDto.opeRid,
    statut: createStatutConflict(conflictDto.statut),

    // Create frontend-specific props
    category: conflictDto.category,
    eqGroupIds: Array.from(new Set(conflictEqGroupIds)).filter(Boolean), // uniques + remove empty values
    eqGroups: [], // empty ; rehydrate only when emitting conflict from state
    hourAffected: getHourForConflict(conflictDto, opts.eqGroupDtos),
  };

  return conflict;
}

export function createDtoFromConflict(conflict: Conflict): ConflictDto {
  const conflictDto: ConflictDto = {
    identifier: conflict.id,
    sousType: conflict.sousType,
    trainIds: conflict.trainIds,
    category: conflict.category,
    description: conflict.description,
    opeRid: conflict.opeRid,
    statut: createStatutConflictDto(conflict.statut),
  };
  return conflictDto;
}

/**
 * Creates a statut conflict from a statut conflict dto
 * @param statutConflictDto The statut conflict dto
 * @returns a statut conflict
 */
export function createStatutConflict(statutConflictDto: StatutConflictDto): StatutConflict {
  if (statutConflictDto) {
    return {
      id: statutConflictDto.id,
      value: statutConflictDto.value,
      label: statutConflictDto.label,
      commentaire: statutConflictDto.commentaire,
      imgSrc: getStatutConflictImgSrc(statutConflictDto.value),
    } as StatutConflict;
  }

  return undefined;
}

/**
 * Creates a statut conflict dto from a statut conflict
 * @param statutConflict The statut conflict
 * @returns a statut conflict dto
 */
function createStatutConflictDto(statutConflict: StatutConflict): StatutConflictDto {
  if (statutConflict) {
    return {
      id: statutConflict.id,
      value: statutConflict.value,
      label: statutConflict.label,
      commentaire: statutConflict.commentaire,
      imgSrc: getStatutConflictImgSrc(statutConflict.value),
    } as StatutConflictDto;
  }

  return undefined;
}

/**
 * Return the eqGroup DTO containing the given train.
 *
 * Note that a given trainId should only appear in one eqGroup, although possibly multiple times.
 * We can therefore stop searching as soon as we find the 1st eqGroup containing the given trainId.
 */
function getEqGroupDtoForTrainId(trainId: number, eqGroupDtos: EquilibreDto[][]): EquilibreDto[] {
  const eqGroupDto = eqGroupDtos.find(eqG =>
    eqG.some(eq => (eq.arrive && eq.arrive.id === trainId) || (eq.depart && eq.depart.id === trainId)),
  );
  return eqGroupDto || [];
}

/**
 * Return the * UNIQUE * hour that the conflict affects.
 *
 * In reality, a conflict may implicate several trains that each have an hour.
 * But the rule is "the hour affected is that of the latest train".
 */
function getHourForConflict(conflictDto: ConflictDto | Conflict, eqGroups: EquilibreDto[][]): number {
  const conflictHours = conflictDto.trainIds.map(trainId => getHourForTrainId(trainId, eqGroups));
  return conflictHours.length > 0 ? Math.max(...conflictHours) : -1;
}

/**
 * Return the hour that the given train is "in".
 *
 * This info is extracted from the train's `dateHeure` prop.
 */
function getHourForTrainId(trainId: number, eqGroups: EquilibreDto[][]) {
  // Find the 1st eqGroup matching the given trainId and stop searching.
  const eqGroup = eqGroups.find(eqG => eqG.some(eq => (eq.arrive && eq.arrive.id === trainId) || (eq.depart && eq.depart.id === trainId)));
  // Then search this eqGroup for the train matching the current trainId.
  if (eqGroup) {
    const eqGroupAllTrains: Train[] = eqGroup.reduce((trains: Train[], eq: EquilibreDto) => trains.concat([eq.arrive, eq.depart]), []);
    const train = eqGroupAllTrains.find(t => t && t.id === trainId) as Train; // train should always exist
    if (train.dateHeure) {
      // NB. In unit tests, `train.dateHeure` may be null
      if (!isPasseMinuit(train)) {
        // Not passe-minuit
        return moment(train.dateHeure).hour();
      } else if (train.indiceJour < 0) {
        // Passe-minuit J-1
        return 0; // affected hour = 00h
      } else {
        // Passe-minuit J+1
        return 23; // affected hour = 23h
      }
    }
    return train.dateHeure ? moment(train.dateHeure).hour() : -1; // NB. In unit tests, `train.dateHeure` may be null
  }
  return -1; // Not found or `train.dateHeure` may be null (this is possible in unit tests)
}

// Return true if the given value is a ConflictDto.
export function isConflictDto(conflict: ConflictDto | Conflict): conflict is ConflictDto {
  return (conflict as ConflictDto).identifier !== undefined;
}

/**
 * Extract the following facts for each train implicated in the given conflict:
 *   - the `Train` data
 *   - whether it's an "arrive" or "depart" train
 *   - the parent equilibre
 */
export function conflictGetTrainsInfo(conflict: Conflict): TrainInfo[] {
  // Gather the required info for ALL TRAINS in the available eqs.
  const trainInfos: TrainInfo[] = _.flattenDepth(
    conflict.eqGroups.map(eqs =>
      eqs.map(eq => {
        const tInfos: TrainInfo[] = [];
        if (eq.arrive) {
          tInfos.push({ train: eq.arrive, isArrive: true, eq });
        }
        if (eq.depart) {
          tInfos.push({ train: eq.depart, isArrive: false, eq });
        }
        return tInfos;
      }),
    ),
    2,
  );
  // Only return info for the trains in the conflict
  return conflict.trainIds.map(trainId => trainInfos.find(tInfo => tInfo.train.id === trainId));
}

/**
 * Return the given list of conflicts organized by types and subtypes.
 *
 * Examples:
 *   - The type "ALERTE" has the subtypes "CHEVAUCHEMENT", "ITINERAIRE_NON_ATTRIBUE"...
 *   - The type "CISAILLEMENT" has the subtypes "CISAILLEMENT_AD", "CISAILLEMENT_DA"...
 */
export function sortConflictsByTypes(conflicts: Conflict[], conflictTypes: ConflictType[]) {
  // Process conflict subtypes.
  return conflictTypes
    .map(conflictType => ({
      ..._.cloneDeep(conflictType),
      sousTypes: processConflictTypeSousTypes(conflictType.sousTypes, conflicts),
    }))
    .filter(conflictType => conflictType.sousTypes.length !== 0);
}

/**
 * Process the `ConflictType.sousTypes` property by:
 *   - attaching the conflicts to their matching sousType
 *   - filtering out sousTypes with no conflicts
 */
function processConflictTypeSousTypes(sousTypes: ConflictSubtype[], conflicts: Conflict[]): ConflictSubtype[] {
  return sousTypes
    .map(st => {
      const conflictsFiltered = conflicts.filter(c => c.sousType === st.sousType);
      return { ...st, $conflicts: conflictsFiltered, statut: processConflictsGlobalStatut(conflictsFiltered) };
    })
    .filter(st => st.$conflicts.length !== 0);
}

/**
 *
 * @param conflicts The conflicts
 * @returns the conflicts global statut
 * corresponding on a summary of all the statut of the conflicts.
 */
export function processConflictsGlobalStatut(conflicts: Conflict[]): StatutConflictValue {
  if (conflicts) {
    const conflictsSorted: Conflict[] = _.cloneDeep(conflicts);
    conflictsSorted.sort((a, b) => compareConflictsByStatut(a, b));

    return conflictsSorted[0]?.statut?.value;
  }

  return undefined;
}

/**
 * Compare conflicts based on the statut conflict, using the index of enum.
 * NON_TRAITE > EN_COURS > MAINTENU > FAUX_CONFLIT
 * @param conflictA The conflict A
 * @param conflictB The conflict B
 * @returns the result of the comparison
 */
function compareConflictsByStatut(conflictA: Conflict, conflictB: Conflict): number {
  const indexA = Object.keys(StatutConflictValue).indexOf(conflictA.statut?.value);
  const indexB = Object.keys(StatutConflictValue).indexOf(conflictB.statut?.value);

  return indexA - indexB;
}

/**
 *
 * @param statut The statut
 * @returns the src corresponding to the statut
 */
export function getStatutConflictImgSrc(statut: string): string {
  const statutName = statut?.toLowerCase().replace('_', '-');
  return `/assets/img/ic-conflit-statut-${statutName}.svg`;
}

/**
 *
 * @param statut The statut of the conflict
 * @returns The statut info (statut + comment)
 */
export function getStatutConflictInfo(statut: StatutConflict) {
  return statut.label + (statut.commentaire ? ` "${statut.commentaire}"` : '');
}
