/**
 * @file
 * Data displayed when the user clicks "Analyze conflicts".
 */

/**
 * Raw data returned by the backend.
 */
export interface ConflictInfo {
  sousType: string;
  trainIds: number[];
  identifier: number;
  description: string;
}
export interface AnalyzedConflictsDto {
  additionsCount: number;
  deletionsCount: number;
  changes: number;
  sourcePdtId: number;
  comparedPdtId: number;
  additions: ConflictInfo[];
  deletions: ConflictInfo[];
}

/**
 * Frontend-friendly data.
 */
export interface AnalyzedConflicts {
  additionsCount: number;
  deletionsCount: number;
  changes: number;
  sourcePdtId: number;
  comparedPdtId: number;
  additionsBySousType: Array<{ sousType: string; conflicts: ConflictInfo[] }>;
  deletionsBySousType: Array<{ sousType: string; conflicts: ConflictInfo[] }>;
}
